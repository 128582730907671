import React from 'react';
import PropTypes from 'prop-types';
//import { Test } from './AssetLinks.styles';

const AssetLinks = (props) => (
  <div className="AssetLinksWrapper">
    Test content
  </div>
);

AssetLinks.propTypes = {
  // bla: PropTypes.string,
};

AssetLinks.defaultProps = {
  // bla: 'test',
};

export default AssetLinks;
