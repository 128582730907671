import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { data } from '../data.';
import { Button, Typography } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  taglineSection: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    height: 500,
    paddingLeft: theme.spacing(4),
    backgroundColor: '#8d5185',
    backgroundImage: 'linear-gradient(315deg, #8d5185 0%, #a1bafe 74%)',
  },
  tagline: {
    maxWidth: '80%',
    fontWeight: 700,
  },
}));

export default function TaglineSection() {
  const classes = styles();

  const handleScrollToContact = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={classes.taglineSection}>
      <div>
        <Typography variant="h2" component={'p'} className={classes.tagline}>
          {data.tagline}
        </Typography>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleScrollToContact('requestdemo')}
        >
          {data.joinWaitListBtn}
        </Button>
      </div>
    </div>
  );
}
