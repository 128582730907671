import { FILTER_BY_DATE } from "../actions/types";
import moment from "moment";

const initialState = {
    startDate: moment().subtract(6, "months"),
    endDate: moment()
}

export default function(state = initialState, action) {

    switch(action.type) {
        case FILTER_BY_DATE:
            return {
                ...state,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate
            }
        default:

            return state
    }
}