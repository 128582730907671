import { Chip, alpha, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  drugChip: {
    margin: theme.spacing(0.5),
    fontSize: 11,
    backgroundColor: alpha(theme.palette.secondary.main, 0.15),
    fontWeight: 'bold',
    maxWidth: 200,
    overflow: 'ellipsis',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:hover': {
      textOverflow: 'visible',
      maxWidth: '600px',
    },
  },
}));
export default function Indications({ indications }) {
  const classes = useStyles();

  return (
    <>
      {indications?.map((indication) => (
        <Chip
          label={indication.toLowerCase()}
          size="small"
          className={classes.drugChip}
          key={indication.toLowerCase()}
        />
      ))}
    </>
  );
}
