import { Grid, List, ListItem, Typography, alpha, makeStyles } from '@material-ui/core';
import React from 'react';
import { data } from '../data.';
import treatmentloop from '../../../../../assets/images/treatmentloop.png';

const styles = makeStyles((theme) => ({
  how: {
    padding: theme.spacing(4),
    textAlign: 'center',
    '& h3': {
      fontWeight: 'bold',
    },
  },
  appName: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  treatmentLoop: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '80%',
    },
  },
}));

export default function How() {
  const classes = styles();
  return (
    <div className={classes.how}>
      <Typography variant="h3">
        How does{' '}
        <Typography component="span" className={classes.appName}>
          {data.appName}
        </Typography>{' '}
        work?
      </Typography>
      <div className={classes.treatmentLoop}>
        <img src={treatmentloop} alt="treatment loop" />
      </div>
    </div>
  );
}
