export const comparisonTableLabels = {
  name: 'Name',
  description: 'Description',
  indications: 'Indications',
  brandNames: 'Brand Names',
  dosage: 'Dosage',
  commonInteractions: 'Common Interactions',
  commonSideEffects: 'Common Side Effects',
  administration: 'Administration',
  organClearance: 'Organ Clearance',
  price: 'Price',
  pregnancyRisk: 'Pregnancy Risk',
};
