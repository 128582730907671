import { Button, Typography, alpha, makeStyles } from '@material-ui/core';
import React from 'react';
import { data } from '../data.';
import ContactForm from '../ContactForm';

const styles = makeStyles((theme) => ({
  requestDemo: {
    padding: theme.spacing(6),
    textAlign: 'center',
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
  },
  requestDemoDesc: {
    fontSize: '2em',
    marginBottom: theme.spacing(2),
  },
  contactUs: {
    display: 'flex',
    gridGap: theme.spacing(2),
  },
}));

export default function RequestDemo() {
  const classes = styles();
  return (
    <div className={classes.requestDemo} id="requestdemo">
      <Typography variant="h5" className={classes.requestDemoDesc}>
        {data.requestDemoDesc}
      </Typography>
      <ContactForm />
    </div>
  );
}
