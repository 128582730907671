import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { Grid, makeStyles } from '@material-ui/core';
import { data } from '../data.';

const initialState = {
  name: '',
  email: '',
  message: '',
};

const styles = makeStyles((theme) => ({
  contactForm: {
    // width: '80%',
  },
}));

export default function ContactForm() {
  const classes = styles();
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const serviceId = 'panacea-app';
  const templateId = 'template_255jbcd';
  const publicKey = 'NEsUuJG7ZfRHtvOVk';

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(serviceId, templateId, e.target, publicKey).then(
      (result) => {
        console.log(result.text);
        clearState();
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <div className={classes.contactForm} id="#contactform">
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <form onSubmit={sendEmail}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    value={name}
                    required
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger"></p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    required
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger"></p>
                </div>
              </div>
            </div>
            <div className="form-group">
              <textarea
                name="message"
                id="message"
                className="form-control"
                rows="4"
                placeholder="Message"
                required
                onChange={handleChange}
                value={message}
              ></textarea>
              <p className="help-block text-danger"></p>
            </div>
            <div id="success"></div>
            <button type="submit" className="btn btn-custom btn-lg">
              {data.requestDemo}
            </button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
