import { textbookDrugs } from '../../../../__mocks__/drugs';

export function parseIndicators(indicationString) {
  // Check if the input is a string and is not null/undefined
  if (typeof indicationString !== 'string' || !indicationString.trim()) {
    return [];
  }

  // Define an array to hold the parsed elements
  const parsedIndicators = [];

  // Regular expression to split elements based on commas, periods, semicolons, and newlines
  const separatorRegex = /,\s*|\.\s*|\;\s*|\r\n|\n/;

  // Split the indicationString into individual elements
  const elements = indicationString.split(separatorRegex);

  // Trim each element and add it to the parsedIndicators array
  elements.forEach((element) => {
    const trimmedElement = element.trim().toLowerCase();
    if (trimmedElement !== '') {
      parsedIndicators.push(trimmedElement);
    }
  });

  return parsedIndicators;
}

export function cleanNames(name) {
  const regex = /(\r\n)/g;
  const replaced = name.replace(regex, '');
  const common = /(common:)/g;
  return replaced.replace(common, '');
}

export function stringWithCommasToArray(string) {
  if (typeof string !== 'string' || !string.trim()) {
    return [];
  }
  const newString = cleanNames(string);
  const array = newString.split(',').map((item) => item.trim().toLowerCase());
  return array;
}

export function getIndications() {
  const indicationSet = new Set();
  textbookDrugs.forEach((drug) => {
    const indications = parseIndicators(drug.indications);
    if (indications.length > 0) {
      indications.forEach((indication) => {
        if (
          indication.length > 1 &&
          indication !== 'and' &&
          indication !== 'or' &&
          indication !== 'small' &&
          indication !== 'indications' &&
          indication !== 'however' &&
          indication !== 'g'
        ) {
          indicationSet.add(indication);
        }
      });
    }
  });
  return Array.from(indicationSet);
}

export function getPregnancyRisks() {
  const pregnancyRiskSet = new Set();
  textbookDrugs.forEach((drug) => {
    if (drug.pregnancyRisk) {
      drug.pregnancyRisk = cleanNames(drug.pregnancyRisk);
      pregnancyRiskSet.add(drug.pregnancyRisk);
    }
  });
  return Array.from(pregnancyRiskSet);
}
