import { AppBar, Badge, InputAdornment, TextField, Toolbar } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { useState } from 'react';
import { drugSearchVar } from '../../../../../data/state/drugs/search/drugSearchVar';
import useStyles from '../Header.styles';

export default function SearchHeader() {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const handleSearchChange = (event) => {
    let keyword = event.target.value;
    setSearch(keyword);
    if (keyword.length >= 3) {
      drugSearchVar(keyword);
    } else {
      drugSearchVar(null);
    }
  };
  return (
    <AppBar position="sticky" className={classes.headerAppBar} color="transparent">
      <Toolbar className={classes.toolbar}>
        <div className={classes.searchSection}>
          <TextField
            className={classes.searchBar}
            name="search"
            placeholder="Search for a drug"
            value={search}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            onChange={handleSearchChange}
          />
        </div>
        <div className={classes.searchHeaderConfig}>
          <Badge color="secondary" variant="dot" overlap="circular">
            <NotificationsOutlinedIcon />
          </Badge>
          <AccountCircleIcon color="secondary" fontSize="large" />
        </div>
      </Toolbar>
    </AppBar>
  );
}
