import { Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  drugDosageChip: {
    margin: theme.spacing(0, 0.5, 0.5, 0),
    fontSize: 10,
    fontWeight: 'bold',
  },
}));

export default function Dosage({ dosageStrengths }) {
  const classes = useStyles();
  return (
    <>
      {dosageStrengths.map((dosageStrength) => (
        <Chip
          label={dosageStrength}
          size="small"
          className={classes.drugDosageChip}
          variant="outlined"
          key={dosageStrength}
        />
      ))}
    </>
  );
}
