import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  app: {
    display: 'grid',
    // TODO: fix the issue where main is still showing sidebar
    gridTemplateAreas: `
      "header header"
      "main main"
      "footer footer"
    `,
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr auto',
    height: '100vh',
    overflow: 'hidden',
  },
  header: {
    gridArea: 'header',
  },
  sideMenu: {
    gridArea: 'sidebar',
  },
  mainContent: {
    gridArea: 'main',
    overflowY: 'auto',
  },
  footer: {
    gridArea: 'footer',
  },
  isAuthApp: {
    gridTemplateColumns: '240px 1fr',
    gridTemplateAreas: `
      "header header"
      "sidebar main"
      "footer footer"
    `,
  },
}));
