import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '../Layout';
import Drugs from '../pages/Drugs';
import Landing from '../pages/Landing';
import NotFound from '../pages/NotFound';
import AssetLinks from '../public/AssetLinks/AssetLinks';
import { CustomLogin } from './CustomLogin';
import RequireAuth from './RequireAuth';

export const RouteMap = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Landing />} />
          {/* <Route
            path="home"
            element={
              <RequireAuth>
                <PatientResponseCard />
              </RequireAuth>
            }
          />
          <Route
            path="responses"
            element={
              <RequireAuth>
                <InsomniaResponse />
              </RequireAuth>
            }
          />
          <Route
            path="analytics"
            element={
              <RequireAuth>
                <SelectedDrugView />
              </RequireAuth>
            }
          /> */}
          {/* route that will go to drugs page and requires auth */}
          <Route
            path="drugs"
            element={
              <RequireAuth>
                <Drugs />
              </RequireAuth>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/login" element={<CustomLogin />} />
        <Route path="/public/.well-known/" element={<AssetLinks />} />
      </Routes>
    </BrowserRouter>
  );
};
