export const SET_USER = 'SET_USER';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_UP = 'SIGN_UP';
export const SET_SUCCESS  = 'SET_SUCCESS';

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const NEED_VERIFICATION = 'NEED_VERIFICATION';


export const SELECT_TREATMENT = 'SELECT_TREATMENT'
export const ADD_TREATMENTS = 'ADD_TREATMENT'
export const ADD_RESPONSES = 'ADD_RESPONSES'


// Actions related to authentication
export const AUTH_USER = 'auth_user';
export const AUTH_MFA = 'auth_mfa';
export const AUTH_NEW_PASSWORD_REQUIRED = 'auth_new_password_required';
export const UNAUTH_USER = 'unauth_user';
export const REGISTER_USER = 'register_user';
export const REGISTER_USER_CONFIRM = 'register_user_confirm';
export const REGISTER_MFA = 'register_mfa';
export const REGISTER_USER_ERROR = 'register_user_error';
export const FORGOT_PASSWORD = 'forgot_password';
export const FORGOT_PASSWORD_CONFIRM = 'forgot_password_confirm';
export const AUTH_ERROR = 'auth_error';
export const AUTH_ERROR_CLEAR = 'auth_error_clear';

// Actions associated with Your own API
export const REQRES_FETCH_ALL_SUCCESS = 'reqres_fetch_all_success';
export const REQRES_CREATE_SUCCESS = 'reqres_create_success';
export const REQRES_ERROR = 'reqres_error';

// Alert actions
export const FETCH_ALERTS = "fetch_alerts"
export const READ_ALERT = "read_alert"

// Response actions
export const ADD_INSOMNIA_RESPONSE = "add_insomnia_response"

// Filter Actions
export const FILTER_BY_DATE = "filter_by_date"