export const mock_drugs = [
  {
    name: 'Abatacept',
    description:
      'Blocks the activity of T-cells, a type of immune cell in the body that causes swelling and joint damage in people who have arthritis',
    indications: ['rheumatoid arthritis', 'active psoriatic arthritis', 'idiopathic arthritis'],
    brandNames: ['Orencia'],

    commonInteractions:
      'Risk or severity of infection can be increased when Adalimumab is combined with Abatacept. The risk or severity of adverse effects can be increased when Abatacept is combined with Tocilizumab.',
    commonSideEffects: [
      'headaches',
      'upper respiratory tract infection',
      'nasopharyngitis',
      'nausea',
    ],
    administration: ['injection'],
    organClearance: ['kidney', 'liver'],
    dosage: ['125 mg', '250 mg', '87.5 mg'],
    price: '$1,000',
  },
  {
    name: 'Adalimumab',
    description:
      'Blocks the activity of T-cells, a type of immune cell in the body that causes swelling and joint damage in people who have arthritis',
    indications: [
      'Rheumatoid Arthritis',
      'Juvenile Idiopathic Arthritis',
      'Psoriatic Arthritis',
      'Ankylosing Spondylitis',
      'Crohn’s Disease',
      'Ulcerative Colitis',
      'chronic plaque psoriasis',
      'Hidradenitis Suppurativa',
    ],
    brandNames: ['Amjevita', 'Cyltezo', 'Humira'],

    commonInteractions:
      'The risk or severity of infection can be increased when Adalimumab is combined with Abatacept.',
    commonSideEffects: ['monitored for any signs or symptoms'],
    administration: ['injection'],
    organClearance: ['opsonization via the reticuloendothelial system'],
    dosage: ['10 mg', '20 mg', '40 mg'],
    price: '$1,000',
  },
  {
    name: 'Tocilizumab',
    description:
      'Blocks the activity of T-cells, a type of immune cell in the body that causes swelling and joint damage in people who have arthritis',
    indications: [
      'rheumatoid arthritis',
      'giant cell arteritis',
      'lung disease',
      'idiopathic arthritis',
      'cytokine release syndrome',
    ],
    brandNames: ['Actemra', 'RoActemra'],
    commonInteractions:
      'The risk or severity of adverse effects can be increased when Abatacept is combined with Tocilizumab.',
    commonSideEffects: ['neutropenia'],
    administration: ['injection', 'IV'],
    organClearance: ['opsonization via the reticuloendothelial system'],
    dosage: ['20 mg/1mL', '162 mg/0.9mL', '400 mg/20mL'],
    price: '$1,000',
  },
  {
    name: 'Azathioprine',
    description:
      "Azathioprine is an immunosuppressant used to prevent renal transplant rejection, treat rheumatoid arthritis, Crohn's disease, and ulcerative colitis",
    indications: ['rheumatoid arthritis', 'renal transplant rejection'],
    brandNames: ['Azasan', 'Imuran'],
    commonInteractions:
      'risk or severity of adverse effects can be increased when Alefacept is combined with Azathioprine, risk or severity of adverse effects can be increased when Alemtuzumab is combined with Azathioprine',
    commonSideEffects: ['neutropenia'],
    administration: ['oral'],
    organClearance: ['unknown'],
    dosage: ['50 mg', '100 mg'],
    price: '$1,000',
  },
  {
    name: 'Aceclofenac',
    description:
      'an oral non-steroidal anti-inflammatory drug (NSAID) with marked anti-inflammatory and analgesic properties used to treat osteoarthritis, rheumatoid arthritis and ankylosing spondylitis',
    indications: ['osteoarthritis', 'rheumatoid arthritis', 'ankylosing spondylitis'],
    brandNames: ['unknown'],
    commonInteractions:
      'The risk or severity of adverse effects can be increased when Acetaminophen is combined with Aceclofenac.',
    commonSideEffects: [
      'dyspepsia',
      'abdominal pain',
      'nausea',
      'rash',
      'ruber',
      'urticaria',
      'symptoms of enuresis',
      'headache',
      'dizziness',
      'drowsiness',
    ],
    administration: ['oral'],
    organClearance: ['urine'],
    dosage: ['200 mg'],
    price: '$1,000',
  },
  {
    name: 'Etanercept',
    description:
      'A protein therapy based on the binding fragment of the tumour necrosis factor alpha receptor used to treat severe rheumatoid arthritis and moderate to severe plaque psoriasis.',
    indications: [
      'rheumatoid arthritis',
      'plaque psoriasis',
      'psoriatic arthritis',
      'ankylosing spondylitis',
    ],
    brandNames: ['Enbrel', 'Eticovo'],
    commonInteractions:
      'The risk or severity of infection can be increased when Etanercept is combined with Abatacept.',
    commonSideEffects: [],
    administration: ['injection'],
    organClearance: ['Not Available'],
    dosage: ['25 mg', '50 mg'],
    price: '$1,000',
  },
  {
    name: 'Methotrexate',
    description:
      'An antineoplastic agent used the treatment of a wide variety of cancers as well as severe psoriasis, severe rheumatoid arthritis, and juvenile rheumatoid arthritis.',
    indications: [
      'rheumatoid arthritis',
      'polyarticular juvenile idiopathic arthritis',
      'recalcitrant',
      'disabling psoriasis',
    ],
    brandNames: ['Metoject', 'Nordimet', 'Otrexup', 'Rasuvo', 'Reditrex', 'Trexall', 'Xatmep'],
    commonInteractions: '',
    commonSideEffects: [],
    administration: ['Oral', 'Injection'],
    organClearance: ['urine'],
    dosage: ['2.5 mg', '15 mg/ 0.3 mL'],
    price: '$1,000',
  },
  {
    name: 'Hydroxychloroquine',
    description:
      'A disease modifying anti-rheumatic drug (DMARD) indicated for treatment of rheumatoid arthritis and lupus erythematosus.',
    indications: [
      'malaria',
      'chronic discoid lupus erythematosus',
      'systemic lupus erythematosus',
      'acute rheumatoid arthritis',
      'chronic rheumatoid arthritis',
    ],
    brandNames: ['Plaquenil'],
    commonInteractions: '',
    commonSideEffects: [],
    administration: ['Oral'],
    organClearance: ['Renal'],
    dosage: ['200 mg'],
    price: '$1,000',
  },
  {
    name: 'Sulfasalazine',
    description:
      "A salicylate used to treat Crohn's disease, ulcerative colitis, and rheumatoid arthritis.",
    indications: ['ulcerative colitis', 'rheumatoid arthritis'],
    brandNames: ['Azulfidine', 'Salazopyrin', 'Salazopyrin En-tabs'],
    commonInteractions: '',
    commonSideEffects: [],
    administration: ['Oral'],
    organClearance: ['Urine'],
    dosage: ['500 mg'],
    price: '$1,000',
  },
  {
    name: 'Leflunomide',
    description: 'A pyrimidine synthesis inhibitor indicated to treat rheumatoid arthritis.',
    indications: ['rheumatoid arthritis'],
    brandNames: ['Arava'],
    commonInteractions:
      'The risk or severity of adverse effects can be increased when Abatacept is combined with Leflunomide.',
    commonSideEffects: [],
    administration: ['Oral'],
    organClearance: ['renal'],
    dosage: ['10 mg', '20 mg', '100 mg'],
    price: '$1,000',
  },
  {
    name: 'Certolizumab pegol',
    description:
      "a tumor necrosis factor (TNF) blocker used to treat a variety of autoimmune and autoinflammatory conditions like Crohn's disease, rheumatoid arthritis, active psoriatic arthritis, ankylosing spondylitis, axial spondyloarthritis, and plaque psoriasis.",
    indications: [
      'rheumatoid arthritis',
      "Chron's disease",
      'psoriatic arthritis',
      'ankylosing spondylitis',
      'plaque psoriasis',
      'axial spondyloarhritis',
    ],
    brandNames: ['Cimzia'],
    commonInteractions: '',
    commonSideEffects: [],
    administration: ['Injection'],
    organClearance: ['renal'],
    dosage: ['200 mg'],
    price: '$1,000',
  },
  {
    name: 'Golimumab',
    description:
      'A TNFα inhibitor used in the symptomatic treatment of various active inflammatory disorders, such as rheumatoid arthritis, psoriatic arthritis, ankylosing spondylitis, and ulcerative colitis.',
    indications: [
      'rheumatoid arthritis',
      'psoriatic arthritis',
      'ankylosing spondylitis',
      'ulcerative colitis',
      'polyarticular juvenile idiopathic arthritis',
    ],
    brandNames: ['Simponi'],
    commonInteractions:
      'The risk or severity of infection can be increased when Golimumab is combined with Abatacept. The risk or severity of adverse effects can be increased when Golimumab is combined with Upadacitinib. The risk or severity of adverse effects can be increased when Ustekinumab is combined with Golimumab.',
    commonSideEffects: [
      'bacterial sepsis',
      'tuberculosis (TB)',
      'invasive fungal (histoplasmosis)',
    ],
    administration: ['injection'],
    organClearance: ['not determined'],
    dosage: ['50 mg', '100 mg'],
    price: '$1,000',
  },
  {
    name: 'Infliximab',
    description:
      "a monoclonal anti tumor necrosis factor alpha antibody used in the treatment of a wide variety of inflammatory conditions such as rheumatoid arthritis, Crohn's disease, and ankylosing spondylitis.",
    indications: [
      'Crohn’s disease',
      'ulcerative colitis',
      'rheumatoid arthritis',
      'ankylosing spondylitis',
      'psoriatic arthritis',
      'plaque psoriasis',
    ],
    brandNames: ['Avsola', 'Flixabi', 'Inflectra', 'Remicade', 'Renflexi'],
    commonInteractions: '',
    commonSideEffects: [],
    administration: ['injection'],
    organClearance: ['Liver'],
    dosage: ['100 mg'],
    price: '$1,000',
  },
];

export const textbookDrugs = [
  {
    name: 'Abatacept',
    brandNames: 'Orencia',
    drugClass: 'Biologic DMARD, co-stimulation modulator',
    __EMPTY: '250 mg vial for IV, 125 mg single dose pre-filled syringe for subQ injection',
    dosage:
      'For RA: IV over 30 minutes in a dose of 500 mg for patients <60 kg, 750 mg for 60-100 kg, 1000 mg for > 100 kg. Dose is repeated at 2 and 4 weeks and then every 4 weeks thereafter. Or subQ injection 125 mg weekly; can be started without an IV loading dose or can load with one infusion of IV preparation (dose above) followed immediately by 125 mg subQ.',
    administration: 'injection',
    indications:
      'Rheumatoid Arthritis with inadequate response to one or more DMARDs, polyarticular juvenile idiopathic arthritis',
    mechanismOfAction:
      'A soluble fusion protein of the extracellular domain of human cytotoxic T-lymphocyte-associated antigen 4 (CTLA-4) joined to the modified Fc portion of human IgG1. Inhibits T-cell activation by binding to CD80 and CD86 blocking interaction with CD28.',
    organClearance: 'Kidney and liver',
    contraindications:
      'Hypersensitivity, untreated tuberculosis and other opportunistic infections, sepsis, active infection, chronic localized or recurrent infections',
    precautions:
      'Increased risk of serious infections including TB and fungal. Exclude latent or active TB with a skin test or TB blood test (interferon-gamma release assays or IGRAs) before starting therapy. The risk of opportunistic infections is increased. Caution if used in the debilitated or in those at high risk of infection. Avoid live virus vaccines. Should not be used in combination with other biologics (increased risk of serious infection). Exclude hepatitis B infection.',
    __EMPTY_1:
      'Monitor clinically for infection. After therapy started, additional TB testing may be indicated for individuals likely to have exposure to TB.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Upper respiratory infections\r\nLess common: Allergy, infusion reactions, injection site reactions (subQ), exacerbation of COPD, infection (viral, bacterial and opportunistic infections such as tuberculosis, listeriosis, and histoplasmosis), falsely elevated blood glucose on test strips on day of IV infusion (contains maltose) with certain blood glucose strips\r\nRare: Anaphylaxis, reactivation of hepatitis B, cancer',
    commonInteractions:
      ' Concurrent use of high-dose steroids and other immunosuppressants may increase risk of infection. Avoid concurrent biologics.',
    patientInstructions:
      'Avoid live virus vaccines; avoid pregnancy; stop injections and call your doctor if an infection or fever develops that lasts more than a few days.',
    comments:
      'Response rates to abatacept and TNF antagonists appear similar but some patients who do not respond to an anti-TNF respond to abatacept (and vice versa). Patients start to respond quickly, usually within 4â€“6 weeks although maximum response may not occur until after 4-6 months of treatment. However, not all patients respond. Combined therapy with methotrexate is more effective than methotrexate alone.',
    clinicalPharmacology:
      'Half-life is 13 days; is not metabolized and thus few drug-drug interactions',
    price: '$$$$$',
    citation:
      'Weinblatt ME, Schiff M, Valente R, van der Heijde D, Citera G, Zhao C, Maldonado M, Fleischmann R. Head-to-head comparison of subcutaneous abatacept versus adalimumab for rheumatoid arthritis: findings of a phase IIIb, multinational, prospective, randomized study. Arthritis Rheum 2013;65:28-38. PMID: 23169319.',
  },
  {
    name: 'Acetaminophen + Opioid (Codeine/Hydrocodone/Oxycodone/Tramadol)\r\n',
    brandNames:
      '\r\nAcetaminophen + codeine: Capital and Codeine, Tylenol with Codeine No.3 , Tylenol with Codeine No.4\r\nAcetaminophen + hydrocodone: Lortab, Lorcet, Lorcet Plus, Vicodin, Vicodin ES, Xodol\r\nAcetaminophen + oxycodone: Percocet, Roxicet, Roxilox, Tylox\r\nAcetaminophen + tramadol: Ultracet',
    drugClass: 'Analgesic/antipyretic with opioid analgesic',
    __EMPTY:
      'Acetaminophen + codeine\r\nTablet: Acetaminophen with codeine #2: 15 mg codeine/300 mg acetaminophen; #3, 30/300 mg; #4, 60/300 mg\r\nCapsules: Acetaminophen with codeine #2: 15 mg codeine/325 mg acetaminophen; #3, 30/325 mg; #4, 60/325 mg\r\nSuspension: Acetaminophen with codeine: 12 mg codeine/120 mg acetaminophen per 5 mL.\r\nAcetaminophen + hydrocodone\r\nTablet: Lortab 2.5/325 (2.5 mg hydrocodone/325 mg acetaminophen); Lortab 5/325; Lortab 7.5/325; Lorcet 5/325; Lorcet Plus 7.5/325; Vicodin 5/300; Vicodin ES 7.5/300\r\nAcetaminophen + oxycodone\r\nTablet: Percocet â€“ oxycodone 2.5 mg/acetaminophen 325 mg, 5/325 mg, 7.5/325 mg, 10/325 mg\r\nAcetaminophen + tramadol: Ultracet (37.5 mg tramadol and 325 mg acetaminophen)',
    dosage:
      'Dose\r\nDo not exceed acetaminophen 4 g/day from all sources (some experts recommend that the maximum daily acetaminophen dose should be 3 g/day).\r\nThe dose of narcotic is selected and titrated according to the severity of the pain and how opioid tolerant the patient is.\r\n\r\nAcetaminophen + codeine: Adult dose, one to two tablets containing acetaminophen 300 mg + codeine 15 or 30 mg q 4â€“6 h p.r.n. with total daily dose not to exceed 8 tablets\r\nAcetaminophen + hydrocodone: Adult dose, one to two tablets containing 325 acetaminophen + 5 to 10 mg hydrocodone q 4â€“6 h p.r.n. with total daily dose not to exceed 8 tablets\r\nAcetaminophen + oxycodone: Adult dose, one to two tablets containing 325 acetaminophen + 5 to 10 mg oxycodone q 6 h p.r.n. with total daily dose not to exceed 8 tablets\r\nAcetaminophen + tramadol: Adult dose, one to two tablets q 4-6 h p.r.n. (not to exceed 8 tablets per day or 5 days of treatment)',
    indications:
      'Pain unresponsive to nonopioid regimens. Most studies show little benefit over nonopioid regimens in chronic musculoskeletal pain.',
    mechanismOfAction:
      'Acetaminophen (uncertain) inhibits central prostaglandin synthesis. Opioids bind to opioid receptors in CNS and modify pain perception.',
    contraindications: 'Hypersensitivity reactions; opioid or prescription drug abuse',
    precautions:
      'Concomitant alcohol use, liver disease and fasting may increase the risk of acetaminophen hepatotoxicity. Avoid or use lower doses with caution in liver disease (usually <2 g/day). Avoid concomitant alcohol. May cause severe hepatotoxicity in overdose. Patient must avoid self-medication with OTC preparations that may also contain acetaminophen. Risk of psychological and physical narcotic dependence should limit use. Avoid dose escalation. Limit dose and duration of therapy if possible. One physician should prescribe all opioids for a particular patient.',
    pregnancyRisk: 'C/D (neonatal abstinence syndrome)',
    commonSideEffects:
      ': Acetaminophen rarely causes allergy, Stevens-Johnson syndrome, rash, or agranulocytosis. Hepatotoxicity is rare at therapeutic doses. Overdose (usually >8 g/day) causes delayed (48â€“72 hours) and potentially fatal hepatotoxicity. Controversial evidence links chronic use of acetaminophen to increased risk of renal impairment.\r\nOpioid toxicity (common): GI intolerance, constipation, and dependence are seen with chronic use. Less common are allergy, confusion, dizziness, nervousness, insomnia, and respiratory depression (see individual opioids).',
    commonInteractions:
      '\r\nAlcohol: Increases risk of hepatotoxicity\r\nWarfarin: Acetaminophen (>2 g/day) may increase the anticoagulant effect\r\nCNS depressants: Effects may be potentiated by opioids',
    patientInstructions:
      'Do not exceed prescribed dose. Do not take additional OTC or prescription medications that contain acetaminophen. Do not drink alcohol. May cause drowsiness and constipation. Contains a narcotic and is addictive. Only take for pain. Keep away from children.',
    comments:
      'Comment: Opioid/narcotic medications are seldom required in the treatment of inflammatory joint disease (i.e., RA, gout). In such conditions, control of inflammation usually controls the associated pain.',
  },
  {
    name: 'Acetaminophen\r\n',
    brandNames: 'Include Acephen, Aspirin-free Anacin, Tylenol, Tylenol Arthritis',
    drugClass: 'Analgesic/antipyretic',
    synonyms: 'Synonyms: Paracetamol',
    __EMPTY:
      '\r\nCapsules: 325 and 500 mg (extra strength)\r\nTablets: 120, 160, 325, 500 mg; 650 mg (extended-release)\r\nSuppositories: 80, 120, 325, 650 mg\r\nElixir, suspension, liquid, or syrup: 100 mg/mL and 160 mg/5 mL',
    dosage:
      'Adults, 1â€“3 g/day in three to four divided doses; do not exceed total daily dose of 4 g/day from all sources. Some experts believe the maximum daily dose of acetaminophen should be limited to 3 g/day.',
    indications:
      'Pain, musculoskeletal pain, headache, fever; less likely to cause GI ulceration than NSAIDs',
    mechanismOfAction: 'Uncertain; may inhibit COX-2 and central prostaglandin synthesis',
    contraindications: 'Hypersensitivity to acetaminophen',
    precautions:
      'Concomitant alcohol use, liver disease and fasting may increase the risk of acetaminophen hepatotoxicity. Avoid or use lower doses with caution in liver disease (usually <2 g/day). Avoid concomitant alcohol. May cause severe hepatotoxicity in overdose. Patients must avoid self-medication with OTC preparations that may also contain acetaminophen.',
    pregnancyRisk: 'B',
    commonSideEffects:
      ': Rarely causes allergy, Stevens-Johnson syndrome, rash, or agranulocytosis. Hepatotoxicity is rare at therapeutic doses. Overdose (usually >8 g/day) causes delayed (48â€“72 hours) and potentially fatal hepatotoxicity. Controversial evidence links chronic use to increased risk of renal impairment.',
    commonInteractions:
      '\r\nAlcohol: Increases risk of hepatotoxicity\r\nWarfarin: Acetaminophen (>2 g/day) may increase anticoagulant effect Barbiturates, carbamazepine, hydantoins, and sulfinpyrazone: May increase hepatotoxicity of acetaminophen',
    patientInstructions:
      'Do not exceed prescribed dose; do not take additional OTC or prescription medications that contain acetaminophen; do not drink alcohol.',
    clinicalPharmacology:
      'Rapid complete oral absorption; 95% metabolized in the liver (mainly conjugation). Duration of action is 4â€“6 hours. With overdose, a hepatotoxic metabolite accumulates.',
    price: '$',
  },
  {
    name: 'Adalimumab\r\n',
    brandNames: 'Amjevita, Cyltezo, Humira',
    drugClass: 'Biologic DMARD, TNF antagonist',
    __EMPTY: '40 mg injection',
    dosage:
      'For RA 40 mg subQ injection every 2 weeks. Some other indications have a loading dose.',
    administration: 'injection',
    indications:
      'Rheumatoid Arthritis, psoriatic arthritis, plaque psoriasis, ankylosing spondylitis, juvenile idiopathic arthritis, Crohnâ€™s disease, ulcerative colitis',
    mechanismOfAction:
      'A human IgG1 antibody against TNF-alpha that blocks the effects of the cytokine',
    contraindications:
      'Hypersensitivity, untreated tuberculosis and other opportunistic infections, sepsis, active infection, chronic localized or recurrent infections, demyelinating disease, optic neuritis, moderate-to-severe heart failure',
    precautions:
      'Increased risk of serious infections including TB and fungal. Exclude latent or active TB with a skin test or TB blood test (interferon-gamma release assays or IGRA). Caution in debilitated or high risk of infection. Exclude active hepatitis B infection or carriage. Avoid live virus vaccines and BCG. Do not use with other biologics. May exacerbate pre-exisiting demyelinating disease and heart failure.',
    __EMPTY_1:
      'Monitor clinically for infection. Periodic CBC. After therapy started, additional TB testing may be indicated for individuals likely to have exposure to TB.',
    pregnancyRisk: 'B',
    commonSideEffects:
      '\r\nCommon: Injection site reactions, positive antinuclear and double stranded DNA antibodies\r\nLess common: Allergy, infection (bacterial, but particularly opportunistic infections such as tuberculosis, listeriosis, and histoplasmosis)\r\nRare: Lymphoma (including fatal hepatosplenic T-cell lymphoma), hepatitis, demyelinating CNS disorders, optic neuritis, seizures, pancytopenia, drug-induced lupus, reactivation of hepatitis B, new onset psoriasis',
    commonInteractions:
      ' Concurrent use of high-dose steroids and other immunosuppressants may increase risk of infection.',
    patientInstructions:
      'Avoid live virus vaccines; avoid pregnancy; stop injections and call your doctor if an infection or fever develops that lasts more than a few days',
    comments:
      'TNF antagonists are among the most effective treatments for RA. Patients start to respond quickly, usually within 4â€“6 weeks but maximum response may take 4-6 months. In RA, combined therapy with an anti-TNF drug and MTX is more effective than either drug alone. TNF antagonists are being explored in a range of diseases such as sarcoidosis and inflammatory eye disease. Risk of tuberculosis may be lower with etanercept than other TNF antagonists; comparative risk of adverse effects among individual TNF antagonists is not clear and side effects likely a class effect. Patients may form antibodies to anti-TNF drugs that decrease their effect. Concurrent treatment with MTX may reduce the frequency of this.',
    clinicalPharmacology:
      'Half-life is 14 days. Biologic agents are not metabolized and thus have few drug interactions.',
    price: '$$$$$',
    citation:
      'Bombardier C, Hazlewood GS, Akhavan P, et al. Canadian Rheumatology Association recommendations for the\r\npharmacological management of rheumatoid arthritis with traditional and biologic disease-modifying antirheumatic drugs: part II safety. J Rheumatol 2012;39:1583-602. PMID: 22707613.',
  },
  {
    name: 'Alendronate\r\n',
    brandNames: 'Fosamax',
    drugClass: 'Bisphosphonate',
    __EMPTY: '5-, 10-, 35-, 40-, 70-mg tablets',
    dosage:
      'Dose/administration\r\nOsteoporosis treatment: 10 mg p.o. daily or 70 mg once weekly\r\nPagetâ€™s disease: 40 mg p.o. daily for 6 months. If disease relapses, retreatment can be considered.',
    indications: 'Treatment and prevention of osteoporosis; treatment of Pagetâ€™s disease',
    mechanismOfAction:
      'Antiresorptive; localizes to areas of bone resorption and inhibits osteoclast activity without any effect on bone formation; increases bone mineral density and significantly reduces fracture rates.',
    contraindications:
      'Hypersensitivity to alendronate, hypocalcemia, esophageal stricture or dysmotility. Not recommended for patients with severe renal insufficiency (creatinine clearance <35 mL/min). Avoid use in patients who cannot stand or sit upright for 30 minutes after administration.',
    precautions:
      'Avoid if possible if esophageal problems or renal impairment are present. Ensure that patient understands how the drug should be taken. A dental exam and preventive dental work should be performed before starting chronic bisphosphonate therapy.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Mild GI disturbance (i.e., nausea, dyspepsia, dysphagia), hypocalcemia (transient, mild), headache\r\nUncommon: severe erosive esophagitis, atypical subtrochanteric femur fractures, osteonecrosis of the jaw, severe musculoskeletal pain, uveitis, altered taste, urticaria, angioedema, atrial fibrillation',
    commonInteractions: ' GI adverse events are increased in patients taking NSAIDs.',
    patientInstructions:
      'Take on an empty stomach (2 hours before meals). Should be taken with a full glass of water on arising in the morning. Nothing other than water should be taken for at least 30 minutes. Even coffee or fruit juice markedly reduce absorption. Delaying such intake for longer than 30 minutes (1â€“2 hours if possible) maximizes absorption. After taking, the patient must remain upright to reduce risk of esophageal irritation. Any other medications must be taken at least 30 minutes after bisphosphonate. Supplement calcium and vitamin D intake if needed.',
    comments:
      'Supplemental calcium and vitamin D are usually co-administered. Weekly dosing for osteoporosis is preferred. The optimal duration of treatment is uncertain. A temporary discontinuation may be considered after 3-5 years of therapy in patients at low risk of fracture with monitoring of bone density 2-3 years after discontinuation.',
    clinicalPharmacology:
      'Oral bioavailability is very poor (<1%) and negligible if administered with or after food. Absorbed drug is renally excreted and not metabolized. Elimination from body over months or years indicating localization and slow release from bone.',
    price: '$$$',
    citation:
      'McClung M, Harris ST, Miller PD et al. Bisphosphonate therapy for osteoporosis: benefits, risks and drug holiday. Am J Med 2013;126:13-20.PMID: 23177553',
  },
  {
    name: 'Allopurinol\r\n',
    brandNames: 'Zyloprim',
    drugClass: 'Xanthine oxidase inhibitor; antigout agent',
    __EMPTY: '100- and 300-mg tablets',
    dosage:
      'For prophylaxis of gout, initially use 100 mg/day, increased at 2-4 week intervals according to uric acid response. In the elderly and those with renal impairment, use 50 mg or less/day initially.\r\n\r\nCommon maintenance dose is 300 mg/day but more than 50% of patients require higher doses. Maximum recommended dose in patients without renal impairment is 800 mg/day.\r\n\r\nIf required in patients with renal impairment, allopurinol starting dose is modified according to creatinine clearance â€“ approximately 1.5 mg allopurinol/ml of creatinine clearance and no more than 50 mg a day. Based on uric acid response many patients with renal impairment can be carefully titrated to higher doses although final doses used are lower than in those without renal impairment and long term safety not established.',
    indications: 'To treat hyperuricemia in patients with gout',
    mechanismOfAction:
      'Inhibits formation of uric acid by inhibiting xanthine oxidase; has no anti-inflammatory activity',
    contraindications:
      'Previous hypersensitivity to allopurinol. Avoid concurrent azathioprine or mercaptopurine.',
    precautions:
      'Reduce dose in the elderly, patients on diuretics, and patients with renal impairment. Check HLA-B*5801 in patients of Asian ancestry before starting allopurinol and avoid if positive.',
    __EMPTY_1:
      'Monitor CBC and liver and renal function periodically, particularly in the first few months of treatment. Uric acid level guides the allopurinol dose. Aim to reduce uric acid concentration to <6 mg/dL in tophaceous disease.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Acute gout may occur with increased frequency after starting allopurinol; thus, concurrent colchicine or NSAID treatment is used, particularly in first 3â€“6 months of therapy. Rash.\r\nRarely: Allopurinol hypersensitivity syndrome (exfoliative dermatitis/erythema multiforme, renal failure, hepatic impairment, vasculitis) is rare but may be fatal. Risk is increased in patients with impaired renal function, those receiving diuretics and those carrying HLA-B*5801. Desensitization has been tried in selected patients. Febuxostat, an alternative xanthine oxidase inhibitor, appears to be tolerated by many (but not all) patients who are allergic to allopurinol. Agranulocytosis and aplastic anemia are rare.',
    commonInteractions:
      '\r\nAzathioprine and mercaptopurine: This is a potentially fatal interaction. Allopurinol decreases metabolism of these agents, leading to toxicity (bone marrow depression).\r\nWarfarin: Anticoagulant effects potentiated\r\nAmpicillin/amoxicillin: Increased risk of rash.\r\nDiuretics: Increased risk of allopurinol toxicity\r\nChlorpropamide: Increased chlorpropamide serum half-life\r\nPegloticase: Urate lowering therapies including allopurinol should be discontinued prior to the use of pegloticase and should not be initiated during pegloticase therapy.\r\nAlcohol: Decreases effectiveness.',
    patientInstructions:
      'The drug needs to be taken every day (or as prescribed) to lower uric acid and thus prevent gout. It has no effect on the symptoms of gout and will not help when joints hurt. Discontinue drug and contact physician if rash develops. Do not drink alcohol.',
    comments:
      'Allopurinol is used primarily to lower the uric acid level in patients with recurrent attacks of acute gout (more than one per year) or patients with tophaceous gout. Start with low doses (to minimize risk of precipitating acute attacks and perhaps decrease risk of hypersensitivity) and increase at 4-8 week intervals if needed. Allopurinol is preferred over a uricosuric agent in patients with tophi, renal stones, and impaired renal function. Monitor serum uric acid and titrate dose with aim to decrease to 5â€“6 mg/dL. Failure to decrease uric acid often indicates poor compliance. Prevent acute attacks of gout with concurrent colchicine or NSAID treatment, particularly in first 3â€“6 months of therapy. High doses of aspirin are uricosuric but low doses increase uric acid levels.',
    citation:
      'Terkeltaub RA. Clinical practice. Gout. N Engl J Med 2003;349:1647â€“1655.PMID: 14573737',
  },
  {
    name: 'Amitriptyline\r\n',
    brandNames: 'Elavil and others',
    drugClass: 'Tricyclic antidepressant',
    __EMPTY: '10-, 25-, 50-, 75-, 100-, 150-mg tablets',
    dosage:
      'Initially, for fibromyalgia 10 mg is taken 2 hours before bedtime. The dose can be increased, if tolerated. The usual maintenance dose for fibromyalgia is 10â€“75 mg taken 2 hours before bedtime. The maximum daily dose for depression is 300 mg/day.',
    indications:
      'Depression, fibromyalgia, insomnia, an adjunct for pain control (chronic or neuropathic), prophylaxis for migraines',
    mechanismOfAction:
      'Inhibits uptake of norepinephrine and serotonin in adrenergic and serotonergic neurons',
    contraindications:
      'Hypersensitivity. Avoid in recovery period of myocardial infarction or if arrhythmias exist. Avoid for at least 14 days after patient has received a MAOI. Avoid in patients with narrow-angle glaucoma.',
    precautions:
      'Antidepressants may increase suicidality, particularly in children and adolescents. Escalate dose slowly; it may cause drowsiness and affect the ability to drive or operate machinery; it aggravates symptoms of prostatism and keratoconjunctivitis sicca. It may precipitate acute glaucoma and may lower seizure threshold. Long-term high doses should not be abruptly discontinued; use with caution in patients with a history of hyperthyroidism, renal or hepatic impairment, or cardiac conduction disturbances. Avoid in elderly if possible (increased sedation, orthostasis, falls).',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Sedation and anticholinergic effects (dry eyes and mouth, blurred vision, constipation, difficulty with urination) are common; tolerance often occurs with continued use.\r\nLess common: Postural hypotension, restlessness, tremor, parkinsonian syndrome, insomnia, rash\r\nRarely: Agranulocytosis, hepatic dysfunction, alopecia, arrhythmias, breast enlargement',
    commonInteractions:
      '\r\nAlcohol and CNS depressants: Effect of tricyclics is potentiated.\r\nCimetidine, methylphenidate, many SSRIs, ritonavir, indinavir, diltiazem, verapamil and cytochrome P450 2D6 inhibitors such as buproprion: Inhibit metabolism of tricyclics; dose reduction may be required\r\nMAOIs: Increased risk of hypertensive crises; deaths have been reported\r\nSympathomimetics: Tricyclics potentiate effect\r\nClonidine: Amitriptyline inhibits hypotensive effects\r\nWarfarin: Prothrombin time may be prolonged\r\nDrugs that prolong QT interval: Increased risk of arrhythmia',
    patientInstructions: 'Avoid alcohol use; may cause drowsiness',
    clinicalPharmacology:
      'Well absorbed, hepatic metabolism with renal excretion of metabolites; onset of antidepressant effects slow (2â€“4 weeks)',
    price: '$',
  },
  {
    name: 'Anakinra\r\n',
    brandNames: 'Kineret',
    drugClass: 'Biologic DMARD, IL-1 receptor antagonist',
    __EMPTY: '100-mg pre-filled syringe',
    dosage: 'For RA 100 mg subQ injection daily. Decrease dose if renal function impaired.',
    indications: 'Rheumatoid Arthritis, NOMID',
    mechanismOfAction: 'Binds to IL-1 receptors and blocks the effects of the cytokine',
    contraindications:
      'Hypersensitivity to anakinra or E. coli derived proteins, active infection, do not use with TNF-antagonists, other biologics, and tofacitinib. Avoid live virus vaccines.',
    precautions: 'Discontinue if patient develops an infection',
    __EMPTY_1:
      'Monitor clinically for infection; CBC monthly for 3 months, then every 3 months, TB testing at baseline and consider retesting periodically based on risk.',
    pregnancyRisk: 'B',
    commonSideEffects:
      '\r\nCommon: Injection site reactions (73% mild, 24% moderate, 2% severe)\r\nLess common: Allergy, infection (bacterial, but also atypical infections), leukopenia\r\nRare: Neutropenia, thrombocytopenia',
    commonInteractions:
      ' Concurrent use of other immunosuppressants, particularly TNF antagonists, may increase risk of infection',
    patientInstructions:
      'Avoid live virus vaccines. Avoid pregnancy. Stop injections if an infection or a fever develops that lasts more than a few days.',
    comments:
      'Combination therapy with methorexate is generally well tolerated and is more effective than monotherapy. Anakinra appears less effective than TNF antagonists and other modern drugs for RA and is little used for RA in clinical practice. Combination with TNF antagonists is avoided because of increased risk of infection. In case-series, anakinra treatment for 3 days (off-label) is effective for the treatment of acute gout in patients unable to tolerate, or not responding to, standard therapies such as NSAIDs, colchicine or corticosteroids. In small case-series, anakinra has also been reported to be effective in other conditions including adult-onset Stillâ€™s disease and idiopathic recurrent pericarditis.',
    clinicalPharmacology:
      'Half-life is 6 hours with renal excretion. Biologic agents are not metabolized and have few drug interactions.',
    price: '$$$$$',
    citation:
      'Cohen S, Hurd E, Cush J, et al. Treatment of rheumatoid arthritis with anakinra, a recombinant human interleukin-1 receptorantagonist, in combination with methotrexate: results of a twenty-four-week, multicenter, randomized, double-blind, placebo-controlled trial. Arthritis Rheum2002;46:614â€“624. PMID:11920396',
  },
  {
    name: 'Apremilast\r\n',
    brandNames: 'Otezla',
    drugClass: 'Phosphodiesterase type 4 (PDE4) inhibitor',
    __EMPTY: '10-, 20-, 30-mg tablets',
    dosage:
      'Starter pack titrates dose over 5 days to avoid GI side effects, day 1: 10 mg, day 2: 10 mg bid, day 3: 10 mg am, 20 mg pm, day 4: 20 mg bid, day 5: 20 mg am, 30 mg pm, day 6 and thereafter 30 mg bid. Target dose is 30 mg bid; 20 mg bid in patients with side effects; and 30 mg qd with renal insufficiency.',
    indications: 'Psoriatic arthritis, Psoriasis',
    mechanismOfAction: 'Inhibits PDE4 and thus increases cyclic AMP concentrations.',
    contraindications: 'Hypersensitivity',
    precautions: 'Caution in depression. Decrease dose 50% in severe renal impairment.',
    __EMPTY_1: 'Monitor weight.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Diarrhea (up to 17%), nausea, headache, weight loss (10%)\r\nLess common: Vomiting, abdominal pain, depression, rash',
    commonInteractions:
      ' Concurrent use of rifampin and other strong cytochrome P450 (CYP) enzyme inducers such as phenytoin decrease apremilast concentrations.',
    patientInstructions:
      'Be alert to emergence or worsening of depression. Weight loss (5-10% of body weight) may occur.',
    comments:
      'Modestly effective in psoriatic arthritis with approximate ACR20 response rates of 40% vs 20% with placebo. Skin may improve. Compared to biologics, oral dosing convenient and lower infection risk.',
    clinicalPharmacology:
      'Well absorbed, half-life is 6-9 h. Metabolized by several CYP enzymes including CYP3A4.',
    price: '$$$$$',
    citation:
      'Kavanaugh A, Mease PJ, Gomez-Reino JJ, et al. Treatment of psoriatic arthritis in a phase 3 randomised, placebo-controlled trial with apremilast, an oral phosphodiesterase 4 inhibitor. Ann Rheum Dis 2014;73:1020-6. PMID: 24595547',
  },
  {
    name: 'Artificial Tears\r\n',
    brandNames:
      '\r\nArtificial tears: Tears Naturale\r\nArtificial tears, preservative free: Tears Naturale Free\r\nOcular ointments: Lacrilube\r\nOcular ointments, preservative free: Refresh PM, Lacriserts',
    drugClass: 'Ophthalmic protectant',
    synonyms: 'Synonyms: Hydroxypropyl methylcellulose, hypromellose, ocular lubricants',
    __EMPTY:
      '\r\nHypromellose 0.3%: Tears Naturale, Tears Naturale Free\r\nHydroxypropyl cellulose ocular system: Lacrisert 5-mg insert',
    dosage:
      'One drop of a 0.3% tear solution applied topically to conjunctiva three to four times daily. In patients with keratoconjunctivitis sicca, drops should be applied regularly even if no symptoms are present. Some patients require more frequent application (every 2 hours). Hydroxypropyl cellulose 5- mg ophthalmic insert: use once or twice daily',
    indications: 'Dry eyes',
    mechanismOfAction: 'Promotes corneal wetting by stabilizing and thickening the tear film',
    contraindications: 'Hypersensitivity',
    precautions: 'Use preservative-free preparations if preservative causes irritation.',
    pregnancyRisk: 'C',
    commonSideEffects: ': Blurring of vision; eye irritation',
    commonInteractions: ' None',
    patientInstructions:
      'Tears may be used as often as required. Regular use rather than waiting until symptoms are present is best. Do not instill with contact lens in place unless preparation is designed for use with contact lenses. Always wash hands before administration. Never touch tip of dropper to the eye surface.',
    clinicalPharmacology: 'No systemic effects after ocular administration',
    price: '$',
  },
  {
    name: 'Aspirin + Oxycodone\r\n',
    brandNames: '\r\nAspirin + oxycodone: Percodan, Roxiprin',
    drugClass: 'Analgesic/antiinflammatory with opioid analgesic',
    __EMPTY: 'Tablets: Aspirin + oxycodone: Aspirin 325 mg/oxycodone 4.5 mg',
    dosage: 'Aspirin + oxycodone: Adult dose, one tablet q 4â€“6 h p.r.n.',
    indications:
      'Pain unresponsive to nonopioid regimens. Most studies show little benefit over nonopioid regimens in chronic musculoskeletal pain.',
    mechanismOfAction:
      'Aspirin inhibits prostaglandin synthesis. Opioids bind to opioid receptors in CNS and modify pain perception.',
    contraindications:
      'Hypersensitivity reactions to opioids or aspirin; opioid or prescription drug abuse; children (Reyeâ€™s syndrome)',
    precautions:
      'precautions\r\nAspirin: Fluid retention may aggravate heart failure and hypertension. Consider misoprostol or proton pump inhibitor prophylaxis in patients at high risk of GI bleeding (prior GI bleeding, peptic ulcer, elderly, concurrent corticosteroid or warfarin treatment). Administer with food. Use with caution in asthma, bleeding disorders, and hepatic or renal disease.\r\nOpioids: Risk of psychologic and physical narcotic dependence should limit use. Avoid dose escalation. Respiratory depression. Limit dose and duration of therapy if possible. One physician should prescribe all opioids for a particular patient.',
    pregnancyRisk: 'C/D (last trimester)',
    commonSideEffects: ': See Aspirin and See Oxycodone',
    commonInteractions: ' See Aspirin and See Oxycodone',
    patientInstructions:
      'Do not exceed prescribed dose. Do not drink alcohol. May cause drowsiness or constipation. Contains a narcotic and is addictive. Only use for pain.',
    clinicalPharmacology: 'See Aspirin and See Oxycodone',
    price: '$$',
  },
  {
    name: 'Aspirin\r\n',
    brandNames:
      '\r\nPlain aspirin: Bayer Aspirin\r\nBuffered aspirin: Ascriptin, Alka-Seltzer, Buffferin\r\nEnteric coated aspirin: Ecotrin,\r\nExtended-release aspirin: Zorprin',
    drugClass: 'NSAID',
    synonyms: 'Synonyms: Acetylsalicylic acid, ASA',
    __EMPTY:
      '\r\nChewable aspirin tablet: 81 mg\r\nTablets: 325 and 500 mg\r\nEnteric coated tablets 81, 325 mg\r\nTimed-/controlled-release tablets: 800 mg',
    dosage:
      'Dose\r\nAnalgesic/antipyretic effect: 325â€“650 mg q 4â€“6 h (as high as 4 g/day) Antiinflammatory effect: Divided doses of 3.6â€“5.4 g/day. In acute rheumatic fever higher doses have been used but dose-related toxicity is common.\r\nAntithrombotic effect: 81â€“325 mg/day (usually 81 mg)',
    indications:
      'Used for antithrombotic effect in myocardial infarction, anticardiolipin antibody syndrome, and transient ischemic attacks. Treatment of minor pain, inflammation, pyrexia, antithrombotic prophylaxis and Kawasaki disease. Seldom used in Rheumatoid Arthritis, osteoarthritis, rheumatic fever, Stillâ€™s disease and inflammatory conditions such as bursitis.',
    mechanismOfAction:
      'Nonselective COX inhibitor; decreases formation of prostaglandins and thromboxane from arachidonic acid. Antiplatelet effect is due to inhibition of thromboxane synthesis.',
    contraindications:
      'Hypersensitivity to aspirin (especially in those with asthma and nasal polyps), GI ulceration, hemorrhagic state, last trimester of pregnancy (may induce premature closure of ductus arteriosus), breast-feeding, children (risk of Reye syndrome), and G6PD deficiency (hemolysis)',
    precautions:
      'Fluid retention with high doses may aggravate heart failure and hypertension. Use with caution or avoid in patients at high risk of GI bleeding (i.e., previous GI bleeding, peptic ulcer, elderly, concurrent corticosteroid or warfarin treatment), but if prescribed, consider misoprostol or proton pump inhibitor prophylaxis. Administer with food. Use with caution in asthma, bleeding disorders, and hepatic or renal disease.',
    __EMPTY_1:
      'In anti-inflammatory doses monitor hematocrit, creatinine, and liver enzymes periodically (1 month after starting and then every 3â€“6 months). In patients at high risk of renal impairment (i.e., diuretics, receiving ACE inhibitors, edematous states, heart failure, renal failure, diabetes), monitor renal function more closely. Consider measuring salicylate levels if using high doses. Serum concentrations of 150â€“300 mcg/mL are antiinflammatory. Salicylism (e.g., tinnitus) is common at levels >200 mcg/mL.',
    pregnancyRisk: 'C (D in third trimester of pregnancy)',
    commonSideEffects:
      '\r\nCommon: GI irritation (dyspepsia, esophageal reflux, epigastric pain); dose-related side effects at concentrations >200 mcg/mL (tinnitus/deafness)\r\nLess common: GI ulceration or hemorrhage, minor elevations of liver enzymes, hypersensitivity (asthma; urticaria; angioedema, particularly in patients with nasal polyps), impaired renal function, acidosis with overdose, and high serum concentrations (>400 mcg/mL), especially in the elderly',
    commonInteractions:
      '\r\nAntacids: Decreased salicylate levels through increased elimination in alkaline urine\r\nAnticoagulants: Activity of warfarin increased; increased hemorrhagic risk with other anticoagulants and thrombolytics\r\nNSAIDs: Increased risk of GI side effects if aspirin is used with other NSAIDs. Coadministration of traditional NSAIDs such as ibuprofen or naproxen (but not COX-2â€“selective NSAIDs) may antagonize the long-term antiplatelet effect of aspirin.\r\nMethotrexate: Aspirin may increase levels of MTX (however, with the doses of MTX used in RA, this is usually clinically insignificant); may potentiate MTX toxicity with high doses\r\nLithium: Increased lithium levels\r\nValproic acid: Aspirin may increase free valproate concentrations and toxicity\r\nProbenecid: Aspirin may antagonize effects',
    patientInstructions:
      'Take with food. Discontinue and seek medical advice if unusual bleeding develops.',
    comments:
      'With high doses, metabolic pathways become saturated, and a small dose increase can result in large increases in plasma concentrations. Enteric coated or delayed-release formulations aspirin may be better tolerated than regular aspirin but are not recommended for prophylaxis of thrombosis.',
    clinicalPharmacology:
      'Rapidly and well absorbed after oral administration. Hydrolyzed to salicylate with hepatic metabolism and renal excretion of conjugated metabolites. Urinary pH alters elimination (alkaline urine increases elimination). Wide variation in plasma concentrations exists in individuals receiving the same dose. Half-life varies with dose (2â€“3 hours with low doses, >10 hours with anti-inflammatory doses). At high doses, the elimination pathway is saturated, and a small increase in dose can lead to a large increase in serum concentration.',
  },
  {
    name: 'Auranofin\r\n',
    brandNames: 'Ridaura',
    drugClass: 'DMARD',
    synonyms: 'Synonyms: Oral gold',
    __EMPTY: '3-mg capsule',
    dosage:
      'Adults, 6 mg p.o. daily as single or divided dose. If no response after 6 months, it can be increased to 9 mg/day (in 3 divided doses). If no response after a further 3 months, discontinue and consider an alternative DMARD.',
    indications: 'Active Rheumatoid Arthritis',
    mechanismOfAction:
      'Unknown; has several immunomodulatory effects, primarily affecting macrophages, including inhibition of phagocytosis',
    contraindications: 'Hypersensitivity to gold; prior blood dyscrasias; severe renal impairment',
    precautions:
      'May exacerbate or cause exfoliative dermatitis. Use caution in hepatic or renal impairment.',
    __EMPTY_1:
      'CBC (WBC count with differential, hemoglobin, and platelet count) and urinalysis before, 1â€“2 weeks after starting treatment, and then monthly. In patients who have tolerated auranofin well for >12 months, the frequency of monitoring can often be decreased to every 2â€“3 months. Check renal and liver function periodically. Discontinue use if significant rash, proteinuria, or decrease in blood counts develops.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Diarrhea or loose stools (approx 50%), rash, itching, mouth ulcers, proteinuria, conjunctivitis\r\nLess common: Leukopenia, thrombocytopenia, agranulocytosis, aplastic anemia, hepatotoxicity, peripheral neuropathy, angioedema',
    commonInteractions:
      ' Toxicity of other DMARDs may be increased if used in combination therapy.',
    patientInstructions:
      'Avoid sunlight because photosensitivity may occur. Regular monitoring of urine and blood count is required. Discontinue if rash develops. It may take as long as 3 months for benefits to appear.',
    comments:
      'Onset of antirheumatic action is slow, over several months. Auranofin is seldom prescribed but is occasionally used for patients with early and mild disease. The combination of auranofin with MTX did not increase efficacy but did increase side effects.',
    clinicalPharmacology:
      'Oral absorption is 25%. Renal excretion is the major route of elimination. The plasma half-life is 26 days.',
    citation:
      'American College of Rheumatology Committee on Rheumatoid Arthritis Guidelines. Guidelines for the management of rheumatoid arthritis: 2002 update. Arthritis Rheum 2002;46:328â€“346. PMID:11840435',
  },
  {
    name: 'Azathioprine\r\n',
    description:
      "Azathioprine is an immunosuppressant used to prevent renal transplant rejection, treat rheumatoid arthritis, Crohn's disease, and ulcerative colitis.",
    brandNames: 'Azasan, Imuran',
    drugClass: 'Immunosuppressive, purine antagonist',
    __EMPTY: 'Tablets: 50 mg, scored',
    dosage: 'Adult, 1.0â€“2.5 mg/kg/day (given once or twice daily)',
    administration: 'Oral',
    indications:
      'SLE; dermatomyositis/polymyositis; vasculitis; used as a steroid-sparing agent in a wide range of autoimmune disorders when the disease requires prolonged high doses of corticosteroids; Rheumatoid Arthritis (very seldom)',
    mechanismOfAction:
      'Azathioprine is an inactive prodrug that is metabolized to 6-mercaptopurine then to 6-thioguanine nucleotides (6-TGN) which act as a purine analogue that interferes with purine synthesis and thus with DNA synthesis.',
    contraindications:
      'Hypersensitivity, concomitant xanthine oxidase inhibitor (allopurinol, febuxostat), lactation, or pregnancy',
    precautions:
      'Consider genetic testing for thiopurine methyltransferase deficiency (see comments). Use caution in hepatic or renal impairment. Reduce dose for renal impairment. Azathioprine is carcinogenic in animals and increases the risk of malignancy, primarily lymphoma, leukemia, and skin cancer in humans.',
    __EMPTY_1:
      'CBC and platelet count weekly for first month and every 2 weeks for months 2-3 or after a dose increase, then monthly on stable dose. Do LFTs every 1â€“3 months. Consider skin cancer screening.',
    pregnancyRisk: 'D',
    commonSideEffects:
      '\r\nCommon: Fever, chills, GI symptoms (vomiting, diarrhea, nausea), dose-related effects on bone marrow (thrombocytopenia, leukopenia)\r\nLess common: Herpes zoster, infections, hepatotoxicity, pancreatitis, pneumonitis, hypersensitivity, stomatitis, rash, increased risk of lymphoma/leukemia, skin cancer, hepatic venoocclusive disease',
    commonInteractions:
      '\r\nXanthine oxidase inhibitor (allopurinol, febuxostat): Accumulation of 6-mercaptopurine and thus toxicity. Avoid concurrent treatment if at all possible; if necessary, for allopurinol reduce azathioprine dose markedly (to 25% or less of usual dose) and monitor frequently; for febuxostat there are no data.\r\nSulfasalazine: Increased leukopenia.\r\nCotrimoxazole and ACE-inhibitors: Increased leukopenia.\r\nWarfarin: May increase anticoagulant effect.\r\nImmunosuppressants: Concurrent use with other immunosuppressants increases the risk of infection and long-term risk of malignancy.\r\nLive vaccines: Replication of the attenuated virus may occur because of immunosuppression.',
    patientInstructions:
      'Use contraception to avoid pregnancy. Do not exceed prescribed dose. Regular monitoring of blood count is essential. Report to physician if persistent sore throat, unusual bleeding, bruises, or fatigue develops. Reduce risk of skin cancer by avoiding the sun.',
    comments:
      'Often used for SLE and seldom for RA. Rather than starting with a high maintenance dose, it is advisable to start at a low dose, usually 50 mg/day and increase by 25 mg increments at 1- to 2-week intervals. Onset of action is delayed, so reduce dose at first sign of a large or persistent (<3,000/mm3) decrease in WBCs or platelets (<100,000/mm3). Consider genetic testing before starting treatment. A complete genetic deficiency of the enzyme thiopurine methyltransferase (TPMT) occurs in one in 300 persons and partial deficiency in 10%. Complete TPMT deficiency is associated with severe, life-threatening myelosuppression because of impaired metabolism; thus, AZA should be avoided if possible. Partial deficiency is associated with increased side effects and lower doses are indicated. Genetic testing for TPMT deficiency is available, and it is prudent to check before starting therapy. GI tolerability may improve with split (twice daily) dosing. Response in RA may not occur for several months. AZA is not more effective than other DMARDs, but adverse effects are more common. Combination studies in RA show that MTX + AZA is more effective than AZA alone, but the MTX + AZA combination is not more effective than MTX alone and may have added toxicity. AZA combination therapy should not be undertaken routinely; avoid with tofacitinib and leflunomide.',
    clinicalPharmacology:
      'AZA is well absorbed orally and largely biotransformed to 6-mercaptopurine and 6-thioinosinic acid; it is further metabolized by xanthine oxidase and renally excreted as metabolites. Half-life is 5 hours (drug and active metabolites), but immunosuppressive action is prolonged for weeks because of the intracellular accumulation of active thioguanine nucleotides.',
    price: '$$$',
    citation:
      'Willkens RF, Urowitz MB, Stablein DM, et al. Comparison of azathioprine, methotrexate, and the combination of both in the treatment of rheumatoid arthritis. Arthritis Rheum 1992;35:849â€“856. PMID:8849352',
  },
  {
    name: 'Baricitinib',
    description:
      'a Janus kinase inhibitor used to treat moderate to severe rheumatoid arthritis that has responded poorly to at least one TNF antagonist.',
    brandNames: 'Olumiant',
    administration: 'Oral',
    indications: 'atopic dermatitis, rheumatoid arthirtis, alopecia areata',
  },
  {
    name: 'Belimumab\r\n',
    description:
      'a B-lymphocyte stimulator (BLyS)-specific inhibitor used to treat systemic lupus erythematosus and active lupus nephritis as an add-on therapy.',
    brandNames: 'Benlysta',
    drugClass: 'B-lymphocyte stimulator (BlyS)-specific inhibitor',
    __EMPTY: '120 mg/vial, 400 mg/vial',
    dosage:
      '10 mg/kg as an IV infusion over 1 hour every 2 weeks for first 3 doses and every 4 weeks thereafter. Premedication to reduce infusion reactions is usual.',
    administration: 'Injection',
    indications: 'Active antibody positive SLE',
    mechanismOfAction:
      'A human IgG1 monoclonal antibody that blocks binding of soluble BlyS to its receptors on B-cells. Inhibits B-cell survival and differentiation.',
    contraindications: 'Hypersensitivity, active infection',
    precautions:
      'In clinical trials increased mortality and depression. Risk of serious infections. Avoid live virus vaccines. Combination with other biologics or cyclophosphamide not recommended.',
    __EMPTY_1: 'Monitor clinically for infection and depression',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Mild infusion reactions, nausea, diarrhea, nasopharyngitis\r\nLess common: Depression, infection\r\nRare: Anaphylaxis, progressive multifocal leucencephalopathy',
    commonInteractions: 'Concurrent use of immunosuppressants may increase risk of infection.',
    patientInstructions:
      'Avoid live virus vaccines, avoid pregnancy, call your doctor if an infection or fever develops that lasts more than a few days.',
    comments:
      'In clinical trials modestly effective, primarily for skin and musculoskeletal manifestations at 52 weeks but not at 76 weeks. Not evaluated in severe active lupus nephritis or central nervous system and not recommended. Unclear if African-Americans respond as well.',
    clinicalPharmacology:
      'Half-life is 19 days. Biologic agents are not metabolized and thus have few drug interactions.',
    price: '$$$$$',
    citation:
      'Furie R, Petri M, Zamani O, et al. A phase III, randomized, placebo-controlled study of belimumab, a monoclonal antibody that inhibits B lymphocyte stimulator, in patients with systemic lupus erythematosus. Arthritis Rheum 2011;63:3918-30. PMID: 22127708.',
  },
  {
    name: 'Bosentan\r\n',
    brandNames: 'Tracleer',
    drugClass: 'Endothelin receptor antagonist',
    __EMPTY: 'Tablets: 62.5 and 125 mg',
    dosage:
      'Initially 62.5 mg twice daily for a month; if tolerated, increase to 125 mg twice daily in adults weighing >40 kg.',
    indications:
      '(Only available through Tracleer Access Program, a restricted distribution program)\r\nPulmonary arterial hypertension; off label use for severe digital ulcers with Raynaudâ€™s',
    mechanismOfAction: 'Blocks endothelin A and B receptors and prevents vasoconstriction',
    contraindications: 'Hypersensitivity, pregnancy, use of glyburide or cyclosporine',
    precautions:
      'Because of teratogenicity and risk of liver impairment, prescribing is restricted through REMS program to registered prescribers. Avoid with hepatic impairment. Obtain baseline LFTs and monitor. Exclude pregnancy. Use 2 methods of reliable contraception.',
    __EMPTY_1:
      'Monitor LFTs at least monthly. Stop bosentan if transaminases have increased and there are any symptoms of clinical hepatic injury (fatigue, jaundice, nausea, increased bilirubin>2x upper limit of normal). Decrease dose to 62.5 mg twice daily or interrupt therapy and monitor carefully (at least every 2 weeks) if a modest (3 to 5 times) increase in transaminases and no clinical symptoms of liver injury. Stop drug if transaminases are markedly elevated (five times or greater). Perform a monthly pregnancy test. Monitor hemoglobin monthly for 3 months, then every 3 months.',
    pregnancyRisk: 'X. Teratogenic; contraindicated in pregnancy',
    commonSideEffects:
      '\r\nCommon: GI side effects, headache, anemia, edema, increased transaminases, decreased sperm count\r\nLess common: Fatigue, flushing, liver injury, rash',
    commonInteractions:
      '\r\nIncreased bosentan levels: Ritonavir, ketoconazole, cyclosporine and likely other CYP3A inhibitors; CYP2C9 inhibitors including fluconazole and amiodarone.\r\nIncreased hepatotoxicity: Glyburide\r\nDecreased levels of other drugs: Bosentan decreases levels of cyclosporine, and because it induces CYP2C9 and CYP3A, it may decrease levels of a range of other drugs including hormonal contraceptives.',
    patientInstructions:
      'Do not become pregnant. Report nausea, vomiting, and orange-yellow discoloration of eyes, skin, or urine.',
    comments:
      'Bosentan is only prescribed by physicians expert in the management of pulmonary hypertension, generally cardiologists or pulmonologists with a special interest in pulmonary hypertension. Access to the drug is limited and obtained through the Tracleer access program. In subset analysis of a clinical trial, stabilization of 6-minute walk distance occurred in patients with scleroderma with pulmonary hypertension treated with bosentan. Bosentan may decrease formation of new digital ulcers in scleroderma but did not appear to speed healing.',
    clinicalPharmacology:
      'Bioavailability, 50%, half-life, 5 hours, hepatic metabolism by CYP3A and CYP2C9',
    price: '$$$$$',
    citation:
      'Rubin LJ, Badesch DB, Barst RJ, et al. Bosentan therapy for pulmonary arterial hypertension. N Engl J Med 2002;346:896â€“903.PMID:11907289',
  },
  {
    name: 'Calcitonin\r\n',
    brandNames:
      '\r\nNasal spray: Miacalcin (salmon)\r\nInjection: Miacalcin (calcitonin salmon injection)',
    drugClass: 'Hormone',
    synonyms: 'Synonyms: Salmon calcitonin',
    __EMPTY:
      '\r\nSalmon calcitonin nasal spray 2 mL bottle, 200 IU/spray\r\nSalmon calcitonin injection 2 mL multidose vial, 200 IU/ml',
    dosage:
      'Salmon calcitonin nasal: For osteoporosis one spray (200 IU) daily; use alternate nostrils\r\nSalmon calcitonin injection for Pagetâ€™s disease 100 IU by subQ injection daily',
    indications:
      'Indications\r\nNasal calcitonin: Osteoporosis when alternative agents not suitable\r\nCalcitonin injection: Pagetâ€™s disease when alternative agents not suitable',
    mechanismOfAction:
      'Calcitonin inhibits osteoclastic bone resorption and promotes renal excretion of calcium.',
    contraindications: 'Hypersensitivity to salmon protein or salmon calcitonin',
    precautions:
      'Skin testing should be considered for patients with suspected sensitivity. A meta-analysis of clinical trials with calcitonin-salmon (nasal spray and investigational oral forms) suggested an increased risk of malignancies (4.1 percent) compared with placebo-treated patients (2.9 percent). The data were not sufficient for further analyses by specific type of malignancy.',
    __EMPTY_1:
      'Consider monitoring of bone density. Consider periodic nasal examination for nasal preparation.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Rhinitis and nasal irritation, flushing, headache, nausea, diarrhea, injection site reaction\r\nLess common: Hypocalcemia, malignancy, antibodies to salmon-calcitonin, anaphylaxis',
    commonInteractions: ' None of significance',
    patientInstructions:
      'Teach the patient the injection technique. For nasal spray, use alternate nostrils. Discontinue if nasal ulceration or bleeding occurs.',
    comments:
      'Antibody formation occurs and 5%â€“10% of patients may become resistant to treatment after long-term use. In contrast to bisphophonates, a decrease in both vertebral and hip fracture rate is not well established with calcitonin. Thus, bisphosphonates are the preferred drugs for osteoporosis. Similarly, bisphosphonates have largely replaced calcitonin in Pagetâ€™s disease. Adequate calcium (1,000â€“1,500 mg elemental calcium/day) and vitamin D (400 IU/day) intake is recommended when using calcitonin.',
    clinicalPharmacology:
      'Short half-life (1â€“2 hours), rapid metabolism by kidneys. Absorption of nasal spray is poor (10%â€“25%).',
    citation:
      "Ralston SH. Clinical practice. Paget's disease of bone. N Engl J Med 2013;368:644-50.PMID: 23406029",
  },
  {
    name: 'Calcium Salts\r\n',
    brandNames:
      '\r\nCalcium carbonate: Generic, Caltrate, Os-Cal, Tums E-X\r\nCalcium citrate: Generic, Calcitrate\r\nCalcium glubionate: Generic, Calcionate\r\nCalcium lactate: Generic\r\nCalcium phosphate dibasic: Generic\r\nCalcium phosphate tribasic: Generic, Posture',
    drugClass: 'Calcium supplement',
    __EMPTY:
      '\r\nCalcium carbonate: Tablets: 500-, 650-, 667- mg; 1.25- and 1.5- g; chewable tablets: 420-, 500-, 750-mg\r\nCalcium citrate: Tablets 950 mg; effervescent tablets: 2,376 mg\r\nCalcium glubionate: Syrup 1.8 g/5 mL\r\nCalcium lactate: Tablets 325 and 650 mg\r\nCalcium phosphate dibasic: 500-mg tablet\r\nCalcium phosphate tribasic: 300- and 600-mg tablets',
    dosage:
      'Prevention and treatment of osteoporosis in adults requires at least\r\n1â€“1.5 g of elemental calcium/day. Various salts contain the following amounts of elemental calcium:\r\nCalcium carbonate: 400 mg elemental calcium/g\r\nCalcium citrate: 220 mg elemental calcium/g\r\nCalcium glubionate: 64 mg elemental calcium/g\r\nCalcium lactate: 130 mg elemental calcium/g\r\nCalcium phosphate dibasic: 115 mg elemental calcium/g\r\nCalcium phosphate tribasic: 400 mg elemental calcium/g\r\nDietary sources rich in calcium include the following approximate amounts of elemental calcium: 8 oz yogurt 400 mg, 1.5 oz cheese 300 mg, cup of milk 300 mg, cup of calcium-fortified orange juice 300 mg.',
    indications: 'Prevention of osteoporosis, hypocalcemia',
    mechanismOfAction:
      'Calcium supplement helps prevent osteoporosis in patients with inadequate intake. Calcium in bone is in exchange with calcium in plasma, so bone stores are depleted if intake is inadequate.',
    contraindications: 'Renal calculi, hypercalcemia, digoxin toxicity, renal failure',
    __EMPTY_1: 'Consider measuring serum calcium before treatment and annually',
    pregnancyRisk: 'C; has been used in pregnancy to supplement calcium intake',
    commonSideEffects:
      '\r\nCommon: Constipation, flatulence\r\nUncommon: Nausea, hypercalcemia, renal stones',
    commonInteractions:
      '\r\nCalcium channel antagonists: Doses of calcium that increase serum calcium concentrations may antagonize the effects of calcium channel antagonists.\r\nDigoxin: Doses of calcium that increase serum calcium concentrations may increase the risk of cardiac arrhythmias.\r\nIron supplements: Oral absorption is decreased if taken together.\r\nTetracyclines: Oral absorption is decreased if taken together.',
    patientInstructions:
      'Best taken with a large glass of water before or during a meal. Do not take calcium within 1â€“2 hours of taking another medication (may impair absorption).',
    comments:
      'Most studies show that calcium and vitamin D do not increase bone density. In subjects with inadequate calcium intake, they may slow bone loss. Calcium with vitamin D supplementation has led to a small reduction in fractures in some studies (often populations likely to have low calcium intake) but not others. Dietary intake is preferred but calcium and vitamin D supplements are used if needed to ensure adequate intake in patients with osteoporosis. Controversial inconsistent evidence links calcium supplementation (usually without vitamin D) to increased cardiovascular risk.',
    clinicalPharmacology:
      'Poor absorption; 20% is eliminated renally and 80% appears in the stool. Vitamin D enhances absorption.',
    price: '$ (see Table 1)',
    citation:
      'Bauer DC. Clinical practice. Calcium supplements and fracture prevention. N Engl J Med. 2013 Oct 17;369(16):1537-43.PMID:24131178',
  },
  {
    name: 'Canakinumab\r\n',
    brandNames: 'Ilaris',
    drugClass: 'IL-1 antagonist',
    __EMPTY: '180 mg vial',
    dosage:
      'CAPS: children older than 4 years: 15-40 kg 2 mg/kg every 8 weeks, more than 40 kg: 150 mg every 8 weeks; adults >40 kg: 150 mg every 8 weeks by subcutaneous injection\r\nSystemic juvenile idiopathic arthritis in patients more than 7.5 kg: 4 mg/kg every 4 weeks (maximum 300 mg/dose) by subcutaneous injection',
    indications:
      'Cryopyrin-associated periodic syndrome (CAPS), systemic juvenile idiopathic arthritis (Stillâ€™s disease)',
    mechanismOfAction:
      'Binds to IL-1 beta preventing interaction with receptors thus blocking the effects of the cytokine',
    contraindications: 'Hypersensitivity, active or chronic infection,',
    precautions:
      'Discontinue if patient develops an infection. Avoid live vaccines. Macrophage activation syndrome may develop â€“ relation to drug unclear. Exclude TB and monitor for new infection. Concurrent use of other immunosuppressants may increase risk of infection; do not use with other IL-1 antagonists, TNF-antagonists, biologics, tofacitinib, abatacept.',
    __EMPTY_1: 'Monitor clinically for infection; CBC after 1-2 months, then periodically',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Injection site reactions, headache, vertigo, diarrhea, nasopharyngitis, abdominal pain, weight gain\r\nLess common: Infection including opportunistic infections, antibody formation, leucopenia, increased liver function tests\r\nRare: Hypersensitivity, neutropenia',
    commonInteractions: '\r\nOther immunosuppressants: increased risk of infection',
    patientInstructions:
      'Avoid live virus vaccines. Avoid pregnancy. Stop if an infection or a fever develops that lasts more than a few days.',
    comments:
      'In clinical studies was effective for the treatment of acute attacks of gout in patients refractory to, or with contraindications to, NSAIDs and colchicine.',
    clinicalPharmacology:
      'Half-life is 26 days. Biologic agents are not metabolized and have few drug interactions.',
    price: '$$$$$',
    citation:
      'Ruperto N, Brunner HI, Quartier P, et al. Two randomized trials of canakinumab in systemic juvenile idiopathic arthritis. N Engl J Med 2012;367:2396-406.PMID: 23252526.',
  },
  {
    name: 'Capsaicin\r\n',
    brandNames: 'Zostrix and many others',
    drugClass: 'Topical analgesic',
    __EMPTY:
      '\r\nCream: 0.025% (45- and 90-g tube); 0.075% (30- and 60-g tube)\r\nGel: 0.025% (15 and 30 g)\r\nLotion: 0.025% (59 mL), 0.075% (59 mL)\r\nRoll-on: 0.075% (60 mL)',
    dosage:
      'Apply to affected area three to four times daily. Less frequent application is less effective.',
    indications:
      'Postherpetic neuralgia, Rheumatoid Arthritis, osteoarthritis, diabetic neuropathy, chronic neuralgic pain',
    mechanismOfAction: 'Depletes peripheral sensory neurons of substance P, a mediator of pain.',
    contraindications: 'Hypersensitivity to capsaicin',
    precautions:
      'Avoid contact with eyes, mucous membranes, genitalia, or open wounds. Wash hands immediately after applying.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Transient sensation of burning when first applied that diminishes with use\r\nLess common: Erythema',
    commonInteractions: ' None',
    patientInstructions:
      'For external use only. Do not apply to broken skin. Wash hands after use or use gloves. Avoid contact with eyes. Regular use is required for effect. Effect is slow; clinical benefits may take weeks or months. There is a transient sensation of burning when first applied.',
    comments:
      'The transient burning that occurs in most patients initially has made it difficult to perform true double-blind studies to assess efficacy. In practice, few patients derive clinically useful benefit from capsaicin in the treatment of pain from arthritis. Most suitable are patients with a few affected joints. The requirement for regular and frequent application and the slow onset of action over several weeks are disadvantages.',
    clinicalPharmacology: 'Not known',
    price: '$',
  },
  {
    name: 'Carisoprodol\r\n',
    brandNames: 'Soma',
    drugClass: 'Skeletal muscle relaxant',
    __EMPTY: '\r\nTablet: 250-, 350 mg\r\nCompound tablet: Carisoprodol 200 mg and aspirin 325 mg',
    dosage:
      'Adults: 250 mg to 350 mg two to four times daily;\r\nRecommended maximum duration of use is 2-3 weeks',
    indications:
      'Short term treatment of painful muscle spasm; useful in some patients with fibromyalgia',
    mechanismOfAction: 'Unknown; has a sedative effect and may modify pain perception',
    contraindications:
      'Hypersensitivity to carisoprodol or meprobamate; acute intermittent porphyria',
    precautions:
      'Use caution in renal or hepatic dysfunction. Avoid in elderly. Abuse potential. Operating machinery or driving under the effects of the drug are hazardous. Seizures.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Drowsiness\r\nUncommon: Allergy, anaphylaxis, psychologic dependence and drug abuse, flushing, rash, nausea, fever, paradoxical stimulation, tremor, hematologic abnormalities, withdrawal symptoms after discontinuation of chronic therapy, seizures',
    commonInteractions: '\r\nCNS depressants: Increased CNS depression',
    patientInstructions: 'Avoid alcohol. It may cause drowsiness.',
    comments:
      'Potential for abuse has resulted in it being removed from the market in several countries. Nighttime sedative effect is sometimes useful in improving sleep and pain control in fibromyalgia.',
    clinicalPharmacology:
      'Rapid oral absorption, hepatic metabolism via CYP2C19 to meprobamate (anxiolytic) that has a half-life of 10 hours, renal excretion. CYP2C19 poor metabolizers and patients receiving CYP2C19 inhibitors such as omeprazole may have higher concentrations of carisoprodol and lower concentrations of meprobamate.',
    price: '$$',
  },
  {
    name: 'Celecoxib\r\n',
    brandNames: 'Celebrex',
    drugClass: 'COX-2 selective NSAID',
    __EMPTY: '\r\nCapsules: 100 and 200 mg\r\n',
    dosage: '100 to 200 mg once or twice daily',
    comments:
      'COX-2 selective NSAIDs are no more effective than traditional NSAIDs that inhibit both COX-1 and COX-2, but they are less likely to cause GI complications. A traditional NSAID with a proton pump inhibitor has similar GI toxicity as COX-2 selective drugs in observational studies. Either is a reasonable strategy for patients who have a high risk of GI complications and are unable to do without an NSAID. Therapy with low-dose aspirin and a COX-2â€“selective drug may abrogate the GI benefits of the COX-2 selectivity. Increased risk of cardiovascular events including myocardial infarction and stroke. Celecoxib is avoided if possible in patients allergic to sulfonamides. Fluconazole and other drugs that inhibit CYP2C9 increase concentrations of celecoxib.',
    clinicalPharmacology:
      'Well absorbed after oral administration; hepatic metabolism (CYP2C9) and renal excretion of metabolites; half-life 11 hours',
    price: '$$$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
    __EMPTY_2:
      'FitzGerald GA, Patrono C. The coxibs, selective inhibitors of cyclooxygenase-2. N Engl J Med 2001;345:433â€“442.PMID:11496855',
  },
  {
    name: 'Certolizumab pegol\r\n',
    description:
      "a tumor necrosis factor (TNF) blocker used to treat a variety of autoimmune and autoinflammatory conditions like Crohn's disease, rheumatoid arthritis, active psoriatic arthritis, ankylosing spondylitis, axial spondyloarthritis, and plaque psoriasis.",
    brandNames: 'Cimzia',
    drugClass: 'Biologic DMARD, TNF antagonist',
    __EMPTY:
      'For subcutaneous injection 200 mg vial lyophilized powder for reconstitution or 200 mg pre-filled syringe',
    dosage:
      '400 mg by subcutaneous injection at weeks 0, 2 , 4 and then either 200 mg every 2 weeks or 400 mg every 4 weeks. For Crohnâ€™s, the maintenance dose of 400 mg every 4 weeks is recommended in the FDA label.',
    administration: 'Injection',
    indications:
      'Rheumatoid Arthritis, psoriatic arthritis, ankylosing spondylitis, Crohnâ€™s disease',
    mechanismOfAction:
      'A recombinant humanized Fab fragment (lacks Fc region) of a monoclonal antibody to human TNF alpha conjugated to polyethylene glycol. It binds to TNF alpha neutralizing it.',
    contraindications:
      'Hypersensitivity, untreated tuberculosis and other opportunistic infections, sepsis, active infection, chronic localized or recurrent infections, demyelinating disease, optic neuritis, moderate-to-severe heart failure.',
    precautions:
      'Increased risk of serious infections including TB and fungal. Exclude latent or active TB with a skin test or TB blood test (interferon-gamma release assays or IGRA). Caution in debilitated or high risk of infection. Exclude active hepatitis B infection or carriage. Avoid live virus vaccines and BCG. Do not use with other biologics. May exacerbate pre-exisiting demyelinating disease and heart failure.',
    __EMPTY_1:
      'Monitor clinically for infection. Periodic CBC. After therapy started, additional TB testing may be indicated for individuals likely to have exposure to TB.',
    pregnancyRisk: 'B',
    commonSideEffects:
      '\r\nCommon: Injection site reactions, positive antinuclear antibody, positive antinuclear and double stranded DNA antibody.\r\nLess common: Allergy, infection (bacterial, but particularly opportunistic infections such as tuberculosis, listeriosis, and histoplasmosis).\r\nRare: Lymphoma (including fatal hepatosplenic T-cell lymphoma), hepatitis, demyelinating CNS disorders, optic neuritis, seizures, pancytopenia, drug-induced lupus, reactivation of hepatitis B, new onset psoriasis. Certolizumab pegol may cause falsely elevated activated partial thromboplastin time (aPTT) results in some assays.',
    commonInteractions:
      ' Concurrent use of high-dose steroids and other immunosuppressants may increase risk of infection.',
    patientInstructions:
      'Avoid live virus vaccines; avoid pregnancy; stop injections and call your doctor if an infection or fever develops that lasts more than a few days.',
    comments:
      'TNF antagonists are among the most effective treatments for RA. Patients start to respond quickly, usually within 4â€“6 weeks, but maximum response may take 4-6 months. In RA, increasing the dose of certolizumab from 200 mg every 2 weeks to 400 mg every 2 weeks did not result in additional benefit. Combined therapy of an anti-TNF with MTX is more effective than either drug alone. TNF antagonists are being explored in a range of diseases such as sarcoidosis and inflammatory eye disease. Risk of tuberculosis may be lower with etanercept than other TNF antagonists; comparative risk of adverse effects among individual TNF antagonists is not clear and side effects likely a class effect. Patients may form antibodies to anti-TNF drugs that decrease their effect. Concurrent treatment with MTX may reduce the frequency of this.',
    clinicalPharmacology:
      'Half-life is 14 days. Biologic agents are not metabolized and thus have few drug interactions.',
    price: '$$$$$',
    citation:
      'Singh JA, Furst DE, Bharat A. et al.2012 Update of the 2008 American College of Rheumatology recommendations for the use of disease-modifying antirheumatic drugs and biologic agents in the treatment of rheumatoid arthritis.Arthritis Care Res 2012;64(5):625-39. PMID:22473917',
  },
  {
    name: 'Cevimeline\r\n',
    brandNames: 'Evoxac',
    drugClass: 'Cholinergic agent',
    __EMPTY: '30-mg capsule',
    dosage: '30 mg three times daily',
    indications: 'Dry mouth in SjÃ¶grenâ€™s syndrome',
    mechanismOfAction: 'Stimulates muscarinic receptors to increase saliva production',
    contraindications: 'Hypersensitivity to pilocarpine, iritis, uncontrolled asthma',
    precautions:
      'Asthma, GI ulceration, cardiovascular disease, urinary tract obstruction, gallstones',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Sweating, headache\r\nLess common: Nausea, rash, flushing, tachycardia, dizziness\r\nRare: Arrhythmia, cytopenias, cholecystitis',
    commonInteractions:
      '\r\nBeta-blockers: Increased risk of cardiac conduction defects\r\nAnticholinergic drugs: Decreased effect\r\nCYP2D6 inhibitors (quinidine, fluoxetine, paroxetine, and others) and CYP3A inhibitors (erythromycin, azole antifungals, diltiazem, verapamil): Increased levels of cevimeline',
    patientInstructions:
      'Expect increased sweating. It make take several weeks for response to occur. It may decrease visual acuity, particularly for night vision.',
    clinicalPharmacology: 'Half-life 5 hours; hepatic metabolism by CYP2D6 and CYP3A4',
    price: '$$$',
  },
  {
    name: 'Chlorambucil\r\n',
    brandNames: 'Leukeran',
    drugClass: 'Alkylating agent',
    __EMPTY: '2-mg tablet',
    dosage:
      '0.05â€“0.1 mg/kg/day; usual dose range, 4â€“6 mg/day, with dose adjusted according to WBC count',
    indications:
      'Immunosuppressant, occasionally used as an alternative for patients who cannot tolerate cyclophosphamide',
    mechanismOfAction:
      'Alkylation and cross-linking of DNA strands interfering with DNA replication',
    contraindications: 'Hypersensitivity, bone marrow suppression, BCG and live virus vaccines',
    precautions:
      'Teratogenic; can cause severe immunosuppression and cytopenias; carcinogenic; caution and dose reduction in hepatic and renal impairment',
    __EMPTY_1:
      'CBC, platelet count, serum uric acid, and LFTs frequently after initiation, then every 4 weeks',
    pregnancyRisk: 'D',
    commonSideEffects:
      '\r\nCommon: Myelosuppression, rash, GI intolerance, oral ulceration\r\nLess common: Confusion, seizures, sterility, pulmonary fibrosis, liver necrosis, drug fever, secondary malignancy, Stevens-Johnson syndrome, azoospermia, irreversible bone marrow failure',
    commonInteractions:
      '\r\nImmunosuppressants: Concurrent use of chlorambucil increases risk of myelosuppression and infection.',
    patientInstructions:
      'Avoid live virus vaccines. Avoid pregnancy. Regular monitoring is required.',
    comments:
      'When possible, azathioprine and methotrexate are used in preference to alkylating agents because the risk of secondary neoplasms is lower. Decrease in neutrophils and lymphocytes progresses even after drug is discontinued.',
    clinicalPharmacology:
      'Well absorbed; food decreases absorption 20%. Half- life 2 hours; hepatic metabolism and renal excretion of metabolites',
    price: '$$$',
    citation:
      'Steinberg AD. Chlorambucil in the treatment of patients with immune-mediated rheumatic diseases. Arthritis Rheum 1993;36:325â€“328.PMID:8452576',
  },
  {
    name: 'Chlorzoxazone\r\n',
    brandNames: 'Parafon Forte',
    drugClass: 'Skeletal muscle relaxant, centrally acting',
    __EMPTY: '500-mg caplet; 250-mg tablet',
    dosage:
      'Usual dose, 250â€“500 mg three to four times daily; to as high as 750 mg three to four times daily',
    indications:
      'Relief of muscle spasm. Questionable efficacy and potentially serious adverse effects including fatal hepatotoxicity limit indications.',
    mechanismOfAction: 'Acts via spinal cord and subcortical regions to decrease muscle tone',
    contraindications:
      'Hypersensitivity to chlorzoxazone, impaired liver function, avoid in elderly',
    __EMPTY_1: 'LFTs periodically with long-term use',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Drowsiness, lightheadedness, allergy, rash, nausea, cramps\r\nLess common: Hematologic toxicity (aplastic anemia, leukopenia), unpredictable fatal hepatotoxicity',
    commonInteractions: ' CNS depressants have increased effect.',
    patientInstructions: 'It may cause drowsiness. Avoid alcohol.',
    clinicalPharmacology: 'Rapid absorption; hepatic metabolism and renal excretion of metabolites',
    price: '$$',
  },
  {
    name: 'Choline Magnesium Trisalicylate\r\n',
    brandNames: 'Trilisate, Tricosal',
    drugClass: 'Nonacetylated salicylate, NSAID',
    __EMPTY: '\r\nLiquid: 500 mg/5 mL\r\nTablets: 500-, 750-, 1000 mg',
    dosage:
      'Adult: 500 mg to 1.5 g, two or three times daily; usual maintenance dose, 1.0â€“4.5 g/day',
    indications: 'Rheumatoid Arthritis, osteoarthritis',
    mechanismOfAction: 'Weak inhibitor of prostaglandin synthesis',
    contraindications: 'Hypersensitivity to salicylates',
    precautions:
      'Administer with food. Use caution in asthma, bleeding disorders, and hepatic or renal disease.',
    __EMPTY_1:
      'Monitor hematocrit, creatinine, LFTs (1 month after starting and then periodically). Periodically check serum magnesium with high-dose therapy or in the presence of impaired renal function.',
    pregnancyRisk: 'C/D third trimester',
    commonSideEffects:
      '\r\nCommon: GI irritation (dyspepsia, reflux, epigastric pain)\r\nLess common: GI ulceration or hemorrhage; minor elevations of liver enzymes; hypersensitivity (asthma, urticaria, angioedema, particularly in patients with nasal polyps). Cross-sensitivity occurs between NSAIDs, but hypersensitivity is less common with the nonacetylated salicylates. Dose-related side effects include tinnitus and deafness.',
    commonInteractions:
      '\r\nAntacids: Decreased salicylate levels because of increased elimination in alkaline urine\r\nAnticoagulants: Activity of warfarin is increased',
    patientInstructions:
      'Take with food. Discontinue and seek medical advice if unusual bleeding occurs.',
    comments:
      'Magnesium may accumulate in patients with renal impairment. Serum salicylate concentrations of 150â€“300 mcg/mL are antiinflammatory. Nonacetylated salicylates have little effect on platelet function and cause less GI toxicity than classical NSAIDs, which are more potent inhibitors of prostaglandin synthesis.',
    clinicalPharmacology: 'See Aspirin',
    price: '$',
  },
  {
    name: 'Codeine (Also See Acetaminophen + Opioids)\r\n',
    drugClass: 'Narcotic analgesic',
    __EMPTY: '15-, 30-, 60 mg\r\nOral solution: 15 mg/5 mL',
    dosage: '15â€“30 mg q 4â€“6 h (maximum dose in 24 hours is 360 mg)',
    indications: 'Pain not controlled by nonopioid drugs',
    mechanismOfAction: 'Binds to opioid receptors in CNS',
    contraindications:
      'Hypersensitivity to codeine, substance abuse, alcoholism, avoid in breastfeeding women, for postoperative pain in children who have undergone tonsillectomy, respiratory depression',
    precautions:
      'Use caution in patients with hypersensitivity to other opioids, respiratory disease, or renal or hepatic impairment. Decrease dose if hepatic or renal impairment.',
    __EMPTY_1: 'Make sure drug is used to control pain.',
    pregnancyRisk: 'C; D in high doses (fetal abstinence syndrome)',
    commonSideEffects:
      '\r\nCommon: Drowsiness, constipation, dysphoria, nausea\r\nLess common: Rash, confusion, CNS stimulation, insomnia, seizures, respiratory depression',
    commonInteractions: ' Increased toxicity occurs with other CNS depressants. Avoid with MAOIs.',
    patientInstructions:
      'Drug is metabolized to morphine and is addictive. Use only to control pain. Do not use with alcohol or other CNS depressants.',
    comments:
      'Patients with low CYP2D6 activity (approximately 8% of the population) have a genetic inability to form morphine from codeine and derive no therapeutic effect from the drug. Patients with duplicate copies of CYP2D6 (fewer than 1% of Caucasians but more common in populations of African ancestry) form more morphine from codeine â€“ fatalities were described in children after tonsillectomy and toxicity in breastfed infants of women who were CYP2D6 hypermetabolizers. Codeine is commonly administered as an acetaminophen/codeine combination (See Acetaminophen + Opioid).',
    clinicalPharmacology:
      'Codeine is a prodrug that after oral absorption undergoes hepatic metabolism to morphine by CYP2D6; half-life of codeine and metabolites 3h, urinary elimination as metabolites',
    price: '$$',
    citation:
      'Cherny NI. Opioid analgesics. Comparative features and prescribing guidelines. Drugs 1996;51:713â€“737. PMID:8861543',
  },
  {
    name: 'Colchicine\r\n',
    brandNames: 'Colcrys',
    drugClass: 'Antigout agent',
    __EMPTY: '\r\nTablets 0.6 mg',
    dosage:
      'Dose\r\nAcute gout: Oral: 1.2 mg at first sign of flare and the 0.6 mg 1 hour later. Maximum dose 1.8 mg â€“ do not repeat for at least 3 days\r\nProphylaxis of gout: 0.6 mg once or twice daily\r\n\r\nMarked dose reduction required for patients who have taken interacting drugs such as CYP3A and P-glycoprotein inhibitors in the past 14 days (see label for dose guidance). Strong CYP3A inhibitors and P-glycoprotein inhibitors (see Drug Interactions for examples) require a decrease in colchicine dose of 75% (e.g., 0.6 mg daily dose for prophylaxis becomes o.3 mg every second day). For moderate CYP3A inhibitors decrease colchicine dose by 50% (e.g., if original dose is 0.6 mg daily use 0.3 mg daily). Avoid colchicine for treatment of acute gout flares in patients already receiving prophylactic colchicine with a CYP3A or P-glycoprotein inhibitor. Avoid colchicine in patients with mild-moderate hepatic or renal impairment who are receiving a CYP3A or P-glycoprotein inhibitor.',
    indications:
      'Treatment of acute gout and prophylaxis of acute gout; also used in the treatment of familial Mediterranean fever and pericarditis',
    mechanismOfAction: 'Decreases leukocyte migration and phagocytosis',
    contraindications:
      'Hypersensitivity to colchicine; significant renal or hepatic impairment; use of CYP3A or P-glycoprotein inhibiting drugs in the presence of hepatic or renal impairment',
    precautions:
      'Use caution in mild-moderate renal or hepatic impairment and the elderly â€“ dose reduction is required. Frequent drug interactions.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Nausea, vomiting, GI cramps, diarrhea\r\nLess common: Neuropathy, myopathy, rash, bone marrow suppression, leucopenia, hepatic damage, alopecia',
    commonInteractions:
      '\r\nCYP3A inhibitors (strong inhibitors include clarithromycin,telithromycin, ketoconazole, itraconazole, nefazodone and many HIV drugs; moderate inhibitors include aprepitant, diltiazem, verapamil, erythromycin, fluconazole, posaconazole, voriconazole, grapefruit juice) and P-glycoprotein inhibitors (e.g., cyclosporine, ranolazine, quinidine, tacrolimus): Increased colchicine concentrations and increased risk of toxicity and myopathy\r\nCimetidine, tolbutamide: Increased colchicine concentrations\r\nStatins, fibrates and digoxin: Increased risk of myopathy and rhabdomyolysis',
    patientInstructions: 'Discontinue if nausea or vomiting occurs. Avoid grapefruit juice.',
    comments:
      'The dose (maximum 1.8 mg) used to treat acute gout is as effective as previous older high-dose regimens. Generic colchicine was cheap but is not available in the US until the patent disputes related to Colcrys are resolved. In treatment of acute gout colchicine is more effective if used early in the attack. Intravenous colchicine is no longer available because of toxicity and fatalities. Depot corticosteroids (intramuscular or intraarticular), ACTH, or anakinra are alternatives for acute gout in patients in whom colchicine and NSAIDs are contraindicated. Colchicine is effective in the treatment of acute pericarditis and in reducing recurrences.',
    clinicalPharmacology:
      'Time to onset of action in gout is slow (6â€“12 hours); peak effect is 24â€“48 hours after first oral dose. Hepatic biotransformation (CYP3A substrate) and renal and hepatic elimination by the P-glycoprotein efflux transporter; high degree of tissue uptake, half-life 26-31 hours.',
    price: '$$',
    citation:
      'Khanna D,Khanna PP, Fitzgerald JD et al. 2012 American College of Rheumatology guidelines for management of gout. Part 2 Arthritis Care Res 2012;64:1447-61. PMID:23024029',
  },
  {
    name: 'Collagenase clostridium histolyticum\r\n',
    brandNames: 'Xiaflex',
    drugClass: 'Enzyme',
    synonyms: 'Synonyms: Collagenase; CCH',
    __EMPTY: 'Lyophilized powder containing 0.9 mg of collagenase in a single use vial',
    dosage:
      'For Dupuytrenâ€™s contracture: 0.58 mg injected into a Dupuytrenâ€™s contracture cord affecting an MCP or PIP joint. Perform dilution, injection and follow up (including a finger extension procedure if required, and splinting) according to the manufacturerâ€™s instructions. Only one cord injected at a time. Re-injection may be required.',
    indications: 'Dupuytrenâ€™s contracture with a palpable cord, Peyronieâ€™s disease',
    mechanismOfAction: 'Collagenase lyzes collagen disrupting the Dupuytrenâ€™s contracture cord',
    contraindications: 'Hypersensitivity to collagenase',
    precautions:
      'Injection into tendons can cause tendon rupture. Increased risk of bleeding with anticoagulants.',
    pregnancyRisk: 'B',
    commonSideEffects:
      '\r\nCommon: Injection site swelling and bruising (70%), pain (35%), itching, lymphadenopathy\r\nLess common: Skin splitting open, injection site bleeding\r\nRare: Anaphylaxis, tendon rupture, nerve damage',
    commonInteractions: '\r\nAnticoagulants: Avoid concurrent use (increased risk of bleeding)',
    patientInstructions:
      'Swelling and bruising common after injection. Some people are allergic to the drug.',
    comments:
      'In clinical trials 64% of cords injected had reduction in contracture to 0-5 degrees after 30 days compared to 7% in placebo. In cords that responded well, approximately 35% recurred within 3 years (defined as 20 degree or worse contracture and palpable cord). In patients with cords that were partial responders initially, the recurrence rate was higher. Cords affecting PIP joints have a lower initial response and a higher recurrence rate than those affecting MCPs.',
    clinicalPharmacology:
      'No quantifiable serum levels after injection in patients with Dupuytrenâ€™s contracture.',
    price: '$$$$$',
    citation:
      "Hurst LC, Badalamente MA, Hentz VR, Hotchkiss RN, Kaplan FT, Meals RA, Smith TM, Rodzvilla J; CORD I Study Group. Injectable collagenase clostridium histolyticum for Dupuytren's contracture. N Engl J Med 2009;361:968-79. PMID: 19726771.",
  },
  {
    name: 'Corticosteroids\r\n',
    brandNames:
      '\r\nOral\r\nPrednisone: Deltasone, Rayos (delayed release)\r\nPrednisolone: Orapred, Millipred\r\nMethylprednisolone: Medrol\r\nDexamethasone: Decadron\r\nParenteral\r\nMethylprednisolone sodium succinate: Solu-Medrol\r\nHydrocortisone: Solu-Cortef\r\nDexamethasone: Decadron\r\nIntraarticular\r\nMethylprednisolone acetate suspension: Depo-Medrol\r\nTriamcinolone acetonide suspension: Kenalog\r\nTriamcinolone diacetate suspension: Aristocort Forte\r\nTriamcinolone hexacetonide suspension: Aristospan Intra-articular',
    drugClass: 'Glucocorticoid',
    __EMPTY:
      '\r\nOral\r\nPrednisone: 1-, 2.5-, 5-, 10-, 20-, 50-mg tablets\r\nPrednisolone: 2-, 4-, 8-, 16-, 24-mg tablets\r\nDexamethasone: 0.25-, 0.5-, 0.75-, 1-, 1.5-, 2-, 4-, 6-mg\r\nParenteral\r\nMethylprednisolone sodium succinate injection: 40, 125, 500, 1,000, 2,000 mg\r\nHydrocortisone injection: 50, 100, 250, 500 mg\r\nDexamethasone: 0.25, 0.5, 0.75, 1, 1.5, 2, 4, 6 mg\r\nIntraarticular\r\nMethylprednisolone acetate suspension: 20, 40, 80 mg/mL\r\nTriamcinolone acetonide suspension: 10 and 40 mg/mL\r\nTriamcinolone diacetate suspension: 40 mg/mL\r\nTriamcinolone hexacetonide suspension: 20 mg/mL',
    dosage:
      'Varies according to the indication; adult dose ranges from 2 to 80 mg/day. For serious inflammatory disease, a common approach is to give 1 mg/kg/day of prednisone or equivalent initially and titrate down according to response. Approximate equivalent doses for glucocorticoid efficacy: 0.75 mg dexamethasone = 4 mg methylprednisolone = 5 mg prednisone or prednisolone = 20 mg hydrocortisone. Intraarticular doses of depot steroid depend on the size of the joint.',
    indications:
      'Anti-inflammatory or immunosuppressant therapy in a wide range of diseases including inflammatory arthritis, inflammatory muscle disease, vasculitis, SLE, and Rheumatoid Arthritis.',
    mechanismOfAction:
      'A wide range of effects on numbers of inflammatory cells, their migration, and production of inflammatory mediators',
    contraindications: 'Known hypersensitivity to a glucocorticoid preparation',
    precautions:
      'Use caution in diabetes, atherosclerosis, immunosuppression, hypertension, osteoporosis, infection, peptic ulcer, and cirrhosis. Because of dose-related adverse effects, the minimum effective dose should be used. After prolonged glucocorticoid therapy, acute adrenal insufficiency may occur if the glucocorticoid is discontinued abruptly. After discontinuation of glucocorticoid therapy, the hypothalamus-pituitary-adrenal axis may remain suppressed for as long as a year, so supplementary doses of glucocorticoid are required during acute stress such as surgery. Increased risk of infections including tuberculosis.',
    __EMPTY_1:
      'Monitor for adverse effects with long-term therapy; consider monitoring potassium and glucose concentrations and bone density and consider osteoporosis prophylaxis or treatment (with long-term use).',
    pregnancyRisk: 'C',
    commonSideEffects:
      ': Most are dose related. Almost all patients on high doses for more than a few weeks experience adverse effects, including cushingoid appearance, weight gain, skin fragility, bruising, impaired wound healing, edema, hypertension, diabetes, hypokalemia, atherosclerosis, cataracts, infection, insomnia, mood swings, psychosis, GI ulceration with NSAIDs, myopathy, osteoporosis, fractures, and pancreatitis. Suppression of the hypothalamus-pituitary-adrenal axis and acute adrenal insufficiency may occur if therapy is discontinued suddenly or with severe physiologic stress (major surgery) after therapy has been discontinued.',
    commonInteractions:
      '\r\nHepatic enzyme inducers (rifampin, phenytoin, phenobarbitone): Decreased effect of corticosteroids\r\nDiuretics: Increased potassium depletion',
    patientInstructions:
      'Corticosteroids should not be discontinued suddenly. Before a procedure or surgery, notify your surgeon or doctor that you are receiving corticosteroids. Careful attention to diet is necessary to avoid significant weight gain.',
    comments:
      'Marked leukocytosis and neutrophilia occur after even a single dose of glucocorticoid, and if the cause is not recognized, an unnecessary infection workup results. Adverse effects are related to dose and duration of therapy. Once disease control has been established, high doses of steroids can often be tapered relatively rapidly with 5- to 10-mg decrements at doses between 40 and 60 mg. Lower doses require a more gradual taper. Between 20 and 40 mg, taper in 5-mg decrements; between 10 and 20 mg, taper in 2.5-mg decrements, and <10 mg, taper in 1-mg decrements. A common error is tapering high doses too slowly and low doses too fast, resulting in adverse effects and disease flare, respectively.',
    clinicalPharmacology:
      'Prednisone is inactive until metabolized to prednisolone. Hepatic conversion is rapid and complete. Plasma half-life of prednisone/prednisolone is 3â€“4 hours, but the biologic effect lasts 18â€“36 hours. Rayos is a delayed release brand of prednisone whose pharmacokinetic profile is delayed about 4 hours compared to immediate release prednisone. It is more expensive than immediate release prednisone. Dexamethasone has almost no mineralocorticoid effect. It is more potent than prednisone/prednisolone but more difficult to titrate clinically. Depot injection preparations, either intraarticularly or intramuscularly, provide low plasma concentrations of steroid for 2â€“4 weeks.',
    citation:
      'Weinstein RS. Clinical practice. Glucocorticoid-induced bone disease. N Engl J Med 2011;365:62-70. PMID:21732837',
  },
  {
    name: 'Cyclobenzaprine\r\n',
    brandNames: 'Flexeril',
    drugClass: 'Muscle relaxant',
    __EMPTY: '5-mg, 7.5-mg, 10-mg tablet',
    dosage: '15â€“30 mg/day in three divided doses',
    indications: 'Muscle spasm, fibromyalgia for short periods of time (2-3 weeks)',
    mechanismOfAction:
      'Centrally acting skeletal muscle relaxant; related to tricyclic antidepressants',
    contraindications:
      'Hypersensitivity to cyclobenzaprine. Do not use within 14â€“21 days of an MAOI. Acute recovery phase of myocardial infarction.',
    precautions:
      'As for tricyclics â€“ heart failure, arrhythmias, urinary obstruction, avoid in elderly',
    pregnancyRisk: 'B',
    commonSideEffects:
      '\r\nCommon: Anticholinergic effects (dry mouth, difficulty urinating), drowsiness\r\nLess common: Dizziness, blurred vision, weakness, allergy, GI symptoms, headache, confusion, arrhythmias',
    commonInteractions:
      '\r\nTricyclic antidepressants: Additive toxicity\r\nCNS depressants (alcohol, benzodiazepines): Increased effect\r\nMAOIs: Hypertensive crisis',
    patientInstructions: 'Causes drowsiness; may impair ability to drive',
    comments: 'Sedative effect is useful at night.',
    clinicalPharmacology:
      'Rapid oral absorption; hepatic metabolism and renal elimination of metabolites, half-life 8-37 hours',
    price: '$$',
  },
  {
    name: 'Cyclophosphamide\r\n',
    brandNames: 'Cytoxan, Neosar',
    drugClass: 'Cancer chemotherapeutic; alkylating agent',
    synonyms: 'Synonyms: CTX, CYT, CYC (abbreviations not recommended)',
    __EMPTY: '\r\nTablets: 25- and 50 mg\r\nPowder for injection: 500 mg; 1- and 2 g',
    dosage:
      'Varies according to indication and patient response â€“ examples below\r\nOral dose: 1â€“3 mg/kg/day\r\nIntravenous infusion: Dose of 500â€“750 mg/square m repeated monthly for 3-6 months. An alternative regimen for SLE nephritis, termed the â€œEuro-Lupusâ€ regimen, is 500 mg IV every 2 weeks for a total of 6 doses followed by maintenance therapy with azathioprine or mycophenolate. Whatever the regimen, adjust dose according to clinical response and WBC nadir and recovery. In many conditions, after an induction period of infusions for 3-6 months an alternative therapy is instituted to maintain remission.',
    indications:
      'Vasculitis, rheumatoid vasculitis, SLE with organ involvement, polymyositis/dermatomyositis refractory to treatment',
    mechanismOfAction: 'Interferes with DNA synthesis by alkylating and cross-linking DNA strands',
    contraindications: 'Hypersensitivity to cyclophosphamide; pregnancy',
    precautions:
      'Bone marrow suppression, active infection, decrease dose in renal/hepatic impairment, avoid BCG and live virus vaccines, consider dose reduction if renal or hepatic function impaired, reliable contraception',
    __EMPTY_1:
      'Frequent monitoring of CBC and platelets is required. Nadir of WBC occurs 10â€“14 days after a single dose. A WBC nadir of 3,000/mm3 is sometimes used as a target for intravenous pulses. With long-term use, monitor urinalysis for blood. During and after long- term oral use, monitor for bladder cancer with urinalysis, cytology, and (if needed) cystoscopy.',
    pregnancyRisk: 'D',
    commonSideEffects:
      ': Dose and duration dependent; myelosuppression (WBC more than platelets), infections (bacterial and viral infections and opportunistic organisms). Hemorrhagic cystitis is more common with daily oral regimens. Gonadal suppression and permanent infertility occur. Risk of ovarian failure is greater with oral regimens and increases in frequency with increased age of female patients. GI symptoms increase with dose. Pulmonary fibrosis, rashes, hypersensitivity, and fluid retention occur. Long-term risk of secondary malignancy (bladder, lymphoma, leukemia, and skin cancers).',
    commonInteractions:
      '\r\nThiazide diuretics: May cause prolonged leukopenia\r\nImmunosuppressants: Concurrent therapy increases risk of myelosuppression\r\nand infection',
    patientInstructions:
      'Take as a single dose in the morning and drink lots of liquids to keep urine diluted. Empty bladder frequently. Report blood in urine. Avoid live virus vaccines. Regular follow-up is essential. Report any significant fever. Use reliable contraception.',
    comments:
      'Life-threatening complications may occur with cyclophosphamide treatment. Titrate dose to avoid leucopenia. Oral regimens are preferred by some for vasculitis, and the less toxic monthly intravenous regimen is generally preferred for SLE. Duration of cyclophosphamide therapy is empirical, but after disease remission, alternative drugs are substituted to maintain remission. Premedicate with antiemetic regimen and continue antiemetic prophylaxis for 24â€“48 hours. Mesna may be used to protect against hemorrhagic cystitis. Consider pneumocystis prophylaxis.',
    clinicalPharmacology:
      'Good rapid oral absorption; hepatic metabolism to several active metabolites including 4-hydroxycyclophosphamide. Plasma half-life is 2â€“10 hours. Metabolites are eliminated in the urine. Acrolein is the urinary metabolite thought to cause hemorrhagic cystitis.',
    citation:
      "Langford CA. Cyclophosphamide as induction therapy for Wegener's granulomatosis and microscopic polyangiitis. Clin Exp Immunol. 2011 May;164 Suppl 1:31-4.PMID:21447129",
  },
  {
    name: 'Cyclosporine\r\n',
    brandNames: 'Sandimmune, Neoral (microemulsion)',
    drugClass: 'Immunosuppressant, DMARD',
    synonyms: 'Synonyms: Cyclosporin A, CyA, CSA (abbreviations not recommended)',
    __EMPTY:
      '\r\nSoft gelatin capsules: 25, 50, 100 mg\r\nOral liquid: 100 mg/mL\r\nInjection: 50 mg/mL',
    dosage:
      'Starting dose in autoimmune disease is 2.5 mg/kg/day orally in divided doses. Calculate dose according to approximate ideal body weight in obese patients. After 8â€“12 weeks, the dose may be increased monthly by 0.5 mg/kg/day to maximum of 4 mg/kg/day. The risk of renal disease increases with doses >4 mg/kg/day and duration of therapy. The dosing of the two preparations available (Neoral and Sandimmune) is similar but they are not equivalent. If switching from Sandimmune to Neoral, lower doses of Neoral may be required because it has better bioavailability.',
    indications:
      'Rheumatoid Arthritis refractory to standard DMARD treatment; also approved for use in psoriasis; anecdotal reports of efficacy in psoriatic arthritis, SLE, myositis, BehÃ§etâ€™s syndrome, and pyoderma gangrenosum',
    mechanismOfAction: 'Inhibits production of IL-2; immunomodulator',
    contraindications: 'Hypersensitivity to cyclosporine; immunodeficiency; renal impairment',
    precautions:
      'Avoid if past or present malignancy, uncontrolled hypertension, or renal or hepatic dysfunction. Avoid live virus vaccines.',
    __EMPTY_1:
      'Monitor blood pressure and creatinine every 2 weeks for first 2â€“3 months and then monthly if stable. If creatinine rises by >25% above baseline values, reduce dose of cyclosporine by 25-50% and check again. Continue dose reduction until the creatinine concentration is back within 25% of baseline. If creatinine dose not return to within 25% of baseline within 2 dose modifications, discontinue cyclosporine. Elevations of creatinine level (>25% of baseline), even if within the normal range, require action. Monitor potassium and uric acid levels, and hepatic enzymes every 1â€“3 months.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Hypertension, increased creatinine, hirsutism, nausea, cramps, tremor, gingival hypertrophy, hyperkalemia, hypomagnesemia, renal impairment, hyperuricemia\r\nLess common: Seizures, headache, muscle cramps, allergy, myositis, pancreatitis, infection, lymphoma',
    commonInteractions:
      '\r\nIncreased concentrations of cyclosporine: CYP3A inhibitors such as grapefruit juice, clarithromycin, erythromycin, ketoconazole, fluconazole, itraconazole, diltiazem, verapamil, nicardipine, protease inhibitors, and others',
    citation:
      'American College of Rheumatology Subcommittee on Rheumatoid Arthritis Guidelines. Guidelines for the management of rheumatoid arthritis: 2002 Update. Arthritis Rheum. 2002;46:328-46. PMID: 11840435.',
  },
  {
    name: 'Danazol\r\n',
    brandNames: 'Danocrine',
    drugClass: 'Attenuated androgen, gonadotropin inhibitor',
    __EMPTY: '50-, 100-, 200-mg capsules',
    dosage:
      'Hereditary angioedema, 400 mg/day in two or three divided doses; decrease if favorable response',
    indications:
      'Hereditary angioedema prophylaxis, fibrocystic breast disease, endometriosis, idiopathic thrombocytopenic purpura with refractory thrombocytopenia',
    mechanismOfAction:
      'Prevents attacks of angioedema by increasing concentrations of C1 esterase inhibitor and thus C4; suppresses ovarian production of pituitary gonadotropins and ovarian hormone production; has weak androgenic effects',
    contraindications:
      'Hypersensitivity, pregnancy, significant renal or hepatic impairment, undiagnosed vaginal bleeding, androgen-dependent tumors',
    precautions:
      'Thromboembolic disease, hepatic or renal dysfunction, seizure disorders, migraines, cardiac disease, exclude pregnancy before starting and ensure adequate contraception',
    pregnancyRisk: 'X',
    commonSideEffects:
      '\r\nCommon: Androgenic effects (hirsutism, irregular menstrual cycles, intercycle menstrual bleeding, weight gain), fluid retention\r\nLess common: Cholestatic jaundice or liver dysfunction, pancreatitis, leukopenia, thrombocytopenia, rashes, benign intracranial hypertension',
    commonInteractions:
      ' Increased effects of warfarin, carbamazepine, hypoglycemics, cyclosporine, tacrolimus. Increased rhabdomyolysis with statins.',
    patientInstructions:
      'Contraception with two reliable nonhormonal methods is recommended. Avoid sunlight because of photosensitivity.',
    clinicalPharmacology: 'Hepatic metabolism and renal excretion; half-life 4â€“6 hours',
    price: '$$$$',
  },
  {
    name: 'Dapsone\r\n',
    brandNames: 'Generic',
    drugClass: 'Sulfone bacteriostatic',
    __EMPTY: '25- and 100-mg tablets',
    dosage:
      '25 mg/day initially, increased by 25- to 50-mg increments to as high as 200 mg/day\r\nFor prophylaxis of Pneumocystis in patients unable to tolerate trimethoprim/sulfamethoxazole, dapsone 100 mg once daily or dapsone 50 mg daily with pyrimethamine and leucovorin has been used.',
    indications:
      'Sometimes tried for skin lesions of systemic or discoid lupus erythematosus, urticarial vasculitis, pemphigus, aphthous ulcers and pyoderma gangrenosum. Nonrheumatologic uses include leprosy, malaria, prophylaxis of Pneumocystis carinii, and dermatitis herpetiformis.',
    mechanismOfAction:
      'Immunomodulatory mechanism unknown; has sulfonamide-like action competing for p-aminobenzoic acid and preventing bacterial synthesis of folic acid',
    contraindications: 'Hypersensitivity to dapsone, G6PD deficiency',
    precautions:
      'Use caution in patients allergic to sulfonamides, check for G6PD deficiency, and severe anemia.',
    __EMPTY_1:
      'Determine G6PD status before treatment of patients from ethnic groups at greater risk. Monitor CBC in all patients initially weekly, then monthly, and then every 2â€“3 months. Monitor LFTs at intervals. Check for jaundice and hemolysis.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Rash, dose-related hemolysis, and methemoglobinemia\r\nLess common: GI intolerance, leukopenia, agranulocytosis, exfoliative dermatitis, hepatitis, cholestatic jaundice',
    commonInteractions:
      '\r\nRifampin: Decreased effect of dapsone\r\nFolic acid antagonists (trimethoprim, sulfonamides): Increased toxicity',
    patientInstructions: 'Regular blood monitoring is required. May cause photosensitivity.',
    comments:
      'The incidence of hemolytic anemia may be reduced by using concomitant antioxidant therapy (vitamin E or C). HLA-B*1301 may be a risk factor for dapsone hypersensitivity syndrome; the allele is present in approximately 1-12% of Asian populations but is extremely rare in Europeans and Africans.',
    clinicalPharmacology:
      'Well absorbed; acetylated in the liver. Acetylator phenotype (slow or fast) does not affect clinical use. Cytochrome P-450â€“mediated hydroxylation; renal elimination of metabolites; half-life 30 hours',
    price: '$$',
    citation:
      'National Institutes of Health and CDC Recommendations for prevention and treatment of Pneumocystis pneumonia (PCP) http://aidsinfo.nih.gov/guidelines/ accessed 8/19/2014',
  },
  {
    name: 'Denosumab\r\n',
    brandNames: 'Prolia',
    drugClass: 'Monoclonal antibody to RANK ligand',
    __EMPTY: '60 mg vial or prefilled syringe for subcutaneous injection',
    dosage: 'Dose/administration: 60 mg by subcutaneous injection every 6 months',
    indications:
      'Treatment of postmenopausal osteoporosis; to prevent bone loss in men receiving androgen deprivation therapy for prostate cancer, and women at high risk for fracture receiving aromatase inhibitors for breast cancer',
    mechanismOfAction:
      'Monoclonal antibody that binds to RANK ligand blocking its interaction with RANK, a receptor on the osteoclast. This prevents osteoclast formation and therefore decreased bone resorption.',
    contraindications: 'Hypersensitivity, pregnancy, hypocalcemia',
    precautions:
      'A dental exam and preventive dental work should be performed before starting therapy; administer with calcium and vitamin D. Xgeva is a high dose preparation of denosumab used to treat bone tumors â€“ do not confuse or use with Prolia. Correct hypocalcemia before therapy and monitor calcium if at risk for hypocalcemia.',
    pregnancyRisk:
      'X, based on animal studies may cause fetal harm; Amgen has a pregnancy surveillance program for women who fall pregnant on denosumab.',
    commonSideEffects:
      '\r\nCommon: Back pain, musculoskeletal pain, anemia, rash, infections\r\nUncommon: Hypocalcemia, atypical subtrochanteric femur fractures, osteonecrosis of the jaw, pancreatitis',
    commonInteractions:
      'Other immunosuppressants may increase risk of infection; monoclonal antibodies have few drug-drug interactions',
    patientInstructions: ' May increase risk of infections; keep good dental hygiene',
    comments:
      'Supplemental calcium and vitamin D are usually co-administered. The optimal duration of treatment is uncertain.',
    clinicalPharmacology: 'Elimination half-life is 25 days',
    __EMPTY_2: 'Cost: $$$$$',
  },
  {
    name: 'Diclofenac\r\n',
    brandNames:
      'Cataflam (immediate release), Voltaren (enteric coated) Voltaren XR (extended release), Arthrotec (diclofenac + misoprostol)',
    drugClass: 'NSAID',
    __EMPTY:
      '\r\nTablets: 25-, 50-, 75 mg; diclofenac/misoprostol: 50 mg/200 mcg or 75 mg/200 mcg\r\nSustained-release tablet (XR): 100 mg',
    dosage:
      '100â€“200 mg/day in two to four divided doses (100 mg once daily of sustained release)',
    comments:
      'Local application of topical diclofenac (1% gel) may offer benefit to some patients with lower GI and CV risk.',
    clinicalPharmacology:
      'Well absorbed after oral administration; hepatic metabolism and renal excretion of metabolites; half-life 2â€“3 hours.',
    price: '$$$',
    citation:
      'Altman RD, Barthel HR. Topical therapies for osteoarthritis. Drugs 2011;71(10):1259-79. PMID: 21770475.',
  },
  {
    name: 'Diflunisal\r\n',
    brandNames: 'Dolobid',
    drugClass: 'NSAID',
    __EMPTY: '250- and 500-mg tablets',
    dosage: '500â€“1,500 mg/day in two to three divided doses',
    clinicalPharmacology:
      'Well absorbed after oral administration; hepatic metabolism and renal excretion of metabolites; half-life 8â€“12 hours',
    price: '$$$',
  },
  {
    name: 'Duloxetine\r\n',
    brandNames: 'Cymbalta',
    drugClass: 'Antidepressant, serotononin/norepinephrine reuptake inhibitor (SNRI)',
    __EMPTY: '20-, 30-, 60 mg capsules',
    dosage:
      'Fibromyalgia and chronic musculoskeletal pain: 30 mg once daily for a week then increase to 60 mg once daily if tolerated. Doses of 120 mg/day in clinical trials did not offer additional benefit (but increased side effects).\r\nDepression: Initially 40-60 mg daily either as a single dose or in divided doses; usual maintenance 60 mg daily',
    indications:
      'Fibromyalgia, chronic musculoskeletal pain, major depression, anxiety disorder, diabetic neuropathy',
    mechanismOfAction: 'Increases synaptic concentrations of serotonin and norepinephrine',
    contraindications:
      'Hypersensitivity. Avoid for at least 14 days after patient has received an MAOI. Avoid in patients with uncontrolled narrow-angle glaucoma.',
    precautions:
      'May increase suicidality, particularly in adolescents. Risk of serotonin syndrome. Seizures reported. Avoid with hepatic disease or severe renal impairment. Caution with hypertension, seizures, gastroparesis. If discontinuing, reduce dose gradually over weeks or months to prevent withdrawal symptoms. Can exacerbate prostatism. Discontinue if any signs suggestive of Stevens Johnson syndrome.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Nausea, constipation, headache, fatigue, dry mouth, dizziness, palpitations, insomnia, difficulty with urination, dry mouth, weight loss\r\nLess common: Hypertension, mild increase in LFTs, orthostatic hypotension, withdrawal syndrome with abrupt discontinuation (nausea, vomiting, diarrhea, anxiety, dizziness, sleep disturbances), erectile dysfunction, worsen glucose control in diabetes\r\nRarely: Severe skin reactions, fractures, inappropriate ADH and hyponatremia, increased bleeding, fulminant hepatitis, serotonin syndrome, mania',
    commonInteractions:
      'Drug Interactions\r\nAlcohol: Increased risk of elevated LFTs\r\nMAOIs and serotonergic drugs: Risk of serotonin syndrome with concomitant use with other serotonergic drugs including triptans, tricyclic antidepressants, fentanyl, lithium,tramadol, tryptophan, buspirone and St. Johnâ€™s Wort, and with drugs that impair metabolism of serotonin such as MAOIs and linezolid and methylene blue.\r\nCatecholamines: Increased risk of arrhythmia and hypertension\r\nClonidine: May attenuate antihypertensive effect\r\nCYP1A2 inhibitors: Strong inhibitors (e.g., ciprofloxacin, fluvoxamine, enoxacin) increase duloxetine levels many fold and should be avoided.\r\nCYP2D6 inhibitors: Strong inhibitors (e.g., bupropion, paroxetine, fluoxetine, quinidine) increase duloxetine levels.\r\nCYP2D6 substrates: Duloxetine inhibits CYP2D6 and can increase levels of CYP2D6 substrates (e.g., many antipsychotics and antidepressants, metoprolol, dextromethorphan and others).',
    patientInstructions:
      ' Avoid alcohol use. Increased risk of suicide; contact physician for suicidal thoughts or change in behavior. Patients should be advised of the risk factors for serotonin syndrome and symptoms that may include mental changes (e.g., agitation, hallucinations, delirium), increased heart rate and labile blood pressure, dizziness, sweating, flushing, fever, tremor, rigidity, nausea, vomiting, diarrhea. Monitor blood pressure. Slow taper for discontinuation to prevent withdrawal symptoms.',
    comments:
      'Modestly more effective than placebo in fibromyalgia clinical trials; withdrawal for adverse effects was more common with duloxetine than placebo in fibromyalgia (19.6% vs. 11.8%) and OA (16.3% vs. 5.6%).',
    clinicalPharmacology:
      'Well absorbed, hepatic metabolism by CYP1A2 and CYP2D6, half-life 12 hours',
    price: '$$$',
    citation:
      'Lunn MP, Hughes RA, Wiffen PJ. Duloxetine for treating painful neuropathy, chronic pain or fibromyalgia. Cochrane Database Syst Rev 2014;1:CD007115. PMID: 24385423.',
  },
  {
    name: 'Edrophonium Chloride\r\n',
    brandNames: 'Tensilon, Enlon',
    drugClass: 'Short-acting acetylcholinesterase inhibitor',
    __EMPTY: 'Injection, 10 mg/mL',
    dosage:
      'Diagnostic test for myasthenia gravis: 2 mg i.v. over 15â€“30 seconds; then, if no response is seen, 8 mg 45 seconds later',
    indications:
      'Diagnostic test for myasthenia gravis; used to differentiate cholinergic crisis from myasthenic crisis',
    mechanismOfAction:
      'Increases acetylcholine concentrations by inhibiting its breakdown by acetylcholinesterase',
    contraindications:
      'Hypersensitivity to edrophonium or sulfites; GI or genitourinary obstruction',
    precautions:
      'May worsen weakness if this is caused by overtreatment (cholinergic crisis); intravenous atropine must be available to treat cholinergic symptoms. Use with caution in patients with asthma or those receiving cardiac glycosides.',
    __EMPTY_1:
      'Must be administered under medical supervision with resuscitation facilities on hand.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Cholinergic symptoms: nausea, vomiting, cramps, diarrhea, salivation, sweating, small pupils, lacrimation\r\nLess common: Bradycardia, seizures, hypersensitivity, bronchospasm, laryngospasm',
    commonInteractions: 'Anticholinesterases, neostigmine and physostigmine: Increased effect.',
    patientInstructions:
      'clinicalPharmacology: Onset of action is within 60 seconds; duration of effect is 10 minutes.',
    price: '$$$',
  },
  {
    name: 'Epoprostenol\r\n',
    brandNames: 'Flolan',
    drugClass: 'Prostaglandin',
    synonyms: 'Synonyms: Prostacyclin, PGI2',
    __EMPTY: 'Injection: 0.5- and 1.5-mg vials',
    dosage:
      'Administered by a pump as a continuous intravenous infusion through a central line. The usual starting dose is 2 ng/kg/min, and the dose is titrated up depending on clinical response and adverse effects.',
    indications:
      'Pulmonary arterial hypertension, pulmonary hypertension associated with connective tissue diseases',
    mechanismOfAction:
      'Prostacyclin is a potent vasodilator and also decreases platelet aggregation.',
    contraindications:
      'Hypersensitivity, heart failure with severe left ventricular systolic dysfunction',
    precautions:
      'Sudden discontinuation of drug can cause rebound pulmonary hypertension. Initiation requires specialized cardiopulmonary monitoring and expertise.',
    __EMPTY_1:
      'Monitor closely for clinical response, adverse effects, pump dysfunction, status of central line and adherence to therapy.',
    pregnancyRisk: 'B',
    commonSideEffects:
      '\r\nCommon: Flushing, tachycardia, GI side effects (nausea, vomiting, diarrhea, cramps), dizziness, headache, jaw pain, tremor\r\nLess common: Hypotension, syncope, angina, edema, rash, anemia, central line infection',
    commonInteractions:
      'May potentiate the hypotensive effects of other vasodilators or antihypertensive drugs',
    patientInstructions:
      ' Therapy requires care of a central line, wearing a pump, and self-administration of drug. Dose titration is required. Do not discontinue the drug suddenly.',
    comments:
      'Epoprostenol is only prescribed by physicians expert in the management of pulmonary hypertension, generally cardiologists or pulmonologists with a special interest in pulmonary hypertension. In clinical trials, the 6-minute walk distance improved in patients with scleroderma with pulmonary hypertension treated with epoprostenol. There are reports of digital ulcers or digital ischemia improving.',
    clinicalPharmacology: 'Rapidly hydrolyzed in blood, half-life 5 minutes',
    citation:
      'Badesch DB, Tapson VF, McGoon MD, et al. Continuous intravenous epoprostenol for pulmonary hypertension due to the scleroderma spectrum of disease. A randomized, controlled trial. Ann Intern Med 2000;132:425â€“434. PMID:10733441',
  },
  {
    name: 'Etanercept\r\n',
    description:
      'A protein therapy based on the binding fragment of the tumour necrosis factor alpha receptor used to treat severe rheumatoid arthritis and moderate to severe plaque psoriasis.',
    brandNames: 'Enbrel, Eticovo',
    drugClass: 'Biologic DMARD, TNF antagonist',
    __EMPTY: '25-mg and 50-mg injection',
    dosage:
      'for RA, ankylosing spondylitis and psoriatic arthritis 50 mg by subcutaneous injection once a week or 25 mg twice weekly',
    administration: 'Injection',
    indications:
      'Rheumatoid arthritis, psoriatic arthritis, psoriasis, ankylosing spondylitis, polyarticular juvenile idiopathic arthritis',
    mechanismOfAction:
      'Etanercept is a dimer of p75 TNF receptors fused to the Fc portion of IgG1. The addition of IgG1 increases the half-life. Etanercept binds to TNF and blocks its ability to bind with its receptor on the cell.',
    contraindications:
      'Hypersensitivity, untreated tuberculosis or other opportunistic infections, sepsis, active infections, chronic localized or recurrent infections, demyelinating disease, optic neuritis or heart failure',
    precautions:
      'Increased risk of serious infections including TB and fungal. Exclude latent or active TB with a skin test or TB blood test (interferon-gamma release assays or IGRA). Caution in debilitated or high risk of infection. Exclude active hepatitis B infection or carriage. Avoid live virus vaccines and BCG. Do not use with other biologics. May exacerbate pre-exisiting demyelinating disease and heart failure.',
    __EMPTY_1:
      'Monitor clinically for infection and periodic CBC. After therapy started, additional TB testing may be indicated for individuals likely to have exposure to TB.',
    pregnancyRisk: 'B',
    commonSideEffects:
      '\r\nCommon: Injection site reactions, positive antinuclear and double stranded DNA antibody\r\nLess common: Allergy, infection (bacterial, but particularly opportunistic infections such as tuberculosis, listeriosis, and histoplasmosis).\r\nRare: Lymphoma (including fatal hepatosplenic T-cell lymphoma), hepatitis, demyelinating CNS disorders, optic neuritis, seizures, pancytopenia, drug-induced lupus, reactivation of hepatitis B, new onset psoriasis.',
    commonInteractions:
      'Concurrent use of other immunosuppressants may increase risk of infection.',
    patientInstructions:
      ' Avoid live virus vaccines. Avoid pregnancy. Stop injections if an infection develops that requires antibiotics or a fever develops that lasts more than a few days.',
    comments:
      'TNF antagonists are among the most effective treatments for RA. Patients start to respond quickly, usually within 4â€“6 weeks but maximum response may take 4-6 months. In RA, combined therapy with an anti-TNF drug and MTX is more effective than either drug alone. TNF antagonists are being explored in a range of diseases such as sarcoidosis and inflammatory eye disease. Risk of tuberculosis may be lower with etanercept than other TNF antagonists; comparative risk of adverse effects among individual TNF antagonists is not clear and side effects likely a class effect. Patients may form antibodies to anti-TNF drugs that decrease their effect. Concurrent treatment with MTX may reduce the frequency of this.',
    clinicalPharmacology:
      'Half-life is 4â€“5 days. Biologic agents are not metabolized and thus have few drug interactions.',
    price: '$$$$$',
    citation:
      'Bombardier C, Hazlewood GS, Akhavan P, et al. Canadian Rheumatology Association recommendations for the\r\npharmacological management of rheumatoid arthritis with traditional and biologic disease-modifying antirheumatic drugs: part II safety. J Rheumatol 2012;39:1583-602. PMID: 22707613.',
  },
  {
    name: 'Etidronate\r\n',
    brandNames: 'Didronel',
    drugClass: 'Bisphosphonate',
    synonyms: 'Synonyms: Disodium etidronate, sodium etidronate',
    __EMPTY: '\r\nTablets: 200- and 400 mg',
    dosage:
      'Dose\r\nNewer bisphosphonates are preferred (see comments)\r\nPagetâ€™s disease: initially 5 mg/kg/day orally not to exceed 6 months\r\nHeterotopic ossification with total hip replacement: 20 mg/kg/day for 1 month before and 3 months after surgery (4 months total)',
    indications:
      'Pagetâ€™s disease, prevention and treatment of heterotopic ossification (e.g., calcification after hip replacement)',
    mechanismOfAction:
      'Adsorbs onto hydroxyapatite crystals and blocks their aggregation and growth',
    contraindications:
      'Hypersensitivity to bisphosphonates; severe renal impairment; osteomalacia; severe esophageal abnormalities, pregnancy',
    precautions:
      'Reduce dose in mild renal impairment. Avoid if possible if esophageal problems or renal impairment are present. Ensure that patient understands how the drug should be taken. A dental exam and preventive dental work should be performed before starting chronic bisphosphonate therapy. Pagetâ€™s disease with lytic lesions have a risk of fracture.',
    __EMPTY_1:
      'In Pagetâ€™s disease, alkaline phosphatase and markers of bone turnover are monitored as measures of response to treatment.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Mild GI disturbance (i.e., nausea, dyspepsia, dysphagia), hypocalcemia (transient, mild), headache, increased bone pain in Pagetâ€™s\r\nLess common: Severe erosive esophagitis, osteomalacia, osteonecrosis of the jaw, severe musculoskeletal pain, uveitis, altered taste, allergy',
    commonInteractions:
      'Drug Interactions\r\nAntacids: Decreased bioavailability of etidronate\r\nCalcium: If taken together, decreased bioavailability of etidronate',
    patientInstructions:
      ' Take on an empty stomach (2 hours before meals). Should be taken with a full glass of water on arising in the morning. Nothing other than water should be taken for at least 30 minutes. Even coffee or fruit juice markedly reduce absorption. Delaying such intake for longer than 30 minutes (1â€“2 hours if possible) maximizes absorption. After taking, the patient must remain upright to reduce risk of esophageal irritation. Any other medications must be taken at least 30 minutes after bisphosphonate. Supplement calcium and vitamin D intake if required.',
    comments:
      'Etidronate should not be used for treatment of osteoporosis. Newer bisphosphonates are preferred for treatment of Pagetâ€™s as they are more potent and less likely to cause osteomalacia. Supplemental calcium and vitamin D are usually co-administered.',
    clinicalPharmacology:
      'Absorption 1%â€“5%; no metabolism; renal excretion. Half of absorbed dose is eliminated in 24 hours; the rest binds to calcium phosphate surfaces and is eliminated over months to years.',
    citation:
      "Ralston SH. Clinical practice. Paget's disease of bone. N Engl J Med 2013;368:644-50. PMID: 23406029",
  },
  {
    name: 'Etodolac\r\n',
    brandNames: 'Lodine, Lodine XL',
    drugClass: 'NSAID',
    synonyms: 'Synonyms: Etodolic acid',
    __EMPTY:
      '\r\nCapsules: 200- and 300 mg\r\nTablets: 400- and 500 mg\r\nTablets, extended release (XL): 400-, 500-, 600 mg',
    dosage:
      '200â€“400 mg two or three times daily; doses above 1000 mg/day have not been adequately evaluated. Extended release 400â€“1,000 mg once daily.',
    comments: 'Compared with traditional, older NSAIDs, somewhat COX-2 â€œpreferentialâ€',
    clinicalPharmacology:
      'Well absorbed after oral administration; hepatic metabolism and renal excretion of metabolites; half-life 7 hours',
    price: '$$$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
  },
  {
    name: 'Febuxostat\r\n',
    brandNames: 'Uloric',
    drugClass: 'Xanthine oxidase inhibitor; antigout agent',
    __EMPTY: '40- and 80-mg tablets',
    dosage:
      'For prophylaxis of gout, initially use 40 mg daily if do not achieve target uric acid of less than 6 mg/dL after 4 weeks increase to 80 mg daily. Some patients require 120 mg/day. No dose adjustment required for mild renal impairment but little data with more severe renal impairment (creatinine clearance less than 30 ml/min).',
    indications: 'To treat hyperuricemia in patients with gout',
    mechanismOfAction:
      'Inhibits formation of uric acid by inhibiting xanthine oxidase; has no anti-inflammatory activity.',
    contraindications: 'Concomitant azathioprine or mercaptopurine',
    precautions:
      'When initiating therapy use NSAID or colchicine prophylaxis to reduce risk of acute attack.',
    __EMPTY_1:
      'Monitor liver function tests before, after 2 and 4 months, and then periodically. Uric acid level guides dose; aim to reduce uric acid concentration to <6 mg/dL.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Acute gout occurs with increased frequency after starting febuxostat, rash, increased liver function tests, nausea\r\nRarely: Hypersensitivity, serious skin reactions, hepatic failure, myocardial infarction',
    commonInteractions:
      'Drug Interactions\r\nAzathioprine and mercaptopurine: This is a potentially fatal interaction. Febuxostat decreases metabolism of these agents, leading to toxicity (bone marrow depression). Avoid concurrent use.\r\nTheophylline: Increased theophylline levels\r\nPegloticase: Urate lowering therapies including febuxostat should be discontinued prior to the use of pegloticase and should not be initiated during pegloticase therapy.',
    patientInstructions:
      ' The drug needs to be taken every day (or as prescribed) to lower uric acid and thus prevent gout. It has no effect on the symptoms of gout and will not help when joints hurt. Do not drink alcohol.',
    comments:
      'Xanthine oxidase inhibitors are used primarily to lower the uric acid level in patients with recurrent attacks of acute gout (more than one per year) or patients with tophaceous gout. They are preferred over a uricosuric agent in patients with tophi, renal stones, and impaired renal function. Monitor serum uric acid and titrate dose with aim to decrease to 5â€“6 mg/dL. Failure to decrease uric acid often indicates poor compliance. Prevent acute attacks of gout with concurrent colchicine or NSAID treatment, particularly in first 3â€“6 months of therapy. High doses of aspirin are uricosuric but low doses increase uric acid levels. Many, but not all, patients who are hypersensitive to allopurinol appear to tolerate febuxostat. Febuxostat 80 mg/day and 120 mg /day were more effective in lowering uric acid than a suboptimal clinical strategy of a fixed dose (300 mg/day) of allopurinol. Comparison with a titrated dose allopurinol will be of interest.',
    clinicalPharmacology:
      'Oral absorption is 50%; 99% protein bound, metabolism by uridine diphosphate glucuronosyltransferases (UGTs), half-life 5-8 hours. If xanthine oxidase inhibitor is discontinued, uric acid concentrations return to pretreatment levels in weeks.',
    citation:
      'Becker MA, Schumacher HR Jr, Wortmann RL, et al Febuxostat compared with allopurinol in patients with hyperuricemia and gout. N Engl J Med 2005;353:2450-61. PMID:16339094.',
  },
  {
    name: 'Fenoprofen\r\n',
    brandNames: 'Nalfon',
    drugClass: 'NSAID',
    synonyms: 'Synonyms: Fenoprofen calcium',
    __EMPTY: '\r\nCapsules: 200- and 400 mg\r\nTablet: 600 mg',
    dosage: '300â€“600 mg three to four times daily; maximum dose not to exceed 3.2 g/day',
    clinicalPharmacology:
      'Well absorbed after oral administration; hepatic metabolism and renal excretion of metabolites; half-life 3 hours',
    price: '$$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions and patientInstructions',
  },
  {
    name: 'Fentanyl\r\n',
    brandNames: 'Duragesic',
    drugClass: 'Opioid analgesic',
    __EMPTY:
      'Transdermal system: 12 mcg/h (5 cm2), 25 mcg/h (10 cm2), 50 mcg/h (20 cm2), 75 mcg/h (30 cm2), 100 mcg/h (40 cm2)',
    dosage:
      'Use only in patients who have already used opioids who require round the clock analgesia. For pain control in adults initially use the transdermal 12 mcg/h or 25 mcg/h system and titrate according to response. Most patients require application every 72 hours. Apply to dry, non-hairy skin on trunk or upper arms.',
    indications:
      'Chronic intractable pain not responding to other therapies including immediate release opioids',
    mechanismOfAction: 'Binds to opioid receptors in CNS',
    contraindications:
      'Hypersensitivity to fentanyl, substance abuse, mild pain, intermittent pain, pain only requiring analgesia for a short period of time, not already opioid tolerant, MAOIs, paralytic ileus',
    precautions:
      'Use caution in patients with hypersensitivity to other opioids, the elderly, respiratory disease, renal or hepatic impairment. Decrease dose if hepatic or renal impairment. Interactions with CYP450 3A4 inhibitors. Use in non-opioid tolerant patients can cause fatal respiratory depression.',
    __EMPTY_1: 'Ensure that drug is used to control pain. Potential for abuse and diversion.',
    pregnancyRisk: 'D, neonatal abstinence syndrome',
    commonSideEffects:
      '\r\nCommon: Drowsiness, constipation, dysphoria, nausea, hypotension, bradycardia\r\nLess common: CNS stimulation, insomnia, seizures, respiratory depression, dependence, itching, hives',
    commonInteractions:
      'Drug Interactions\r\nCytochrome P4503A4 inhibitors: CYP3A inhibitors such as ritonavir, nelfinavir, ketoconazole and other azoles, trolandeomycin, erythromycin, clarithromycin, verapamil, diltiazem and nefazodone increase fentanyl concentrations and monitoring and dose adjustments are needed.\r\nCytochrome P4503A4 inducers: CYP3A inducers such as rifampin, carbamazepine and phenytoin increase metabolism of fentanyl and can lower levels.\r\nCNS depressants: Increased toxicity\r\nMAOIs: Avoid. Risk of hypertensive crisis',
    patientInstructions:
      ' Drug is addictive. Use only to control pain. Do not use with alcohol or other CNS depressants. Impairs driving and ability to operate machinery. Do not expose patch to heat while wearing. Chewing patch can result in death. Used patches should be folded and flushed down toilet. Keep patches (new and used) away from children. Do not use damaged patches. Give patients written detailed patient information literature. http://www.duragesic.com/patient-information.html',
    comments:
      'The respiratory depressant effect may last longer than the analgesic effect. Absorption from the patch increases in patients with fever. Patch is suitable for continuous pain, but its slow onset of action makes it unsuitable for immediate pain control. Levels decrease slowly after patch removed (50% decrease in 13-22 h).',
    clinicalPharmacology:
      'Slow absorption from transdermal preparation; time to maximal concentration approx 30h; after application of a new dose, evaluate analgesic effect after 24-48 hours. Hepatic metabolism by CYP3A4 and urinary elimination as metabolites.',
    price: '$$$$',
    citation:
      'Pergolizzi J, BÃ¶ger RH, Budd K, et al. Opioids and the management of chronic severe pain in the elderly: consensus statement of an International Expert Panel with focus on the six clinically most often used World Health Organization Step III opioids (buprenorphine, fentanyl, hydromorphone, methadone, morphine, oxycodone). Pain Pract. 2008 Jul-Aug;8(4):287-313. PMID:1850366',
  },
  {
    name: 'Flurbiprofen\r\n',
    brandNames: 'Ansaid',
    drugClass: 'NSAID',
    __EMPTY: '50- and 100-mg tablets',
    dosage: '200â€“300 mg/day in two to four divided doses',
    clinicalPharmacology:
      'Well absorbed after oral administration; renal elimination; half-life 4 hours',
    price: '$$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
  },
  {
    name: 'Folic Acid\r\n',
    brandNames: 'Generics',
    drugClass: 'Vitamin',
    synonyms: 'Synonyms: Folate, pteroylglutamic acid',
    __EMPTY: '\r\nTablets: 0.4 mg, 0.8 mg, 1 mg\r\nInjection: 5 mg/mL',
    dosage: 'For prophylaxis of methotrexate adverse effects 1 mg/day',
    indications:
      'Used to prevent adverse effects caused by methotrexate (oral ulcers, GI intolerance, hematologic, hepatic)',
    mechanismOfAction:
      'May inhibit effects of methotrexate in some cells; does not affect methotrexate control of RA',
    contraindications:
      'Vitamin B12 deficiency. Folate may obscure the diagnosis of pernicious anemia. Neurologic deficit may progress while anemia improves.',
    precautions: 'If patient is macrocytic, check vitamin B12 level before folate supplementation.',
    pregnancyRisk: 'A',
    commonSideEffects: ': Uncommon: flushing, rash',
    commonInteractions:
      'comments: Patients treated with methotrexate should usually receive folic acid.',
    price: '$',
    citation:
      'Shea B, Swinden MV, Tanjong Ghogomu E, et al. Folic acid and folinic acid for reducing side effects in patients receiving methotrexate for rheumatoid arthritis. Cochrane Database Syst Rev. 2013 May 31;5:CD000951.PMID:23728635',
  },
  {
    name: 'Folinic Acid\r\n',
    brandNames: 'Lederle Leucovorin',
    drugClass: 'Vitamin',
    synonyms: 'Synonyms: Leucovorin calcium, citrovorum factor, tetrahydrofolate',
    __EMPTY:
      '\r\nInjection: 10 mg/mL\r\nPowder for injection: 50-, 100-, 200-, 350 mg\r\nTablets: 5-, 15-, 25 mg',
    dosage:
      'After inadvertent overdose, or prolonged methotrexate concentrations in patients with renal failure, use folinic acid 10 mg/m2 i.v. every 6 h for 72 hours until MTX concentrations decrease to <1 x 10-8 mol/L. Alternatively, similar oral doses have been used in patients who had no nausea or vomiting. Extremely high concentrations of MTX require higher doses of folinic acid. (Dosing graphs are available in the package insert to further delineate dose.) Urine alkalinization and hydration are also often indicated. With routine MTX use, 5 mg folinic acid orally is given post-dose once a week to lessen side effects.',
    indications: 'MTX toxicity',
    mechanismOfAction: 'Replaces the folinic acid whose synthesis is inhibited by MTX',
    __EMPTY_1:
      'In MTX overdose or toxicity, measurement of MTX concentrations is a useful guide to therapy and folinic acid dose.',
    pregnancyRisk: 'C',
    comments:
      'Folinic acid has been used to prevent minor adverse effects owing to MTX, but the data supporting the use of folic acid are stronger, folic acid is cheaper, and there is theoretical concern that folinic acid may antagonize the anti-inflammatory effects of MTX.',
    price: '$$',
  },
  {
    name: 'Gamma-Globulin\r\n',
    brandNames: 'Gammaked, Gammagard, Gammaplex, Flebogamma, Bivigam',
    drugClass: 'Immunoglobulin',
    synonyms: 'Synonyms: Intravenous immunoglobulin, IVIG',
    __EMPTY: '\r\nDifferent brands have preparations containing : 1.0-, 2.5-, 5-, 10-, 20g',
    dosage:
      'Dose\r\nAdministered intravenously at the rate specified by the manufacturer â€™s instructions; generally, start at a slow rate 0.5â€“1.0 mL/min and increase.\r\nPrimary immunodeficiencies: 200â€“400 mg/kg every 4 weeks\r\nKawasaki disease: 2 g/kg in one dose, or 400 mg/kg/day for 4 days. Concomitant aspirin therapy is indicated.\r\nChronic idiopathic thrombocytopenic purpura: 400 mg/kg/day for 5 days\r\nInflammatory myositis: 1 g/kg/day for 2 days once monthly\r\nOther autoimmune disease: 400 mg/kg/day for 1â€“4 days per month',
    indications:
      'Primary immunodeficiencies associated with hypogammaglobulinemia or agammaglobulinemia; also used in idiopathic thrombocytopenic purpura, Kawasaki disease, Guillain-BarrÃ© syndrome, and chronic inflammatory demyelinating polyneuropathy. Uncontrolled reports suggest efficacy in some autoimmune diseases refractory to other treatment.',
    mechanismOfAction:
      'In primary immunodeficiencies, intravenous immunoglobulin replaces IgG; in autoimmune disease, mechanism unknown. Theories include blockade of Fc receptors, T cell inhibition, and solubilization of immune complexes.',
    contraindications:
      'Hypersensitivity to immunoglobulin. In patients with profound IgA deficiency (serum IgA <5 mg/dL), many intravenous immunoglobulin products containing larger amounts of IgA are contraindicated as they may cause anaphylactic reactions.',
    precautions:
      'Patients with profound IgA deficiency may develop anaphylactic reactions. If intravenous immunoglobulin is required in these patients, use an IgA-deficient preparation (consult your pharmacy). Avoid high dose regimes (1g/kg/day) in fluid intolerant patients. Caution in renal impairment, diabetes, heart failure and the elderly.',
    __EMPTY_1:
      'For autoimmune disease, intravenous immunoglobulin is usually administered in the hospital on an outpatient basis, with monitoring of vital signs.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Flushing, tachycardia, chills, dyspnea: may respond to slowing the rate of infusion\r\nLess common: Hypotension, anaphylaxis, aseptic meningitis, hyperviscosity, thrombosis, nephritic syndrome, acute renal failure (more likely with sucrose containing preparations), hemolysis, pulmonary edema. Transmission of viral infection is uncommon. In the past, rare cases of transmission of hepatitis C occurred with inadequately treated preparations.',
    commonInteractions: 'May interfere with the action of live virus vaccines',
    patientInstructions:
      'comments: Prepared from pooled plasma. Few controlled trials exist to guide appropriate use of intravenous immunoglobulin in the rheumatic diseases.',
    price: '$$$$$',
    citation:
      'Gelfand EW. Intravenous immune globulin in autoimmune and inflammatory diseases. N Engl J Med. 2012 Nov 22;367(21):2015-25.PMID: 23171098',
  },
  {
    name: 'Glucosamine\r\n',
    brandNames:
      'Sold in health food stores as a dietary supplement under many brandNames, often in conjunction with chondroitin sulfate.',
    drugClass: 'Dietary supplement preparations',
    synonyms: 'Synonyms: Glucosamine sulfate',
    __EMPTY: 'Tablets: 250-, 500-, 750 mg alone and in combination with chondroitin',
    dosage: 'Usual dose is 500 mg three times daily or 750 mg twice daily',
    indications: 'Osteoarthritis',
    mechanismOfAction:
      'Unknown; hypothesized to incorporate into cartilage and stimulate proteoglycan synthesis',
    contraindications: 'Hypersensitivity',
    precautions: 'Caution in patients allergic to shellfish, diabetes',
    pregnancyRisk: 'Unknown',
    commonSideEffects: ': Uncommon: GI side effects (gas, loose stools, cramps)',
    patientInstructions:
      'comments: Because it is a dietary supplement and not a drug, glucosamine has not been subjected to the usual rigorous evaluation applied to new drugs by the FDA. Uncontrolled, and some controlled, studies have reported modest beneficial effects on OA symptoms and possible slowing of radiologic loss of cartilage in the knees. However, the evidence supporting the use of glucosamine for treating osteoarthritis is debated and a meta-analysis, as well as the best controlled trial, suggest no benefit. The possible differential benefit of glucosamine versus chondroitin sulfate and the different preparations of glucosamine available is debated.',
    clinicalPharmacology: 'Little information available',
    price: '$',
    citation:
      'Henrotin Y, Mobasheri A, Marty M. Is there any scientific evidence for the use of glucosamine in the management of human osteoarthritis? Arthritis Res Ther. 2012;14:201. doi: 10.1186/ar3657. PMID: 22293240.',
  },
  {
    name: 'Gold, Injectable Preparations\r\n',
    brandNames: 'Gold sodium thiomalate (Myochrysine), availability varies',
    drugClass: 'DMARD',
    __EMPTY: '\r\nGold sodium thiomalate: 50 mg/mL',
    dosage:
      '10 mg i.m. test dose first week, 25 mg i.m. the next week, and then 25-50 mg/wk i.m. thereafter until response or a cumulative dose of 1 g. Once response is obtained, decrease frequency of injections to every 2 weeks for 2 months, then decrease frequency and maintain on 50 mg i.m. every 3â€“4 weeks. Most patients receive a maintenance dose of 50 mg monthly (every 4 weeks).',
    indications: 'Rheumatoid arthritis (largely obsolete)',
    mechanismOfAction: 'Unknown; probably interferes with normal macrophage function',
    contraindications:
      'Hypersensitivity to gold, severe renal or hepatic disease, blood dyscrasias',
    precautions:
      'Regular monitoring is required. Administer under medical supervision with facilities to treat anaphylaxis.',
    __EMPTY_1:
      'Perform baseline CBC, creatinine, LFTs and urinalysis. Check CBC, platelets, and urinalysis before each injection and LFTs periodically. Discontinue gold if there is a rapid fall in any blood parameter or if WBC is <4,000, granulocytes <1,500, or platelets <100,000. If there is persistent proteinuria (>300 mg/24 h), discontinue.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Rash, itching, painful mouth ulcers, altered taste, proteinuria\r\nLess common: Anaphylaxis, exfoliative dermatitis, glomerulonephritis, nephrotic syndrome, blue/black skin discoloration. Blood dyscrasias (aplastic anemia, agranulocytosis, thrombocytopenia) should be looked for because they may be severe, precipitous, and fatal. Hepatotoxicity, pulmonary fibrosis, and peripheral neuropathy are uncommon. Gold sodium thiomalate preparations have uncommonly been associated with a nitritoid reaction (flushing, sweating, dizziness) after the injection.',
    commonInteractions: 'Potentially increased toxicity with other DMARDs',
    patientInstructions:
      ' Frequent monitoring is essential. Do not become pregnant. Notify physician of rash and mouth sores.',
    comments:
      'Toxicity is common and can be severe. Largely obsolete and replaced by newer DMARDs. Onset of response is slow (6â€“12 weeks). If no response by 24 weeks, consider stopping. If there is a poor response after 1 g total dose, discontinue. A small minority of patients with RA responded well to intramuscular gold and tolerated it long term.',
    clinicalPharmacology:
      'Injectable gold is only given intramuscularly. Absorption is slow and erratic. Most is renally excreted. Plasma half-life is ~30 days, but gold is present in tissues months to years later.',
    price:
      '$$ (Medication is relatively inexpensive, but the cost of weekly administration and laboratory work increases the total cost.)',
    citation:
      'American College of Rheumatology Committee on Rheumatoid Arthritis Clinical Guidelines.Guidelines for the management of rheumatoid arthritis:2002 update. Arthritis Rheum 2002;46:328â€“346. PMID:11840435',
  },
  {
    name: 'Golimumab\r\n',
    description:
      'A TNFα inhibitor used in the symptomatic treatment of various active inflammatory disorders, such as rheumatoid arthritis, psoriatic arthritis, ankylosing spondylitis, and ulcerative colitis.',
    brandNames: 'Simponi, Simponi Aria',
    drugClass: 'Biologic DMARD, TNF antagonist',
    __EMPTY:
      ':\r\nSimponi: Subcutaneous injection 50 mg/0.5 ml and 100 mg/1 ml prefilled syringes or prefilled autoinjector\r\nSimponi Aria: IV solution 50 mg/4ml',
    dosage:
      'For RA, psoriatic arthritis, ankylosing spondylitis 50 mg subcutaneous injection once a month, or for RA 2 mg/kg IV infusion at weeks 0, 4 and then every 8 weeks. For ulcerative colitis 200 mg subcutaneous injection at week 0, followed by 100 mg at week 2 and then 100 mg every 4 weeks.',
    administration: 'Injection',
    indications:
      'Rheumatoid arthritis, psoriatic arthritis, ankylosing spondylitis, ulcerative colitis',
    mechanismOfAction:
      'A human monoclonal antibody against TNF-alpha that blocks the effects of the cytokine.',
    contraindications:
      'Hypersensitivity, untreated tuberculosis and other opportunistic infections, sepsis, active infection, chronic localized or recurrent infections, demyelinating disease, optic neuritis, moderate-to-severe heart failure.',
    precautions:
      'Increased risk of serious infections including TB and fungal. Exclude latent or active TB with a skin test or TB blood test (interferon-gamma release assays or IGRA). Caution in debilitated or high risk of infection. Exclude active hepatitis B infection or carriage. Avoid live virus vaccines and BCG. Do not use with other biologics. May exacerbate pre-existing demyelinating disease and heart failure.',
    __EMPTY_1:
      'Monitor clinically for infection. CBC periodically. After therapy started, additional TB testing may be indicated for individuals likely to have exposure to TB.',
    pregnancyRisk: 'B',
    commonSideEffects:
      '\r\nCommon: Injection site reactions (subcutaneous), minor infusion reactions (IV), positive antinuclear antibody, positive antinuclear and double stranded DNA antibody.\r\nLess common: Serious infusion reactions, allergy, infection (bacterial, but particularly opportunistic infections such as tuberculosis, listeriosis, and histoplasmosis).\r\nRare: Anaphylaxis, lymphoma (including fatal hepatosplenic T-cell lymphoma), hepatitis, demyelinating CNS disorders, optic neuritis, seizures, pancytopenia, drug-induced lupus, reactivation of hepatitis B, new onset psoriasis.',
    commonInteractions:
      'Concurrent use of high-dose steroids and other immunosuppressants may increase risk of infection.',
    patientInstructions:
      ' Avoid live virus vaccines; avoid pregnancy; stop injections and call your doctor if an infection or fever develops that lasts more than a few days.',
    comments:
      'TNF antagonists are among the most effective treatments for RA. Patients start to respond quickly, usually within 4â€“6 weeks but maximum response may take 4-6 months. Combined therapy with MTX is more effective than either drug alone. TNF antagonists are being explored in a range of diseases such as sarcoidosis and inflammatory eye disease. Risk of tuberculosis may be lower with etanercept than other TNF antagonists; comparative risk of adverse effects among individual TNF antagonists is not clear and side effects likely a class effect. Patients may form antibodies to anti-TNF drugs that decrease their effect. Concurrent treatment with MTX may reduce the frequency of this.',
    clinicalPharmacology:
      'Half-life is 14 days. Biologic agents are not metabolized and thus have few drug interactions.',
    price: '$$$$$',
    citation:
      'Bombardier C, Hazlewood GS, Akhavan P, et al. Canadian Rheumatology Association recommendations for the pharmacological management of rheumatoid arthritis with traditional and biologic disease-modifying antirheumatic drugs: part II safety. J Rheumatol 2012;39:1583-602. PMID: 22707613.',
  },
  {
    name: 'Hyaluronate Injections\r\n',
    brandNames: 'Hyalgan, Monovisc, Orthovisc, Synvisc, Synvisc-One, Supartz',
    drugClass: 'Viscosupplement',
    synonyms: 'Synonyms: Viscosupplements, hyaluronic acid',
    __EMPTY:
      '\r\nHyalgan 2ml (avian)\r\nMonovisc 4 ml (bacterial)\r\nOrthovisc 2 ml (bacterial)\r\nSynvisc 2 ml (avian)\r\nSynvisc-One 6 ml (avian)',
    dosage:
      'Dose (for OA of the knee)\r\nHyalgan 2ml (20 mg) intra-articular injection once weekly for 5 weeks\r\nMonovisc 4ml (88 mg) intra-articular injection once\r\nOrthovisc 2ml (30 mg) intra-articular injection once weekly for 3-4 weeks\r\nSynvisc 2ml (16 mg) intra-articular injection once weekly for 3 weeks\r\nSynvisc-One 6ml (48 mg) intra-articular injection once',
    indications: 'Osteoarthritis of the knee',
    mechanismOfAction:
      'Unknown; initially thought to improve viscosity of synovial fluid but because the duration of clinical effects can far exceed the time the drug is present in the joint, other mechanisms of action are likely.',
    contraindications:
      'Hypersensitivity to hyaluronate or any component of the formulation (some preparations avian source, some bacterial)',
    precautions: 'Strict aseptic technique, do not inject extra-articularly',
    pregnancyRisk: 'Unknown',
    commonSideEffects:
      '\r\nCommon: Pain and swelling in the injected knee\r\nLess common: Rash, flushing, hypersensitivity',
    patientInstructions:
      'comments: Most trials have found efficacy greater than placebo but quality of trials has been poor with true double-blinding difficult due to the viscosity of the injection. Controversial whether there is a clinically relevant effect. Injection reactions in the treated knee are usually minor but can resemble septic arthritis.',
    price: '$$$',
    citation:
      'Rutjes AW, JÃ¼ni P, da Costa BR, et al. Viscosupplementation for osteoarthritis of the knee: a systematic review and meta-analysis. Ann Intern Med 2012;157:180-91 PMID: 22868835.',
  },
  {
    name: 'Hydroxychloroquine and Chloroquine Phosphate\r\n',
    description:
      'A disease modifying anti-rheumatic drug (DMARD) indicated for treatment of rheumatoid arthritis and lupus erythematosus.',
    brandNames: 'Plaquenil â€“ hydroxychloroquine; Aralen â€“ chloroquine',
    drugClass: 'Antimalarial, DMARD',
    __EMPTY:
      '\r\nHydroxychloroquine: 200 mg tablet (155 mg base)\r\nChloroquine: 250 mg tablet (150 mg base)',
    dosage:
      'Dose\r\nHydroxychloroquine: 5 mg/kg lean body weight. The usual initial dose is 400 mg/day (once daily or in divided doses). Dose may be reduced once a clinical response is achieved.\r\nChloroquine: 2-3 mg/kg lean body weight; usually not more than 250 mg daily',
    administration: 'Oral',
    indications: 'Rheumatoid arthritis, SLE, discoid lupus erythematosus, palindromic rheumatism',
    mechanismOfAction:
      'Unknown; may interfere with with macrophage presentation of antigen to T cells',
    contraindications: 'Hypersensitivity to hydroxychloroquine, chloroquine, or 4-aminoquinolines',
    precautions:
      'Use caution in hepatic disease, psoriasis, and porphyria. Reports suggest that antimalarials may exacerbate psoriasis, but they were often safely used to treat psoriatic arthritis. G-6PD deficiency.',
    __EMPTY_1:
      'Hydroxychloroquine is the preferred antimalarial because it is less toxic to the eye. Chloroquine is sometimes tolerated by patients who do not tolerate hydroxychloroquine. Ophthalmologic (retinal and visual field) testing should be performed at baseline or soon after drug initiation and then at 6 -12 month intervals. Risks of retinal toxicity with hydroxychloroquine increase with a cumulative dose >800 g, age >70 years, daily dose >6.0 mg/kg, and impaired hepatic or renal function.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: GI irritation, headache, rash, itch, blurred vision owing to ciliary muscle dysfunction\r\nLess common: Blue or black discoloration of skin or mucosa, reversible corneal opacities, irreversible retinal toxicity, neuromyopathy, cardiomyopathy, blood dyscrasias, ototoxicity, emotional changes, hemolysis with G6PD deficiency',
    commonInteractions: 'Penicillamine: increased toxicity',
    patientInstructions:
      'comments: In RA, most often used in combination with other DMARDs. Retinal toxicity is rare with a dose of <5 mg/kg lean body weight/day and normal liver and renal function. When it does occur, retinal toxicity usually occurs after years of use and is slow in onset and irreversible.',
    clinicalPharmacology:
      'Good oral absorption, may be taken with food, partial hepatic metabolism and renal elimination, extensive tissue deposition with tissue concentrations 300 times that of plasma. Only 10% of a dose is excreted in 24 hours, and hydroxychloroquine remains in tissues for months.',
    price: '$$$',
  },
  {
    name: 'Ibandronate\r\n',
    brandNames: 'Boniva',
    drugClass: 'Bisphosphonate',
    __EMPTY: 'Tablets: 150 mg, IV injection: 3 mg/3ml',
    dosage: 'Dose/administration\r\nOsteoporosis: oral 150 mg monthly; IV 3 mg every 3 months',
    indications: 'Treatment and prevention of osteoporosis',
    mechanismOfAction:
      'Antiresorptive; localizes to areas of bone resorption and inhibits osteoclast activity without any effect on bone formation',
    contraindications:
      'Hypersensitivity, hypocalcemia, esophageal stricture, or dysmotility. Not recommended for patients with severe renal insufficiency (creatinine clearance <30 mL/min). Avoid use in patients who cannot stand or sit upright for 30 minutes after administration.',
    precautions:
      'Avoid if possible if esophageal problems or renal impairment are present. Ensure that patient understands how the drug should be taken. A dental exam and preventive dental work should be performed before starting chronic bisphosphonate therapy.',
    pregnancyRisk: 'D',
    commonSideEffects:
      '\r\nCommon: Mild GI disturbance (i.e., nausea, dyspepsia, dysphagia), hypocalcemia (transient, mild), headache\r\nUncommon: Severe erosive esophagitis, allergy, atypical subtrochanteric femur fractures, osteonecrosis of the jaw, severe musculoskeletal pain, uveitis, altered taste, urticaria, angioedema, atrial fibrillation',
    commonInteractions: 'GI adverse events are increased in patients taking NSAIDs.',
    patientInstructions:
      ' Take on an empty stomach (2 hours before meals). Should be taken with a full glass of water on arising in the morning. Nothing other than water should be taken for at least 30 minutes. Even coffee or fruit juice markedly reduce absorption. Delaying such intake for longer than 30 minutes (1â€“2 hours if possible) maximizes absorption. After taking, the patient must remain upright to reduce risk of esophageal irritation. Any other medications must be taken at least 30 minutes after bisphosphonate. Supplement calcium and vitamin D intake if needed.',
    comments:
      'Supplemental calcium and vitamin D are usually co-administered. The optimal duration of treatment is uncertain. A temporary discontinuation may be considered after 3-5 years of therapy in patients at low risk of fracture with monitoring of bone density 2-3 years after discontinuation.',
    clinicalPharmacology:
      'Oral bioavailability is very poor (<1%) and negligible if administered with or after food. Absorbed drug is renally excreted and not metabolized. Elimination from body over months or years, indicating localization and slow release from bone.',
    price: '$$$',
  },
  {
    name: 'Ibuprofen\r\n',
    brandNames: 'Advil, Genpril, Ibuprin, Motrin, Nuprin',
    drugClass: 'NSAID',
    __EMPTY:
      '\r\nTablets: 200-, 400-, 600-, 800 mg\r\nSuspension (oral): 100 mg/5 mL\r\nChewable tablets: 50- and 100 mg',
    dosage: '400â€“800 mg three to four times daily; maximum of 3.2 g/day',
    clinicalPharmacology:
      'Well absorbed after oral administration; hepatic metabolism and renal excretion of metabolites; half-life 2â€“4 hours',
    price: '$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
  },
  {
    name: 'Indomethacin\r\n',
    brandNames: 'Indocin',
    drugClass: 'NSAID',
    __EMPTY:
      '\r\nCapsules: 25- and 50 mg\r\nCapsule, extended release: 75 mg\r\nSuspension oral: 25 mg/5 mL\r\nSuppository: 50 mg',
    dosage:
      'Dose\r\n25â€“50 mg orally two to three times daily (1â€“2 mg/kg/day); maximum, 200 mg/day\r\nSustained-release capsule (75 mg) administered one or two times/day',
    comments:
      'Indomethacin may be more likely to cause CNS side effects (headaches, somnolence, cognitive dysfunction), particularly in the elderly.',
    clinicalPharmacology:
      'Well absorbed after oral administration; hepatic metabolism and renal excretion of metabolites; half-life 4â€“5 hours',
    price: '$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
  },
  {
    name: 'Infliximab\r\n',
    description:
      "a monoclonal anti tumor necrosis factor alpha antibody used in the treatment of a wide variety of inflammatory conditions such as rheumatoid arthritis, Crohn's disease, and ankylosing spondylitis.",
    brandNames: 'Avsola, Flixabi, Inflectra, Remicade, Renflexi \r\n',
    drugClass: 'Biologic DMARD, TNF antagonist',
    __EMPTY: '100-mg injection',
    dosage:
      'RA: 3 mg/kg by slow intravenous infusion (at least 2 hours) at 0, 2, and 6 weeks and then repeated every 8 weeks. The maintenance dose required ranges from 3 to 10 mg/kg every 4â€“8 weeks. Dose escalations over time (5â€“10 mg/kg or at 4- to 6-week intervals) may be necessary to control disease in a minority of patients with aggressive disease.\r\nCrohnâ€™s disease, ulcerative colits, psoriatic arthritis, ankylosing spondylitis: 5 mg/kg by slow intravenous infusion at 0, 2, and 6 weeks and then repeated every 8 weeks.',
    administration: 'Injection',
    indications:
      'Rheumatoid arthritis, psoriatic arthritis, psoriasis, ankylosing spondylitis, Crohnâ€™s disease, ulcerative colitis',
    mechanismOfAction:
      'A chimeric monoclonal antibody that binds to TNF and blocks its ability to bind with its receptor on the cell.',
    contraindications:
      'Hypersensitivity, untreated tuberculosis and other opportunistic infections, sepsis, active infection, chronic localized or recurrent infections, demyelinating disease, optic neuritis, moderate-to-severe heart failure.',
    precautions:
      'Increased risk of serious infections including TB and fungal. Exclude latent or active TB with a skin test or TB blood test (interferon-gamma release assays or IGRA). Caution in debilitated or high risk of infection. Exclude active hepatitis B infection or carriage. Avoid live virus vaccines and BCG. Do not use with other biologics. May exacerbate pre-exisiting demyelinating disease and heart failure.',
    __EMPTY_1:
      'Monitor during infusion. Monitor clinically for infection. Periodic CBC. After therapy started, additional TB testing may be indicated for individuals likely to have exposure to TB.',
    pregnancyRisk: 'B',
    commonSideEffects:
      '\r\nCommon: Infusion reactions (rash, hives, itching, tachycardia, fever, nausea) are usually mild to moderate and can often be controlled with acetaminophen and antihistamines and slowing the rate of infusion. Positive antinuclear antibody, positive antinuclear and double stranded DNA antibody, anti-infliximab antibodies.\r\nLess common: Serious infusion reactions occur in <2% of patients and may manifest as severe rashes, hypotension, hemodynamic instability, chest tightness, severe dyspnea, or a feeling of impending doom. Cessation and aggressive treatment may require intravenous corticosteroids and hemodynamic support. Allergy, infection (bacterial, but particularly opportunistic infections such as tuberculosis, listeriosis, and histoplasmosis).\r\nRare: Anaphylaxis, lymphoma (including fatal hepatosplenic T-cell lymphoma), hepatitis, demyelinating CNS disorders, optic neuritis, seizures, pancytopenia, drug-induced lupus, reactivation of hepatitis B, new onset psoriasis.',
    commonInteractions:
      'Concurrent use of high-dose steroids and other immunosuppressants may increase risk of infection.',
    patientInstructions:
      ' Avoid live virus vaccines; avoid pregnancy; stop injections and call your doctor if an infection or fever develops that lasts more than a few days.',
    comments:
      'TNF antagonists are among the most effective treatments for RA. Patients start to respond quickly, usually within 4â€“6 weeks but maximum response may take 4-6 months. Combined therapy with MTX is more effective than either drug alone. TNF antagonists are being explored in a range of diseases such as sarcoidosis and inflammatory eye disease. Risk of tuberculosis may be lower with etanercept than other TNF antagonists; comparative risk of adverse effects among individual TNF antagonists is not clear and side effects likely a class effect. Patients may form antibodies to anti-TNF drugs that decrease their effect. Concurrent treatment with MTX may reduce the frequency of this.',
    clinicalPharmacology:
      'Half-life is 7-12 days. Biologic agents are not metabolized and thus have few drug interactions.',
    price: '$$$$$',
    citation:
      'Bombardier C, Hazlewood GS, Akhavan P, et al. Canadian Rheumatology Association recommendations for the pharmacological management of rheumatoid arthritis with traditional and biologic disease-modifying antirheumatic drugs: part II safety. J Rheumatol 2012;39:1583-602. PMID: 22707613.',
  },
  {
    name: 'Iron Dextran (Parenteral)\r\n',
    brandNames: 'Infed, Dexferrum',
    drugClass: 'Antianemia agent (iron supplement)',
    __EMPTY: 'Injection, 50 mg/mL (2 mL)',
    dosage:
      'Test dose 0.5 mL i.v. or i.m. Observe for at least 1 hour before administering dose. Calculate dose by referring to the treatment nomogram in the package insert and administer accordingly. Intravenous injection must be slow. Infuse very slowly initially, and observe for allergy or anaphylaxis. Intramuscular regimen may require several injections on different days (maximum at one site is 2 mL).',
    indications: 'Treatment of iron deficiency anemia in patients unable to take oral medication',
    mechanismOfAction: 'Replaces deficient iron stores',
    contraindications: 'Hypersensitivity to iron preparations, hemochromatosis',
    precautions:
      'Administer under medical supervision with facilities available to treat anaphylaxis. Administer test dose first. If administered by intramuscular injection, it must be by a deep intramuscular injection, using a Z-track technique, in the upper outer quadrant of the buttock (not in upper arm).',
    __EMPTY_1:
      'Monitor for allergy/anaphylaxis during administration. Use hemoglobin and reticulocyte count to monitor response.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Dizziness, fever, sweating, nausea, vomiting, metallic taste, discolored urine, staining skin at site of injection, leukocytosis\r\nLess common: Anaphylaxis, cardiovascular collapse, urticaria. Onset of arthralgia, sweating, urticaria, and dizziness may be delayed 1â€“2 days after administration.',
    price: '$$$ (includes cost of administration)',
  },
  {
    name: 'Iron Preparations (Oral)\r\n',
    brandNames:
      'Ferrous fumarate: Ferrimin\r\nFerrous gluconate: Fergon\r\nFerrous sulfate: FeroSul, Slow FE',
    drugClass: 'Antianemia agent (iron supplement)',
    __EMPTY:
      '\r\nFerrous fumarate (elemental iron 33%)\r\nTablets: 150-, 324- and 325 mg\r\nFerrous gluconate (elemental iron 11.6%)\r\nTablets: 240-, 324- and 325 mg\r\nFerrous sulfate (elemental iron 20%)\r\nTablets: 325 mg; Extended-release tablet: 142-, 160- and 324 mg; Syrup: 300 mg/5mL',
    dosage:
      'Dose\r\nTreatment of iron deficiency anemia : 60â€“100 mg of elemental iron twice daily\r\nProphylaxis of iron deficiency: 60 mg of elemental iron daily',
    indications: 'Prevention and treatment of iron deficiency anemia',
    mechanismOfAction: 'Replaces deficient iron stores',
    contraindications: 'Hypersensitivity to iron preparations; hemochromatosis',
    precautions:
      'Tablets/capsules may be corrosive to the bowel. In patients with dysphagia, liquid preparations are preferred.',
    __EMPTY_1: 'Hemoglobin and reticulocyte count to monitor response',
    pregnancyRisk: 'A; low doses oral iron preparations are often prescribed in pregnancy',
    commonSideEffects:
      '\r\nCommon: GI cramps, nausea, constipation, dark stools\r\nLess common: Diarrhea, GI ulceration',
    commonInteractions:
      'Drug Interactions\r\nTetracyclines: Decreased absorption of tetracycline and iron\r\nAntacids, histamine blockers and proton pump inhibitors: Decreased iron absorption\r\nPenicillamine: Decreased absorption of iron and penicillamine\r\nFluoroquinolones: Decreased absorption of antibiotic',
    patientInstructions:
      ' Take regularly. May cause blackish/dark green stools or guaiac-positive tests for occult blood. Keep out of reach of children.',
    comments:
      'Many patients with inflammatory rheumatic disease have a hypochromic microcytic anemia that is not due to iron deficiency but to chronic disease. This anemia does not respond to iron therapy. Failure of an iron deficiency anemia to respond to oral therapy usually signals an incorrect diagnosis or poor compliance. Once the hemoglobin concentration has been corrected, continue prophylactic doses of iron for 3â€“6 months to load body iron stores.',
    clinicalPharmacology:
      'Oral absorption of iron is increased in iron deficiency anemia. Absorption is decreased by gastrectomy and achlorhydria. Absorbed iron is stored in the body and is eliminated in small amounts by cellular shedding. After starting treatment, onset of reticulocytosis is rapid (3â€“5 days), and hemoglobin increases within 2â€“4 weeks.',
    price: '$',
  },
  {
    name: 'Ketoprofen\r\n',
    brandNames: 'Generic',
    drugClass: 'NSAID',
    __EMPTY: '\r\nCapsules: 25-, 50-, 75 mg\r\nCapsules, sustained release: 100-, 150- and 200 mg',
    dosage:
      'Immediate release: 50â€“75 mg three to four times daily; maximum 300 mg/day. Sustained release: 200 mg once daily (maximum). Lower doses in elderly.',
    clinicalPharmacology:
      'Well absorbed after oral administration; hepatic metabolism and renal excretion of metabolites. Half-life is 1â€“4 hours.',
    price: '$$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
  },
  {
    name: 'Ketorolac\r\n',
    brandNames: 'Toradol',
    drugClass: 'NSAID',
    __EMPTY: '\r\nTablet: 10 mg\r\nInjection: 15 mg/mL (1 mL), 30 mg/mL (1 mL, 2 mL)',
    dosage:
      'Dose\r\nTablet: 10 mg three to four times daily; maximum 40 mg/day; maximum 5 days administration\r\nInjection: 30 mg then 15â€“30 mg every 6 hours as needed (maximum daily dose 120 mg)',
    indications: 'Short-term management of acute pain',
    comments:
      'Only use for short-term control of pain. Lower doses in the elderly and if renal impairment.',
    clinicalPharmacology:
      'Well absorbed after oral administration; hepatic metabolism; renal excretion of 60% of drug unchanged. Half-life of 2â€“8 hours is increased in elderly persons.',
    price: '$$$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions',
    __EMPTY_2:
      'BIBLIOGRAPHY\r\nGillis JC, Brogden RN. Ketorolac. A re-appraisal of its pharmacodynamic and pharmacokinetic properties and therapeutic use in pain management. Drugs 1997;53:139â€“188. PMID: 9010653',
  },
  {
    name: 'Leflunomide\r\n',
    description: 'A pyrimidine synthesis inhibitor indicated to treat rheumatoid arthritis.',
    brandNames: 'Arava',
    drugClass: 'Antimetabolite, DMARD',
    __EMPTY: '10-, 20-, 100-mg tablets',
    dosage:
      'Leflunomide can be initiated with a loading dose of 100 mg daily for 3 days followed by 20 mg/day. Most rheumatologists omit the loading dose to minimize GI side effects. A maintenance dose of 10 mg/day (or 20 mg every 2 days) may be effective in patients who cannot tolerate 20 mg/day. When used in combination with MTX the usual starting dose of leflunomide is 10 mg/day (for 4â€“8 weeks) with careful clinical and laboratory monitoring.',
    administration: 'Oral',
    indications: 'Rheumatoid arthritis, psoriatic arthritis',
    mechanismOfAction: 'Inhibition of dihydroorotate dehydrogenase and pyrimidine synthesis',
    contraindications:
      'Hypersensitivity to leflunomide, liver disease, hepatitis B or C infection, alcoholism, pregnancy and breast feeding, avoid live virus vaccines and BCG, impaired bone marrow function, uncontrolled infection, unwilling to use reliable contraception',
    precautions:
      'The patient must understand the risks and benefits of treatment and the requirement for monitoring. Exclude pregnancy before starting. Pregnant women must not receive leflunomide. Counsel regarding risk, and ensure that contraceptive methods are in place in both men and women before starting therapy. Women of child-bearing age who discontinue therapy should take cholestyramine 8 g three times daily for 11 days to reduce plasma levels to <0.02 mg/L on two consecutive tests at least 14 days apart. Without this elimination procedure, it could take as long as 2 years to reach these plasma concentrations. Although drug levels are higher in patients with impaired renal function, it appears that leflunomide can be used cautiously in such patients. Leflunomide is not dialyzed. Screen for TB before therapy. Avoid in patients with interstitial lung disease.',
    __EMPTY_1:
      '\r\nBaseline: CBC, platelets, LFTs, creatinine. Check for hepatitis B and C infection. Maintenance: Initially or with dose increases, check CBC and LFTs every 4 weeks for 6 months; then if LFTs are stable, every 6â€“8 weeks. Co-therapy with MTX or other immunosuppressants requires more frequent monitoring (e.g., every 4 weeks). Dose reduction is indicated for minor increases in liver enzymes. For elevations more than 3x normal discontinue and treat with cholestyramine. Discontinue leflunomide in patients with persistent liver test abnormalities. Monitor blood pressure.',
    pregnancyRisk:
      'X (teratogen). Leflunomide is teratogenic in animals. Ensure that women are not pregnant before starting treatment and ensure reliable contraception during treatment. When women of childbearing age discontinue leflunomide treatment, use the cholestyramine protocol described under precautions to speed elimination of the drug.',
    commonSideEffects:
      '\r\nCommon: Diarrhea, GI cramps\r\nLess common: Hypertension, hair loss, rash, nausea, vomiting, weight loss, minor LFT abnormalities\r\nUncommon: Allergy, interstitial pneumonitis, leukopenia, peripheral neuropathy, increased risk of infection, reactivation of TB\r\nRare: Stevens-Johnson syndrome or toxic epidermal necrolysis, liver failure, agranulocytosis, pancytopenia',
    commonInteractions:
      'Drug Interactions\r\nRifampin: Increased concentrations of the active form of leflunomide\r\nWarfarin: Case reports of increased warfarin effect\r\nActivated charcoal and cholestyramine: Enhance elimination of the drug by interfering with enterohepatic recycling\r\nTofacitinib: Co-therapy contraindicated\r\nTeriflunomide: Is the active metabolite of leflunomide; avoid co-therapy\r\nMethotrexate and other hepatotoxic and hemotoxic drugs: Increased toxicity',
    patientInstructions: ' Do not become pregnant. Do not drink alcohol.',
    comments:
      'Leflunomide is as effective as MTX for RA and can be useful for patients who cannot tolerate MTX. Its long half-life and teratogenicity make it less suitable for women of childbearing age. GI symptoms limit tolerability. The combination of leflunomide and MTX is more effective than monotherapy but carries a greater risk of liver toxicity and careful monitoring is needed. The loading dose is usually omitted in clinical practice to improve GI tolerability. Minor toxicities can be managed by holding or lowering the dose and brief use of cholestyramine 8 g three times daily for 1â€“2 days to lower plasma concentrations by nearly 50%. Serious toxicities require drug cessation and a full-dose drug elimination (see precautions). Leflunomide reduces uric acid.',
    clinicalPharmacology:
      'Well absorbed and rapidly converted to the active metabolite (A77 1726, teriflunomide). Hepatic metabolism with enterohepatic recycling. Half-life 15 days.',
    price: '$$$$',
    citation:
      'Behrens F, Koehm M, Burkhardt H. Update 2011: leflunomide in rheumatoid arthritis - strengths and weaknesses. Curr Opin Rheumatol. 2011;23:282-7. PMID: 21427581.',
  },
  {
    name: 'Meclofenamate Sodium\r\n',
    brandNames: 'Generic',
    drugClass: 'NSAID',
    __EMPTY: '50- and 100-mg capsules',
    dosage: '200â€“300 mg/day in three to four divided doses',
    clinicalPharmacology:
      'Well absorbed after oral administration; hepatic metabolism and renal excretion of metabolites; analgesic effect lasts 4â€“6 hours.',
    price: '$$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
  },
  {
    name: 'Meloxicam\r\n',
    brandNames: 'Mobic',
    drugClass: 'NSAID',
    __EMPTY: '7.5- and 15-mg tablets and capsules; 7.5 mg/5mL oral suspension',
    dosage: '7.5â€“15 mg once daily',
    comments: 'Compared with traditional, older NSAIDs, somewhat COX-2 â€œpreferentialâ€',
    clinicalPharmacology:
      'Hepatic metabolism and renal excretion of metabolites; half-life 15 hours',
    price: '$$$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
  },
  {
    name: 'Meperidine\r\n',
    brandNames: 'Demerol',
    drugClass: 'Narcotic analgesic, opioid',
    synonyms: '\r\nSynonyms: Meperidine hydrochloride, pethidine',
    __EMPTY:
      '\r\nTablets: 50- and 100 mg\r\nSyrup: 50 mg/5 mL (500 mL)\r\nInjection: 10-, 25-, 50-, 100 mg/mL',
    dosage:
      'Oral, intramuscular, or subcutaneous: 50â€“100 mg/dose every 3â€“4 hours (50â€“75 mg of meperidine is roughly equivalent to 10 mg morphine). The oral dose is less potent than the intramuscular dose.',
    indications:
      'Pain not controlled by nonopioid drugs. Morphine is generally preferred. Do not use to treat chronic pain.',
    mechanismOfAction: 'Binds to opioid receptors in the CNS',
    contraindications:
      'Hypersensitivity to meperidine, substance abuse, patients receiving MAOIs in the previous 14 days, renal failure',
    precautions:
      'Use caution in patients with hypersensitivity to other opioids, seizure disorder, respiratory disease, renal or hepatic impairment. Decrease the dose if there is hepatic or renal impairment. The metabolite normeperidine accumulates in patients with impaired renal function and causes CNS stimulation, anxiety, tremors and seizures. Meperidine is not suitable for long-term use. Use the lowest dose necessary to control pain. Escalate dose only with uncontrolled pain. Avoid in the elderly.',
    __EMPTY_1: 'Monitor blood pressure and respiration if used parenterally.',
    pregnancyRisk: 'C; D in high doses (neonatal abstinence syndrome)',
    commonSideEffects:
      '\r\nCommon: Drowsiness, constipation, dysphoria, nausea, hypotension, confusion, pruritus\r\nLess common: Rash, CNS stimulation, insomnia, twitchiness, seizures, respiratory depression, dependence, histamine release',
    commonInteractions:
      'Drug Interactions\r\nCNS depressants: Increased toxicity\r\nMAOIs: Avoid because risk of serious potentially fatal reactions including hypertension, seizures, coma\r\nSSRIs: Avoid fluoxetine and other drugs in this class because of increased toxicity of meperidine\r\nCimetidine: Increased meperidine levels',
    patientInstructions:
      ' Drug is a narcotic and addictive. Use only to control pain. Do not use with alcohol or other CNS depressants. Impairs driving and ability to operate machinery.',
    comments:
      'Meperidine offers little advantage over morphine and has a worse adverse effect profile, particularly the increased risk of seizures due to normeperidine. Most pain authorities recommend against its use.',
    clinicalPharmacology:
      'Onset of action within 10 minutes, duration of effect is 2â€“4 hours, hepatic metabolism, urinary elimination as metabolites. Half-life of meperidine is 3â€“4 hours, but that of the toxic metabolite normeperidine is 15â€“30 hours and depends on renal function.',
    __EMPTY_3:
      'Cummings SR, San Martin J, McClung MR, et al. Denosumab for prevention of fractures in postmenopausal women with osteoporosis. N Engl J Med 2009;361:756-65. PMID: 19671655.',
  },
  {
    name: 'Mesna\r\n',
    brandNames: 'Mesnex',
    drugClass: 'Thiol compound',
    __EMPTY: 'Injection, 100 mg/mL (10 mL); tablet, 400 mg',
    dosage:
      'Dose\r\nIntravenous: Administer mesna to a total of 60% of the cyclophosphamide dose (milligram for milligram) divided into several doses, given 15 minutes before cyclophosphamide and 4 and 8 hours after cyclophosphamide.\r\nOral: Administer mesna equivalent to 40% of the cyclophosphamide dose (milligram for milligram) 2 hours before cyclophosphamide and two additional doses at 3- to 4-hour intervals after cyclophosphamide (total dose, 120% of cyclophosphamide dose). Intravenous mesna is recommended if patient vomits.',
    indications: 'Prophylaxis of hemorrhagic cystitis caused by cyclophosphamide or ifosfamide',
    mechanismOfAction:
      'Binds and detoxifies with urotoxic metabolites such as acrolein (seen with cyclophosphamide therapy)',
    contraindications: 'Hypersensitivity to mesna',
    pregnancyRisk: 'B',
    commonSideEffects:
      '\r\nCommon: Bad taste, headache, diarrhea, nausea, musculoskeletal pain\r\nUncommon: Allergic rash, hives',
    comments:
      'Most rheumatologists prefer intravenous protocols to oral cyclophosphamide because there is less hemorrhagic cystitis. Some rheumatologists use mesna regularly. Others, because the doses of cyclophosphamide used in rheumatology are relatively low compared to those used in oncology, reserve it for patients experiencing hemorrhagic cystitis with intravenous or oral cyclophosphamide therapy. There is little evidence-based data to inform decisions about mesna in rheumatology. Mesna tablets are expensive compared to an equivalent dose of the IV preparation. Thus, the IV preparation has been used orally, but because it has an unpleasant smell and taste it has been mixed with a carbonated drink or juice.',
    clinicalPharmacology:
      'Peak plasma levels 2â€“3 hours after oral administration; rapidly oxidized in the blood to mesna disulfide; excreted by glomerular filtration. Half-life of mesna disulfide is 1.2 hours.',
    price: '$$$',
  },
  {
    name: 'Methocarbamol\r\n',
    brandNames: 'Robaxin',
    drugClass: 'Skeletal muscle relaxant',
    __EMPTY: '\r\nTablets: 500- and 750 mg\r\nInjection: 100 mg/mL (10 mL)',
    dosage:
      'Oral: between 2 and 4 g/day in three to six divided doses (the intravenous preparation is rarely indicated in the treatment of musculoskeletal disorders)',
    indications: 'Treatment of painful muscle spasm; fibromyalgia',
    mechanismOfAction: 'Reduces spinal nerve traffic to skeletal muscle',
    contraindications: 'Hypersensitivity to methocarbamol, renal impairment',
    precautions: 'Use caution in hepatic and renal dysfunction and patients with seizures.',
    __EMPTY_1:
      'Adverse Effects\r\nCommon: Dizziness, drowsiness (although methocarbamol is less sedating than other muscle relaxants)\r\nUncommon: Allergy, flushing, rash, nausea, leukopenia. Urine may turn dark when left to stand.',
    pregnancyRisk: 'C',
    commonSideEffects: 'Drug Interactions\r\nIncreased toxicity of CNS depressants',
    patientInstructions: ' Avoid alcohol; may cause drowsiness',
    comments:
      'Nighttime sedative effect is sometimes useful in improving sleep in fibromyalgia. Little high-quality data to support or guide use.',
    clinicalPharmacology:
      'Rapid oral absorption; hepatic metabolism; renal excretion. Half-life is 1â€“2 hours.',
    price: '$$',
  },
  {
    name: 'Methotrexate\r\n',
    description:
      'An antineoplastic agent used the treatment of a wide variety of cancers as well as severe psoriasis, severe rheumatoid arthritis, and juvenile rheumatoid arthritis.',
    brandNames: 'Metoject, Nordimet, Otrexup, Rasuvo, Reditrex, Trexall, Xatmep',
    drugClass:
      'Antimetabolite and cytotoxic (at high doses); DMARD, anti-inflammatory (at low doses)',
    synonyms: '\r\nSynonyms: MTX',
    __EMPTY:
      'Tablet: generic and Rheumatrex 2.5 mg; Trexall 5-, 7.5-, 10-, 15 mg ; Injection: 25 mg/mL (2 mL vial)',
    dosage:
      'MTX is typically initiated in a dose of 7.5 mg or 10 mg once weekly (all tablets taken on the same day). Start elderly patients with 5 mg/wk. Most patients start on oral tablets. Dose may be increased after 4 weeks if clinical benefit is not achieved. If necessary, increase dose by 2.5â€“5 mg (one to two tablets) every 4â€“8 weeks to 15 mg/wk. Higher doses are occasionally used in RA (to as much as 20 or 25 mg/wk). Absorption of oral doses above 15 mg plateaus; consider subcutaneous injection for doses above 15 mg. Doses of 20â€“25 mg/wk may be necessary to control cutaneous psoriasis or inflammatory muscle disease.\r\n\r\nIf nausea, diarrhea, or mucositis occurs, the clinician has several options: (a) lower the dose or temporarily suspend therapy (for 1â€“2 weeks); (b) divide the total weekly dose and administer two doses at 12-hour intervals; (c) premedicate with antiemetics (e.g., promethazine); or (d) switch to weekly intramuscular or subcutaneous injections for an improved side effect profile and more reliable absorption (patients can be taught to self-administer weekly subcutaneous injections); (e) increase dose of folate to 2 mg/day.\r\nThe injectable form is 80%â€“90% cheaper than the tablets and is the preferred alternative for those of limited financial resources. The injectable (parenteral) form may be administered intramuscularly, subcutaneously, or orally (diluted in water or fruit juice). Patients must be taught how to draw up the proper amount of parenteral MTX accurately. All patients should receive concomitant folate (1 mg/day) to lessen the incidence of serious toxicities, especially hepatic, pulmonary, and marrow toxicity.',
    administration: 'Oral, Injection',
    indications:
      'Rheumatoid arthritis, psoriasis, psoriatic arthritis, juvenile Rheumatoid arthritis, inflammatory myositis, maintenance in vasculitis (after control achieved with cyclophosphamide or rituximab), SLE',
    mechanismOfAction:
      'The cytotoxic action is based on inhibition of dihydrofolate reductase by MTX, but this does not appear to be the mechanismOfAction with the doses typically used in rheumatic diseases. Thus, concomitant folate administration will not negate the clinical effects of MTX. Postulated mechanisms underlying its anti-inflammatory effects include inhibition of methylation reactions and increased adenosine release.',
    contraindications:
      'Hypersensitivity to MTX, liver disease, alcoholism, pregnancy, renal impairment, hepatitis B or C. Most rheumatologists avoid its use in patients with marked leukopenia or HIV infection.',
    precautions:
      'The patient must understand the risks and benefits of treatment and the requirement for monitoring. Pregnant women must not receive MTX. Contraceptive methods should be reviewed and ensured (in both men and women) before starting MTX therapy, during MTX therapy, and for at least 3 months after discontinuing MTX. Patients should wait at least 3 months after discontinuing MTX before becoming pregnant. MTX toxicity is more common in those with renal impairment, Down syndrome, or folate deficiency and those receiving high-dose aspirin (4â€“6 g/day). Elimination reduced if ascites or pleural effusions.',
    __EMPTY_1:
      '\r\nBaseline: CBC, platelets, LFTs, creatinine, chest x-ray (within the past year). Check for hepatitis B and C infection and tuberculosis; if at risk, exclude human immunodeficiency virus infection.\r\nMaintenance: CBC and creatinine 2 weeks after initiating treatment; then CBC, LFTs (including albumin, AST, ALT), and creatinine every 4 weeks until efficacy and stable dose are achieved. Patients on a stable dose with no previous WBC or LFT abnormalities may have the laboratory testing interval gradually increased to every 4â€“8 weeks. Dose reduction is indicated for minor increases in liver enzymes. Check whether the patient is receiving other hepatotoxic agents (e.g., alcohol, NSAIDs). If minor elevations persist, a liver biopsy or discontinuation of MTX should be considered. Detailed guidelines on monitoring MTX use in RA have been established and are outlined in Table 2.',
    pregnancyRisk:
      'X (teratogen). Ensure that women are not pregnant before starting treatment, ensure reliable contraception during treatment, and avoid conception for at least 3 months after discontinuing MTX.',
    commonSideEffects:
      '\r\nCommon: Oral ulcers, post-dose (1â€“2 days) nausea or diarrhea\r\nLess common: Worsening of rheumatoid nodules (MTX nodulosis), fatigue, somnolence, photosensitivity, reversible hair loss, vomiting, increased LFTs\r\nUncommon: Pneumonitis (MTX lung), impotence, bone marrow suppression (primarily leukopenia or pancytopenia), increased risk of infection, irreversible hepatic fibrosis, lymphoma (sometimes reversible on stopping MTX), Stevens-Johnson syndrome',
    commonInteractions:
      'Drug Interactions\r\nTrimethoprim/sulfamethoxazole: Increased marrow toxicity of MTX\r\nImmunosuppressants: Additive toxicities\r\nNSAIDs: Minor increases in MTX levels (seldom of clinical significance with RA doses of MTX)\r\nProbenecid: Increased risk of MTX toxicity\r\nPenicillins: Increased MTX concentrations\r\nHepatotoxic drugs: Increased hepatotoxicity',
    patientInstructions:
      'American College of Rheumatology Ad Hoc Committee on Clinical Guidelines. Guidelines for\r\nmonitoring drug therapy in rheumatoid arthritis. Arthritis Rheum 1996;39:723â€“731.PMID: 8639168',
    comments:
      'patientInstructions: Take MTX only once weekly. Never increase the dose by yourself. Do not become pregnant. Do not drink alcohol. Avoid prolonged exposure to direct sunlight.',
  },
  {
    name: 'Milnacipran\r\n',
    brandNames: 'Savella',
    drugClass: 'Antidepressant, serotononin/norepinephrine reuptake inhibitor (SNRI)',
    __EMPTY: '12.5-, 25-, 50-, 75-, 100-mg tablets',
    dosage:
      'administration with food and a slow flexible dose escalation over several weeks may reduce nausea and improve tolerability. For example, initially, 12.5 mg daily for 3 days, 12.5 mg twice daily for 4 days, 25 mg twice daily for 7 days, 37.5 mg twice daily for 7 days and then 50 mg twice daily thereafter. Maximum dose is 200 mg a day.',
    indications: 'Fibromyalgia',
    mechanismOfAction: 'Increases synaptic concentrations of serotonin and norepinephrine',
    contraindications:
      'Hypersensitivity. Avoid for at least 14 days after patient has received an MAOI. Avoid in patients with uncontrolled narrow-angle glaucoma.',
    precautions:
      'May increase suicidality, particularly in children and adolescents. Risk of serotonin syndrome. Seizures reported. Avoid with hepatic disease. May elevate blood pressure and heart rate. If discontinuing, reduce dose gradually over weeks or months to prevent withdrawal symptoms. Can exacerbate prostatism.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Nausea, constipation, headache, hot flashes, sweating, dizziness, palpitations, tachycardia, insomnia, difficulty with urination, dry mouth, weight loss\r\nLess common: Hypertension, mild increase in LFTs, withdrawal syndrome with abrupt discontinuation (nausea, vomiting, diarrhea, anxiety, dizziness, sleep disturbances),seizures\r\nRarely: Inappropriate ADH, hyponatremia, increased bleeding, fulminant hepatitis',
    commonInteractions:
      'Drug Interactions\r\nAlcohol: Increased risk of elevated LFTs\r\nMAOIs and serotonergic drugs: Risk of serotonin syndrome with concomitant use with other serotonergic drugs including triptans, tricyclic antidepressants, fentanyl, lithium,tramadol, tryptophan, buspirone and St. Johnâ€™s Wort, and with drugs that impair metabolism of serotonin such as MAOIs and linezolid and methylene blue.\r\nCatecholamines: Increased risk of arrhythmia and hypertension\r\nClonidine: Antihypertensive effect is attenuated',
    patientInstructions:
      ' Avoid alcohol use. Increased risk of suicide; contact physician for suicidal thoughts or change in behavior. Patients should be advised of the risk factors for serotonin syndrome and symptoms that may include mental changes (e.g., agitation, hallucinations, delirium), increased heart rate and labile blood pressure, dizziness, sweating, flushing, fever, tremor, rigidity, nausea, vomiting, diarrhea. Monitor blood pressure. Slow taper for discontinuation to prevent withdrawal symptoms.',
    comments:
      'Modestly more effective than placebo in fibromyalgia clinical trials; more patients receiving milnacipran than placebo had a 30% or greater reduction in pain (44.6% vs. 30.6%) or a 50% or greater reduction in pain (27.7% vs. 18.1%). Withdrawal for adverse effects was more common with milnacipran than placebo (17.8% vs. 13.9%).',
    clinicalPharmacology: 'Well absorbed, 55% excreted unchanged in urine, half-life 8 hours',
    price: '$$$',
    citation:
      'Arnold LM, Gendreau RM, Palmer RH, et al. Efficacy and safety of milnacipran 100 mg/day in patients with fibromyalgia: results of a randomized, double-blind, placebo-controlled trial. Arthritis Rheum 2010;62:2745-56. PMID:20496365.',
  },
  {
    name: 'Minocycline\r\n',
    brandNames: 'Minocin',
    drugClass: 'Tetracycline antibiotic',
    synonyms: 'Synonyms: Minocycline hydrochloride',
    __EMPTY: '\r\nCapsules: 50- and 100 mg',
    dosage: 'Effective dose in RA is 200 mg/day (100 mg twice daily)',
    indications: 'Rheumatoid arthritis (see PDR for infectious indications and doses)',
    mechanismOfAction:
      'Unknown; RA effects are probably related to downregulation of intraarticular metalloproteinases rather than antibacterial effects.',
    contraindications:
      'Hypersensitivity to minocycline, children younger than 9 years old, pregnancy',
    precautions: 'Renal impairment',
    pregnancyRisk: 'D',
    commonSideEffects:
      '\r\nCommon: Diarrhea, nausea, photosensitivity, hyperpigmentation particularly affecting the face with long-term use, discoloration of teeth in children\r\nLess common: Allergy, rash, increased intracranial pressure, pericarditis, dysphagia, enterocolitis, drug-induced lupus, vasculitis (including polyarteritis nodosa and ANCA+ vasculitis), pseudotumor cerebri, vertigo, agranulocytosis, hepatitis, esophagitis',
    commonInteractions:
      'Drug Interactions\r\nAntacids: Decreased absorption of minocycline\r\nOral contraceptive: Decreased contraceptive efficacy\r\nWarfarin: Increased effect',
    patientInstructions: ' Avoid sunlight. Do not take with antacids or milk.',
    comments:
      'Clinical effect in RA is small; may be most suitable for patients with mild or early disease.',
    clinicalPharmacology:
      'Well absorbed after oral administration, renal elimination. Half-life is 15 hours.',
    citation:
      "O'Dell JR, Blakely KW, Mallek JA, et al. Treatment of early seropositive rheumatoid arthritis: a two-year, double-blind comparison of minocycline and hydroxychloroquine. Arthritis Rheum 2001;44:2235-41. PMID: 11665963",
  },
  {
    name: 'Misoprostol\r\n',
    brandNames: 'Cytotec, Arthrotec (misoprostol + diclofenac)',
    drugClass: 'Synthetic prostaglandin, protects against NSAID gastropathy',
    synonyms: 'Synonyms: Synthetic prostaglandin E1',
    __EMPTY: '100- and 200- mcg tablets',
    dosage:
      'Prophylaxis of NSAID-induced gastropathy 100â€“200 mcg four times daily. Efficacy best demonstrated for 200 mcg four times daily, but lower doses (200 mcg b.i.d. or 100 mcg four times daily) are better tolerated and may be nearly as effective.',
    indications: 'Prevention of NSAID-induced gastropathy (see comments)',
    mechanismOfAction:
      'Substitutes for endogenous prostaglandins (necessary to stimulate gastric mucous and bicarbonate secretion) whose synthesis is inhibited by NSAIDs',
    contraindications: 'Hypersensitivity to misoprostol, pregnancy',
    precautions:
      'Safety in children is not established. Ensure that women are not pregnant before starting treatment, and ensure reliable contraception while receiving misoprostol.',
    pregnancyRisk: 'X (abortifacient and teratogenic)',
    commonSideEffects:
      '\r\nCommon: Diarrhea, abdominal cramps\r\nLess common: Headaches, vaginal bleeding, miscarriage',
    patientInstructions:
      ' Do not consider becoming or become pregnant while on misoprostol. Need reliable contraception May cause diarrhea. Take with food. Do not give drug to others.',
    comments:
      'Misoprostol reduces serious GI complications caused by NSAIDs by 40%. Strategies to reduce risk should be considered in patients at high risk of NSAID-induced GI complications (those with previous GI bleeding or previous peptic ulcer, the elderly, those on combination NSAID and corticosteroid therapy) if therapy with an NSAID is necessary. Proton pump inhibitors are better tolerated. Prophylaxis in unselected NSAID users is not cost-effective. Misoprostol does not prevent GI pain (indigestion, dyspepsia) associated with NSAID use.',
    clinicalPharmacology:
      'Rapidly absorbed and metabolized to the free acid. Plasma concentrations are highest within 30 minutes of dosing. Half-life is 20â€“40 minutes.',
    price: '$$$',
    citation:
      'Graham DY, Agrawal NM, Campbell DR, et al. Ulcer prevention in long-term users of nonsteroidal anti-inflammatory drugs: results of a double-blind, randomized, multicenter, active- and placebo-controlled study of misoprostol vs lansoprazole. Arch Intern Med 2002;162:169-75. PMID: 11802750.',
  },
  {
    name: 'Mycophenolate\r\n',
    brandNames: 'CellCept',
    drugClass: 'Immunosuppressant',
    synonyms: 'Synonyms: Mycophenolate mofetil',
    __EMPTY: '\r\nTablet: 500 mg\r\nCapsule: 250 mg',
    dosage:
      'In lupus nephritis usually started at 500 mg twice daily for a week and, if tolerated, increased to 1 g twice daily for a week and then increased to a target of 1.5 g twice daily. For maintenance therapy after remission lower doses (2 g/day) are used.',
    indications: 'Treatment of SLE, vasculitis, inflammatory muscle disease',
    mechanismOfAction:
      'Inosine monophosphate dehydrogenase inhibitor, inhibits guanine and de novo purine synthesis, inhibits T and B lymphocyte proliferation',
    contraindications:
      'Hypersensitivity to mycophenolate or mycophenolic acid, pregnancy, avoid live virus vaccines',
    precautions:
      'The patient must understand the risks and benefits of treatment and the requirement for monitoring. Pregnant women must not receive mycophenolate. Contraceptive methods should be reviewed and be in place before starting therapy. Because mycophenolate may affect the efficacy of estrogen contraceptives, two methods of contraception are recommended. Avoid live virus vaccines.',
    __EMPTY_1:
      '\r\nCBC, platelets, LFTs, creatinine at baseline; check CBC every 2 weeks till stable and and once stable CBC and LFTs every 4â€“8 weeks.',
    pregnancyRisk: 'D; increased congenital malformations',
    commonSideEffects:
      '\r\nCommon: Nausea, GI cramps, diarrhea, headache\r\nLess common: Vomiting, leukopenia, neutropenia, pure red cell aplasia, elevated liver enzymes, hypertension,edema, rash, hyperglycemia, tremor, increased risk of infection, skin cancer, herpes zoster, lymphoma, reactivation of hepatitis B or C, gastrointestinal perforation or bleeding',
    commonInteractions:
      'Drug Interactions\r\nAntacids and cholestyramine: Decreased serum concentrations\r\nAzathioprine: Increased toxicity, avoid this combination\r\nRifampin: Decreased serum concentrations\r\nOral contraceptives: Decreased contraceptive efficacy',
    patientInstructions:
      ' Never increase the dose by yourself. Do not become pregnant. Protect skin from the sun.',
    comments:
      'Appears to be as effective as cyclophosphamide for induction therapy and more effective than azathioprine for maintenance therapy of lupus nephritis with a better side effect profile. In ANCA-associated vasculitis less effective than azathioprine in maintaining remission.',
    clinicalPharmacology:
      'Rapidly and completely absorbed, rapidly de-esterified to mycophenolic acid, the active drug, which is glucuronidated and excreted in the urine. Plasma half-life of mycophenolic acid is 16 hours.',
    citation:
      'Hahn BH, McMahon MA, Wilkinson A, et al. American College of Rheumatology guidelines for screening, treatment, and management of lupus nephritis. Arthritis Care Res 2012;64:797-808. PMID: 22556106',
  },
  {
    name: 'Nabumetone\r\n',
    brandNames: 'Relafen',
    drugClass: 'NSAID',
    __EMPTY: '500- and 750-mg tablets',
    dosage:
      '1,000 mg/day in a single or divided doses. Dose may be increased to 1,500â€“2,000 mg/day in divided doses.',
    clinicalPharmacology:
      'Well absorbed after oral administration. Nabumetone is an inactive prodrug that is metabolized to the 6-methoxy-2-naphthyl acetic acid. Hepatic metabolism. Half-life is 20â€“30 hours (even longer in the elderly).',
    price: '$$$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
  },
  {
    name: 'Naproxen\r\n',
    brandNames: 'Aleve (OTC), Anaprox, EC-Naprosyn, Naprelan, Naprosyn',
    drugClass: 'NSAID',
    synonyms: 'Synonyms: Naproxen sodium',
    __EMPTY:
      '\r\nTablets: 250, 375, 500 mg\r\nTablets (enteric coated): 375 and 500 mg\r\nTablets, as sodium (Anaprox): 275 mg (250 mg base), 550 mg (500 mg base).\r\nOTC: 220 mg (200 mg base)\r\nOral suspension: 125 mg/5 mL',
    dosage: '500â€“1,000 mg/day in two divided doses',
    clinicalPharmacology:
      'Well absorbed after oral administration; hepatic metabolism; renal elimination. Half-life is 13 hours.',
    price: 'OTC (low dose), $; generic, $$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
  },
  {
    name: 'Nitroglycerin Ointment\r\n',
    brandNames: 'Nitro-Bid Ointment',
    drugClass: 'Nitro vasodilator 1-, 30-, 60 g',
    synonyms: 'Synonyms: Nitropaste, NTG, glyceryl trinitrate',
    __EMPTY: '2% ointment',
    dosage:
      'For angina, 1/2 inch twice daily initially, increased to 1 inch twice daily if needed with a 12 hour nitrate free interval. For digital ulcers, smaller amounts are applied to the fingertips.',
    indications: 'Angina, severe Raynaudâ€™s syndrome with digital ulceration',
    mechanismOfAction:
      'Nitric oxide donor. Nitric oxide acts through cyclic guanosine monophosphate to relax vascular smooth muscle.',
    contraindications:
      'Hypersensitivity to nitroglycerin, concommitant use of sildenafil (Viagra) or other phosphodiesterase-5 inhibitors',
    pregnancyRisk: 'C',
    commonSideEffects: '\r\nCommon: Flushing, headache, dizziness, rash',
    comments: 'Small amounts on tips of fingers tried for digital ulcers. Efficacy is uncertain.',
    clinicalPharmacology:
      'Systemic absorption occurs transdermally, and this preparation is used to treat angina. Duration of action is 10â€“12 hours.',
    price: '$',
  },
  {
    name: 'Nonsteroidal Antiinflammatory Drugs (NSAIDs)',
    indications:
      'Rheumatoid arthritis, juvenile idiopathic arthritis, osteoarthritis, ankylosing spondylitis, spondyloarthropathy, gout, pain',
    mechanismOfAction:
      'Anti-inflammatory and antiplatelet properties are mediated by the inhibition of COX enzymes and decreased production of prostaglandins. Drugs that inhibit COX-2 are anti-inflammatory because they decrease the formation of prostaglandins by activated cells. Drugs that inhibit COX-1 decrease the production of thromboxane and thus decrease platelet activation. Because COX-1 contributes to the maintenance of gastric mucosa, blocking COX-1 increases the risk of peptic ulceration. NSAIDs are classified according to whether they inhibit both COX-1 and COX-2 (nonselective NSAIDs) or whether they are more selective for COX-2 and spare COX-1 (COX-2 selective NSAIDs or coxibs). Nonacetylated salicylates such as salsalate are weak inhibitors of COX, and their mechanismOfAction is poorly understood.',
    contraindications:
      'Hypersensitivity to NSAIDs, GI ulceration, hemorrhagic state, and last trimester of pregnancy (risk of premature closure of ductus arteriosus), severe renal impairment, heart failure, hyperkalemia,postoperative pain after CABG surgery',
    precautions:
      'Increased risk of MI and stroke. Fluid retention may aggravate heart failure and hypertension. Use with caution or avoid in patients at high risk of GI bleeding (i.e., previous GI bleeding, peptic ulcer, elderly, concurrent corticosteroid or warfarin treatment); if benefit outweighs risk, a nonselective NSAID with a proton pump inhibitor or misoprostol prophylaxis or a COX-2 selective drug should be used. Administer with food. Use with caution in asthma, bleeding disorders, and hepatic or renal disease. Can aggravate hypertension. Caution in elderly â€“ increased risk of side effects.',
    __EMPTY_1:
      'Monitor hematocrit, creatinine, liver enzymes periodically (e.g., 1 month after starting and then every 3â€“6 months). In patients at high risk of renal impairment (diuretics, receiving ACE inhibitors, edematous states, heart failure, renal failure, diabetes), monitor renal function more closely after starting treatment.',
    pregnancyRisk:
      'Most NSAIDs category C, but category D in third trimester\r\nArthrotec-category X because of misoprostol',
    commonSideEffects:
      '\r\nCommon: GI irritation (dyspepsia, reflux, epigastric pain), rash, fluid retention, hypertension\r\nLess common: GI ulceration, hemorrhage, gastric outlet obstruction, hepatitis with elevations of liver enzymes, hypersensitivity (anaphylaxis, asthma, urticaria, angioedema) particularly in patients with nasal polyps, exfoliative dermatitis, hematologic toxicity (agranulocytosis, anemia, leukopenia, thrombocytopenia), renal toxicity (interstitial nephritis, proteinuria, nephrotic syndrome, acute renal failure, hyperkalemia), CNS toxicity (dizziness, drowsiness, insomnia, nervousness), CV toxicity (stroke, MI), tinnitus, aseptic meningitis (particularly in SLE)',
    commonInteractions:
      'Drug Interactions\r\nAnticoagulants: Increased hemorrhagic risk with anticoagulants and thrombolytics.\r\nNSAIDs: Increased risk of GI side effects if combinations of NSAIDs used.\r\nAspirin: A nonselective NSAID such as ibuprofen taken before aspirin blunted the long-term antiplatelet effects of aspirin perhaps because ibuprofen blocked the access of aspirin to its binding site on the COX-1 enzyme. If patients took aspirin first and then ibuprofen, the long-term antiplatelet effects of aspirin were not altered. A COX-2 selective drug did not affect the antiplatelet effect of aspirin, irrespective of whether it was taken before or after aspirin.\r\nMethotrexate: Increased levels of MTX with many NSAIDs, but with the MTX doses used in RA, usually not of clinical importance.\r\nCyclosporine: May increase risk of nephrotoxicity.\r\nDiuretics: Decreased effects of thiazides and furosemide, increased renal toxicity with diuretics, increased risk of hyperkalemia with K+-sparing diuretics.\r\nLithium: Increased lithium levels.\r\nAntihypertensive agents: Antihypertensive effect reduced.\r\nWarfarin, novel anticoagulants and antiplatelet agents: Increased risk of bleeding.',
    patientInstructions:
      ' Take with food. Discontinue and seek medical advice if unusual bleeding occurs.',
    comments:
      'See individual NSAIDs for additional information. Most NSAID side effects are class-effects. Consider risk-benefit ratio before prescribing. Use lowest effective dose and shortest duration of therapy. The increased CV risk appears smaller with naproxen than other NSAIDs. Prophylax against GI ulcers and bleeds (usually with a PPI) in high-risk patients who cannot do without an NSAID.',
  },
  {
    name: 'Orphenadrine Citrate\r\n',
    brandNames: 'Norflex',
    drugClass: 'Muscle relaxant',
    __EMPTY: '\r\nTablet: 100 mg',
    dosage: '100 mg twice daily',
    indications: 'Treatment of muscle spasm',
    mechanismOfAction:
      'Central atropine-like action is thought to induce skeletal muscle relaxation.',
    contraindications:
      'Hypersensitivity to orphenadrine, myasthenia gravis, bowel obstruction, glaucoma, prostatism',
    precautions: 'Use caution with cardiac arrhythmias, may impair ability to operate machinery',
    __EMPTY_1:
      'Adverse Effects\r\nCommon: Drowsiness, blurred vision, dizziness\r\nLess common: Tachycardia, rash, anticholinergic effects, nausea, constipation, flushing, confusion',
    pregnancyRisk: 'C',
    commonSideEffects:
      'Drug Interactions\r\nAnticholinergics: Additive anticholinergic effects\r\nCNS depressants: Additive CNS depression',
    patientInstructions: ' May cause drowsiness. Avoid alcohol.',
    clinicalPharmacology: 'Hepatic metabolism and renal excretion. Half-life is 14â€“16 hours.',
    price: '$$$',
  },
  {
    name: 'Oxaprozin\r\n',
    brandNames: 'Daypro',
    drugClass: 'NSAID',
    __EMPTY: '600-mg tablet',
    dosage:
      '600â€“1,200 mg/day once daily. Do not exceed maximum dose of 1,800 mg/day or 26 mg/kg (whichever is lower).',
    clinicalPharmacology: 'Well absorbed after oral administration. Half-life is 40â€“50 hours.',
    price: '$$$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
  },
  {
    name: 'Oxycodone\r\n',
    brandNames: 'Roxicodone, Oxycodone immediate release (generic), Oxycontin (extended release)',
    drugClass: 'Narcotic analgesic',
    __EMPTY:
      '\r\nTablets (immediate release): 5-, 10-, 15- , 20-, 30 mg\r\nTablets (controlled release): 10-, 15-, 20-, 30-, 40-, 60-, 80 mg\r\nOral suspension: 5 mg/5 mL',
    dosage:
      'immediate release: 5â€“10 mg three to four times daily; extended release: if opioid naive, 10 mg every 12 hours initially; if already on immediate release, divide total daily dose in half and administer every 12 hours sustained release.',
    indications:
      'Pain not controlled by nonopioid drugs; extended release preparations are only indicated for patients with severe pain requiring round-the-clock pain control and should not be used as needed.',
    mechanismOfAction: 'Binds to opioid receptors in CNS',
    contraindications: 'Hypersensitivity to oxycodone, substance abuse, respiratory depression',
    precautions:
      'Use caution in patients with hypersensitivity to other opioids, respiratory disease, or renal or hepatic impairment. Decrease dose with hepatic or renal impairment. Affects ability to operate machinery. May cause fatal respiratory depression. Interactions with CYP3A4 inhibitors.',
    __EMPTY_1:
      'Use the lowest dose necessary to control pain. Escalate dose only for uncontrolled pain.',
    pregnancyRisk: 'C/D high doses (neonatal abstinence syndrome)',
    commonSideEffects:
      '\r\nCommon: Drowsiness, dizziness, constipation, dysphoria, nausea, pruritus\r\nLess common: Rash, CNS stimulation, insomnia, hypotension, addiction, respiratory depression, overdose, death',
    commonInteractions:
      'Drug Interactions\r\nCNS depressants: Increased CNS depression\r\nMAOIs: Avoid\r\nCytochrome P450 3A4 inhibitors: CYP 3A inhibitors such as ritonavir, nelfinavir, ketoconazole and other azoles, trolandeomycin, erythromycin, clarithromycin, verapamil, diltiazem and nefazodone increase oxycodone concentrations and monitoring and dose adjustments are needed',
    patientInstructions:
      ' Drug is addictive. Use only to control pain. Do not use with alcohol or other CNS depressants. Do not crush slow-release tablets.',
    comments:
      'Because of greater risks, physicians prescribing extended release oxycodone need to receive training per the REMS program instituted by the FDA http://www.purduesrems.com/',
    clinicalPharmacology:
      'Oral absorption and hepatic metabolism by CYP3A4; urinary elimination as metabolites. Half-life of immediate release is 3 hours with duration of effect 3â€“6 hours.',
    price: '$$',
  },
  {
    name: 'Pamidronate\r\n',
    brandNames: 'Aredia',
    drugClass: 'Bisphosphonate',
    __EMPTY: ': 30- and 90 mg for injection',
    dosage:
      'Dose\r\nPagetâ€™s disease: 30 mg as a dilute intravenous infusion over 4 hours once daily for 3 days\r\nHypercalcemia of malignancy: 60â€“90 mg as a slow infusion over 4â€“24 hours; may need to be repeated at 2- to 3-week intervals',
    indications: 'Hypercalcemia of malignancy, Pagetâ€™s disease',
    mechanismOfAction: 'Localizes to areas of bone resorption and inhibits osteoclast activity',
    contraindications:
      'Hypersensitivity, hypocalcemia, not recommended for patients with severe renal insufficiency (creatinine clearance <35 mL/min)',
    precautions:
      'Use caution in renal impairment. A dental exam and preventive dental work should be performed before starting bisphosphonate therapy.',
    __EMPTY_1: 'Monitor serum electrolytes periodically.',
    pregnancyRisk: 'D',
    commonSideEffects:
      '\r\nCommon: Fever, arthralgia, flu-like syndrome, fatigue, headache, nausea, vomiting, hypocalcemia, hypokalemia, hypomagnesemia, nausea, diarrhea, bone pain, dyspnea, thrombophlebitis at infusion site\r\nLess common: Rash, hypersensitivity, leukopenia, infusion reaction, atypical subtrochanteric femur fractures, osteonecrosis of the jaw, severe musculoskeletal pain, uveitis, altered taste, urticaria, angioedema, atrial fibrillation, severe hypocalcemia, impaired renal function',
    commonInteractions:
      'Drug Interactions\r\nDiuretics: Increased risk of electrolyte abnormalities',
    patientInstructions:
      'comments: Supplemental calcium and vitamin D are usually administered. Premedication and treatment with acetaminophen often used to decrease flu-like symptoms associated with infusion.',
    clinicalPharmacology:
      'Renal excretion. Plasma half-life is relatively short (24 hours), but bone half-life is much longer (months or years) indicating localization and release from bone.',
    price: '$$',
    citation:
      "Ralston SH. Clinical practice. Paget's disease of bone. N Engl J Med 2013;368:644-50. PMID: 23406029",
  },
  {
    name: 'Pegloticase\r\n',
    brandNames: 'Krystexxa',
    drugClass: 'Class: Urate-oxidase enzyme; pegylated recombinant uricase; antigout agent',
    __EMPTY: '8 mg/ml for i.v. infusion',
    dosage: '8 mg every two weeks by slow i.v. infusion over at least 120 minutes',
    indications: 'Chronic symptomatic gout refractory to conventional therapy',
    mechanismOfAction: 'A recombinant urate-oxidase that converts uric acid to allantoin',
    contraindications: 'G-6PD deficiency, hypersensitivity',
    precautions:
      'Screen for G-6PD. Anaphylaxis and infusion reactions occur, administer under close supervision in a health care facility with ability to treat these. Premedicate with antihistamine and corticosteroid. Infusion reactions can be delayed â€“ observe patient for 1-2 hours after infusion. Risk of infusion reaction with first and subsequent infusions; with subsequent infusions risk is greater in patients whose uric acid is more than 6 mg/dL. Delayed hypersensitivity reactions can occur. Monitor uric acid before repeat infusions and consider discontinuing treatment if uric acid more than 6 mg/dL (likely indicates high-titre antibodies). Treatment causes gout flares â€“ prophylaxis should be used.',
    __EMPTY_1:
      'Monitor uric acid level before next infusion; usually decreases to less than 1 mg/dL. A lack of response after an initial response usually means that anti-pegloticase antibodies have formed and an increased risk of severe allergy.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Infusion reactions (26%), formation of anti-pegloticase antibodies (90%), rash, pruritus, nausea, gout flare (40-67% even with colchicine or NSAID treatment)\r\nLess common: Anaphylaxis (6.5%), exacerbation of heart failure',
    commonInteractions:
      'Drug Interactions\r\nUrate lowering therapies including allopurinol and febuxostat should be discontinued prior to the use of pegloticase and should not be initiated during pegloticase therapy.',
    patientInstructions: ' Allergy is common and can be serious; gout flares are common',
    comments:
      'An extremely expensive but very effective therapy usually reserved for patients with tophaceous gout refractory to conventional therapy. Tophi can decrease in size rapidly. Acute attacks of gout are frequent. Serious allergy is a problem. Optimal duration of therapy and safety of a repeated course after a drug-free interval are not defined.',
    clinicalPharmacology:
      'Rapid onset of action, marked reduction in uric acid within 24 hours; half-life 14 days',
    price: '$$$$$',
    citation:
      'Sundy JS, Baraf HS, Yood RA, et al Efficacy and tolerability of pegloticase for the treatment of chronic gout in patients refractory to conventional treatment: two randomized controlled trials. JAMA 2011;306:711-20. PMID: 21846852.',
  },
  {
    name: 'Penicillamine\r\n',
    brandNames: 'Cuprimine, Depen',
    drugClass: 'Chelating agent, DMARD',
    synonyms: 'Synonyms: D-Penicillamine',
    __EMPTY: '\r\nCapsules: 250 mg\r\nTablet: 250 mg (scored)',
    dosage:
      'For RA (obsolete), 125-250 mg daily initially. Increase by 125-mg increments at 1- to 3-month intervals until usual maintenance dose of 375â€“750 mg/day is reached. Do not exceed maximum dose of 1 g/day.',
    indications:
      'Rheumatoid arthritis, scleroderma, primary biliary cirrhosis, Wilsonâ€™s disease, cystinuria, lead poisoning',
    mechanismOfAction:
      'In RA, mechanism is unknown but may be related to inhibition of T cell function.',
    contraindications: 'Hypersensitivity to penicillamine, renal impairment, pregnancy',
    precautions:
      'Penicillamine has a high frequency of adverse effects, and most patients experience an adverse drug reaction.',
    __EMPTY_1:
      'CBC, differential, platelets, and urinalysis (for protein) should initially be done within 1â€“2 weeks and then monthly until therapeutic effect and a stable dose are achieved. The frequency of laboratory testing may be changed to every 4â€“8 weeks if the laboratory indices remain stable on repeat testing.',
    pregnancyRisk: 'D',
    commonSideEffects:
      '\r\nCommon: Rash, hives, itching, altered (metallic) taste, proteinuria, arthralgia\r\nLess common: Fever, hematologic toxicity (agranulocytosis, thrombocytopenia, leukopenia, aplastic anemia), glomerulonephritis, myasthenia gravis, Goodpasture syndrome, optic neuritis, hepatitis, lymphadenopathy, drug-induced lupus, pemphigus, inflammatory myositis, bronchiolitis obliterans, mouth ulcers',
    commonInteractions: 'Antacids/iron/food: Significantly decreased absorption of penicillamine',
    patientInstructions:
      ' Take on an empty stomach. Altered taste may occur. Regular laboratory monitoring is required. Do not become pregnant.',
    comments:
      'Penicillamine for RA is obsolete; it has largely been replaced by methotrexate and other DMARDs. Most patients who took penicillamine for RA discontinued it within 1â€“2 years because of lack of efficacy or toxicity. Data suggesting benefits of penicillamine in scleroderma are largely based on retrospective studies. A controlled study found no difference between 125 mg on alternate days (a dose used as a substitute for placebo) and 750â€“1,000 mg/day.',
    clinicalPharmacology:
      'Absorption is 50%. When chronic treatment is stopped there is a prolonged elimination phase lasting 4-6 days. Elimination is primarily renal as unchanged drug.',
    citation:
      'Clements PJ, Furst DE, Wong WK, et al. High-dose versus low-dose D-penicillamine in early diffuse systemic sclerosis: analysis of a two-year, double-blind, randomized, controlled clinical trial. Arthritis Rheum 1999;42:1194â€“1203. PMID:10366112',
  },
  {
    name: 'Phenylbutazone\r\n',
    brandNames: 'Formerly Butazolidin',
    drugClass: 'NSAID, human use in US discontinued',
    __EMPTY: '100-mg tablet',
    dosage: '100 mg three to four times daily',
    indications: 'Ankylosing spondylitis refractory to other treatment',
    comments:
      'Phenylbutazone for humans is no longer available commercially in the US. It is not recommended for human use because of hematologic toxicity (agranulocytosis, thrombocytopenia). Some rheumatologists believe it can be useful for some patients with ankylosing spondylitis when other NSAIDs and treatments have failed to control symptoms. The potential risks and benefits must be carefully evaluated with the patient before prescribing phenylbutazone.',
    clinicalPharmacology:
      'Rapid absorption; hepatic metabolism; renal elimination. Half-life is 50â€“100 hours.',
    price: 'Not available',
    citation:
      'See NSAIDs for mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
  },
  {
    name: 'Pilocarpine\r\n',
    brandNames: 'Salagen',
    drugClass: 'Cholinergic agent',
    __EMPTY: '5- and 7.5 mg tablet',
    dosage: '5 mg three times daily; titrate to as high as 10 mg three times daily if needed',
    indications: 'xerostomia, SjÃ¶grenâ€™s syndrome',
    mechanismOfAction: 'Stimulates muscarinic receptors to increase saliva production',
    contraindications: 'Hypersensitivity to pilocarpine, uncontrolled asthma, hepatic dysfunction',
    precautions:
      'Asthma, GI ulceration, cardiovascular disease, urinary tract obstruction, gall stones',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Sweating, flushing, dizziness, headache, nausea, rhinitis, urine frequency\r\nLess common: Palpitation, rash, allergy, edema, bronchospasm',
    commonInteractions:
      'Drug Interactions\r\nBeta-blockers: Increased risk of cardiac conduction disturbances\r\nParasympathomimetics: Additive effects\r\nAnticholinergics (atropine, ipratropium): Antagonize effects',
    patientInstructions:
      ' Expect increased sweating. It may take several weeks for response to occur.',
    clinicalPharmacology: 'Half-life is 1 hour; duration of action 3â€“5 hours.',
    price: '$$$',
  },
  {
    name: 'Piroxicam\r\n',
    brandNames: 'Feldene',
    drugClass: 'NSAID',
    __EMPTY: '10- and 20-mg capsules',
    dosage: '10â€“20 mg/day as a single dose',
    clinicalPharmacology:
      'Well absorbed after oral administration. The drug undergoes hepatic metabolism and renal elimination. Half-life is 50 hours.',
    price: '$$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
  },
  {
    name: 'Plasmapheresis\r\n',
    indications:
      'Plasmapheresis can be effective in a number of rheumatologic and immunologic disorders, including Goodpasture syndrome, myasthenia gravis, Guillain-BarrÃ© syndrome, hyperviscosity syndrome (e.g.,with WaldenstrÃ¶m macroglobulinemia), cryoglobulinemia (particularly with renal involvement), thrombotic thrombocytopenic purpura, catastrophic antiphospholipid antibody syndrome (CAPS), idiopathic demyelinating polyneuropathy, rapidly progressive glomerulonephritis, and refractory autoimmune hemolytic anemia. In addition to thrombotic thrombocytopenic purpura, plasmapheresis may be of benefit in other disorders associated with microangiopathic hemolytic anemia, including hemolytic-uremic syndrome, SLE presenting with thrombotic thrombocytopenic purpuraâ€“like features, disseminated intravascular coagulation, and CAPS. Plasmapheresis may be of benefit in other conditions, e.g., SLE with transverse myelitis, alveolar hemorrhage or catastrophic conditions. However, the evidence is anecdotal at best. For SLE nephritis, several studies showed that in unselected groups of patients, plasmapheresis is of no benefit. Plasmapheresis is not indicated in Rheumatoid arthritis or dermatomyositis.',
    mechanismOfAction:
      'Mechanisms of Action: Plasmapheresis nonspecifically removes plasma constituents, including immunoglobulins and other plasma proteins. Removal of particular antibodies or immune complexes presumably underlies its effect in most diseases. In thrombotic thrombocytopenic purpura, and perhaps other conditions, some of the benefit perhaps also derives from infusion of normal plasma.',
    comments:
      'There are few adequate controlled trials in rheumatic diseases. Plasmapheresis should usually be used in conjunction with immunosuppressive therapy to prevent rebound increase in antibody production.',
    price:
      '$$$$$. An important consideration in the use of plasmapheresis is its cost, which runs upward of several thousand dollars for a treatment. Although generally well tolerated, it may also be associated with transient hypocalcemia and bleeding diathesis.',
    citation:
      'Nguyen TC, Kiss JE, Goldman JR, Carcillo JA. The role of plasmapheresis in critical illness. Crit Care Clin 2012;28:453-68. PMID: 22713617.',
  },
  {
    name: 'Pregabalin\r\n',
    brandNames: 'Lyrica',
    drugClass: 'Analgesic/anticonvulsant',
    __EMPTY: '25-, 50-, 75-, 100-, 150-, 200-, 225-, 300 mg capsules',
    dosage:
      'Fibromyalgia: 75 mg twice a day may be increased stepwise to a maximum of 225 mg twice daily (i.e., maximum daily dose 450 mg). Reduce dose for renal impairment (see label).',
    indications:
      'Fibromyalgia, diabetic neuropathy, neuropathic pain, post-herpetic neuralgia, partial-onset seizures',
    mechanismOfAction:
      'Binds to voltage-gated calcium channels in the CNS inhibiting excitatory neurotransmitter release',
    contraindications: 'Hypersensitivity',
    precautions:
      'May increase suicidality. Caution with renal impairment â€“ dose adjustment required. Increased CK and rare cases of rhabdomyolysis. May impair ability to operate machinery. Taper dose over minimum of 1 week when discontinuing. Risk of angioedema may be increased in patients receiving other drugs associated with angioedema (e.g., ACE inhibitors). Fluid retention; caution in heart failure. Weight gain.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Headache, dizziness, sleepiness, weight gain, edema, dry mouth, poor concentration, blurred vision\r\nLess common: Allergy (hives, dyspnea, wheezing), rash, ataxia, tremor\r\nRarely: Angioedema, thrombocytopenia, prolonged PR interval, rhabdomyolysis, gynecomastia',
    commonInteractions:
      'Drug Interactions\r\nAlcohol and other sedatives: Increased sedation and psychomotor effects',
    patientInstructions:
      ' Avoid alcohol use. Increased risk of suicide; contact physician for suicidal thoughts or change in behavior, swelling of face or tongue or allergic rash.',
    comments:
      'Modestly more effective than placebo in fibromyalgia clinical trials; withdrawal for adverse effects was more common with pregabalin than placebo in fibromyalgia (19% vs. 10%). Doses above 450 mg a day were not associated with greater efficacy in fibromyalgia but did result in more adverse effects.',
    clinicalPharmacology: 'Well absorbed, 90% renal excretion as unchanged drug, half-life 6 hours',
    price: '$$$$',
    citation:
      'HÃ¤user W, Walitt B, Fitzcharles MA, Sommer C. Review of pharmacological therapies in fibromyalgia syndrome. Arthritis Res Ther 2014;16:201.PMCID: 3979124.',
  },
  {
    name: 'Probenecid\r\n',
    description: 'A medication used to treat gouty arthritis, tophaceous gout, and hyperuricemia.',
    brandNames: 'Benemid',
    drugClass: 'Uricosuric',
    __EMPTY: '500-mg tablet',
    dosage:
      'Initially, 250 mg twice daily for the first week, then increase to 500 mg twice daily, and, if needed, to a maintenance dose of 1â€“2 g/day in divided doses',
    administration: 'Oral',
    indications:
      'To treat hyperuricemia associated with gout in patients who excrete <700 mg of urate in a 24-hour urine collection have normal renal function, no tophi, no history of renal calculi and do not have renal impairment.',
    mechanismOfAction: 'Inhibits renal tubular reabsorption of uric acid',
    contraindications: 'Hypersensitivity to probenecid, renal impairment, nephrolithiasis.',
    precautions:
      'Use caution with peptic ulcer. It inhibits excretion of penicillins, and toxic levels may accumulate in patients with impaired renal function. Maintain good urine output to decrease risk of stones and consider urine alkalinization.',
    __EMPTY_1: 'Monitor uric acid periodically',
    pregnancyRisk: 'No FDA category on label, likely C',
    commonSideEffects:
      '\r\nCommon: Nausea, vomiting, headache\r\nLess common: Rash, itch, allergy, precipitation of an acute attack of gout, leukopenia, aplastic anemia, urate nephropathy, nephrotic syndrome',
    commonInteractions:
      'Drug Interactions\r\nSalicylates (high dose): Antagonize uricosuric effect\r\nBeta-lactams (penicillins, cephalosporins) and quinolones: Increased plasma levels of antibiotics\r\nMethotrexate: Increased MTX levels and toxicity\r\nAntivirals: Reduced excretion of acyclovir and zidovudine',
    patientInstructions:
      ' Drink plenty of fluids. Avoid aspirin or other salicylates (may antagonize the uricosuric effect).',
    comments:
      'To prevent acute attacks of gout, chronic colchicine or NSAID therapy is coadministered for the first 3â€“12 months of probenecid therapy. In patients with tophi or renal stones, allopurinol is preferred.',
    clinicalPharmacology:
      'Rapid, complete absorption; hepatic metabolism and renal excretion. Half-life is 6â€“12 hours.',
    price: '$',
    citation:
      'Terkeltaub RA. Clinical practice. Gout. N Engl J Med 2003;349:1647â€“1655.PMID: 14573737',
  },
  {
    name: 'Raloxifene\r\n',
    brandNames: 'Evista',
    drugClass: 'Selective estrogen receptor modulator',
    __EMPTY: '60-mg tablet',
    dosage: 'Dose/administration: Osteoporosis: 60 mg by mouth daily',
    indications:
      'Treatment and prevention of osteoporosis in postmenopausal women; reduction in risk of invasive breast cancer in high risk women',
    mechanismOfAction:
      'Acts on some estrogen receptors and blocks others; acts as an estrogen receptor agonist to prevent bone loss',
    contraindications:
      'Pregnancy or planned pregnancy (not intended for use in premenopausal women), hypersensitivity, history of thromboembolism including DVT, pulmonary embolism, retinal vein thrombosis',
    precautions:
      'Increased risk of thromboembolism, renal or hepatic impairment, may increase triglycerides, increased risk of stroke in women with coronary heart disease',
    pregnancyRisk: 'X',
    commonSideEffects:
      '\r\nCommon: Hot flashes, arthralgia, edema\r\nLess Common: Fever, migraine, insomnia, myalgia, cramps, thromboembolism, thrombophlebitis, pulmonary embolism, hypertriglyceridemia',
    commonInteractions:
      'Drug Interactions\r\nCholestyramine: decreased raloxifene concentrations\r\nEstrogens: avoid',
    patientInstructions: ' Increased risk of blood clots.',
    comments:
      'Selective estrogen receptor modulators such as raloxifene preferentially act on estrogen receptors outside the uterus and breast. Raloxifene, unlike estrogens, does not increase the risk of breast cancer; in fact, it reduced the risk. Unlike hormone replacement therapy, hot flashes do not improve; they may get worse. Calcium and vitamin D supplemented if intake inadequate. Greater effect on vertebral than non-vertebral fracture risk.',
    clinicalPharmacology:
      'Absorbed (60%) but rapidly glucuronidated so that oral bioavailability is poor (2%). Extensive hepatic metabolism; half-life 30 hours.',
    price: '$$$$',
  },
  {
    name: 'Rilonacept\r\n',
    brandNames: 'Arcalyst',
    drugClass: 'IL-1 antagonist',
    __EMPTY: '220 mg vial for subcutaneous injection',
    dosage:
      'CAPS: Adults â€“ loading dose of 320 mg as 2 separate subcutaneous injections then once weekly dose of 160 mg. Children 12 to 17 years â€“ loading dose of 4.4 mg/kg (maximum 320 mg) as 2 separate subcutaneous injections then once weekly dose of 2.2 mg/kg (maximum160 mg).',
    indications: 'Cryopyrin-associated periodic syndrome (CAPS)',
    mechanismOfAction:
      'Acts as a decoy receptor and binds to IL-1 beta preventing interaction with receptors thus blocking the effects of the cytokine.',
    contraindications: 'Hypersensitivity, active or chronic infection',
    precautions:
      'Discontinue if patient develops an infection; avoid live vaccines; exclude TB and monitor for new infection',
    __EMPTY_1: 'Monitor clinically for infection; CBC after 1-2 months, then periodically',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Injection site reactions, upper respiratory tract infection, antibody formation\r\nLess common: Infection\r\nRare: Hypersensitivity',
    commonInteractions:
      'Concurrent use of other immunosuppressants may increase risk of infection; do not use with other IL-1 antagonists, TNF-antagonists, biologics, tofacitinib, abatacept',
    patientInstructions:
      ' Avoid live virus vaccines. Avoid pregnancy. Stop if an infection or a fever develops that lasts more than a few days.',
    comments:
      'In clinical studies rilonacept was more effective than placebo in preventing gout flares in patients starting allopurinol. Comparative efficacy against far cheaper alternatives such as NSAIDs or colchicine is not known. For the treatment of acute gout rilonacept was less effective than indomethacin; the combination of rilonacept and indomethacin was not significantly more effective than indomethacin alone.',
    clinicalPharmacology:
      'Half-life is 9 days. Biologic agents are not metabolized and have few drug interactions.',
    price: '$$$$$',
    citation:
      'Terkeltaub RA, Schumacher HR, Carter JD, et al. Rilonacept i n the treatment of acute gouty arthritis: a randomized, controlled clinicaltrial using indomethacin as the active comparator. Arthritis Res Ther 2013;15:R25. PMID:23375025',
  },
  {
    name: 'Risedronate\r\n',
    brandNames: 'Actonel',
    drugClass: 'Bisphosphonate',
    __EMPTY: '5-, 30-, 35-, 75- , 150 mg tablets',
    dosage:
      'Dose/administration\r\nOsteoporosis: 5 mg p.o. daily or 35 mg once weekly or 150 mg once a month\r\nPagetâ€™s disease: 30 mg p.o. daily for 2 months. If disease relapses, retreatment can be considered.',
    indications: 'Treatment and prevention of osteoporosis; treatment of Pagetâ€™s disease',
    mechanismOfAction:
      'Antiresorptive; localizes to areas of bone resorption and inhibits osteoclast activity without any effect on bone formation; increases bone mineral density and significantly reduces fracture rates',
    contraindications:
      'Hypersensitivity, hypocalcemia, esophageal stricture or dysmotility; not recommended for patients with severe renal insufficiency (creatinine clearance <35 mL/min). Avoid use in patients who cannot stand or sit upright for 30 minutes after administration.',
    precautions:
      'If possible, avoid use if esophageal problems or renal impairment are present. Ensure that patient understands how the drug should be taken. A dental exam and preventive dental work should be performed before starting chronic bisphosphonate therapy.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Mild GI disturbance (i.e., nausea, dyspepsia, dysphagia), hypocalcemia (transient, mild), headache\r\nUncommon: severe erosive esophagitis, atypical subtrochanteric femur fractures, osteonecrosis of the jaw, severe musculoskeletal pain, uveitis, altered taste, urticaria, angioedema, atrial fibrillation',
    commonInteractions: 'GI adverse events are increased in patients taking NSAIDs',
    patientInstructions:
      ' Take on an empty stomach (2 hours before meals). Should be taken with a full glass of water on arising in the morning. Nothing other than water should be taken for at least 30 minutes. Even coffee or fruit juice markedly reduce absorption. Delaying such intake for longer than 30 minutes (1â€“2 hours if possible) maximizes absorption. After taking, the patient must remain upright to reduce risk of esophageal irritation. Any other medications must be taken at least 30 minutes after bisphosphonate. Supplement calcium and vitamin D intake if needed.',
    comments:
      'Supplemental calcium and vitamin D are usually coadministered. Weekly or monthly dosing for osteoporosis is preferred.The optimal duration of treatment is uncertain. A temporary discontinuation may be considered after 3-5 years of therapy in patients at low risk of fracture with monitoring of bone density 2-3 years after discontinuation.',
    clinicalPharmacology:
      'Oral bioavailability is very poor (<1%) and negligible if administered with or after food. Absorbed drug is renally excreted and not metabolized. Terminal half-life exceeds 10 days, but elimination from body over months or years indicating localization and slow release from bone.',
    price: '$$$$',
    citation:
      'McClung M, Harris ST, Miller PD et al. Bisphosphonate therapy for osteoporosis: benefits, risks and drug holiday. Am J Med 2013;126:13-20. PMID: 23177553',
  },
  {
    name: 'Rituximab\r\n',
    description:
      "a monoclonal anti-CD20 antibody used to treat non-Hodgkin's lymphoma, chronic lymphocytic leukemia, Wegener's granulomatosis, pemphigus vulgaris, and rheumatoid arthritis.",
    brandNames: 'MabThera, Riabni, Rituxan, Rituxan Hycela, Ruxience, Truxima',
    drugClass: 'Anti CD-20 monoclonal antibody, biologic DMARD',
    __EMPTY: 'Injection: 100 mg/10 mL and 500 mg/50 mL',
    dosage:
      'Refer to individual i.v. infusion protocols in label.\r\nFor granulomatosis with polyangiitis: 375 mg/m2 once weekly for 4 weeks. Also treated with IV methylprednisolone.\r\nFor RA: 1,000 mg i.v. on days 1 and 15 and based on response readministration at 24 week intervals. Usually premedication with IV methylprednisolone 100 mg or equivalent. Usually administered for RA with background methotrexate therapy.',
    administration: 'Injection, IV',
    indications:
      'Rheumatoid arthritis in combination with methotrexate and had inadequate response to at least one TNF inhibitor, granulomatosis with polyangiitis, non-Hodgkinâ€™s lymphoma',
    mechanismOfAction: 'Binds to antigen on CD-20 positive cells and depletes B cells',
    contraindications:
      'Hypersensitivity to murine proteins; active infection; breast feeding; hepatitis B infection;',
    precautions:
      'Do not administer as intravenous push; administer slowly per protocols in package insert. Infusion reactions are common and may respond to slowing or temporary discontinuation of infusion or premedication with acetaminophen, diphenhydramine and corticosteroids. Screen to exclude hepatitis B and C before initiating therapy. For granulomatosis with polyangiitis consider prophylaxis against pneumocystis during, and for 6 months after, rituximab treatment.',
    __EMPTY_1:
      'Monitor for infusion reactions during infusion. Monitor CBC and platelets every 2-4 months.',
    pregnancyRisk: 'C',
    commonSideEffects:
      '\r\nCommon: Mild infusion reactions, allergy, headache, hypotension, nausea, leukopenia, urticaria\r\nLess common: Vomiting, hypertension, thrombocytopenia, neutropenia, red cell aplasia, arrhythmia, serious (including fatal) infusion reactions, severe (including fatal) mucocutaneous reactions, reactivation of hepatitis B, bacterial viral and fungal infections, zoster, progressive multifocal leukoencephelopathy, bowel obstruction or perforation, psoriasis, vasculitis',
    commonInteractions:
      'Avoid concurrent use of other biologic immunosuppressants. Avoid live virus vaccines and BCG.',
    patientInstructions:
      'comments: For severe ANCA-associated vasculitis a single course of rituximab was as effective as standard cyclophosphamide and then azathioprine for 18 months. The comparative efficacy and safety of rituximab retreatment compared to other strategies to maintain remission in ANCA-associated vasculitis is being defined. In a 28 month study low dose rituximab (500 mg) on days 0 and 14, and then at months 6, 12 and 18 was more effective than daily azathioprine in preventing major relapse in patients who had been in complete remission after a glucocorticoid and cyclophosphamide induction regimen.',
    clinicalPharmacology:
      'Half-life is approximately 18 days; can be detected in serum several months after administration; B cell recovery begins approximately 6 months after treatment and returns to baseline by 12 months.',
    price: '$$$$$',
    citation:
      'Specks U, Merkel PA, Seo P, et al. Efficacy of remission-induction regimens for ANCA-associated vasculitis. N Engl J Med 2013;369:417-27. PubMed PMID: 23902481.',
  },
  {
    name: 'Salsalate\r\n',
    brandNames: 'Disalcid',
    drugClass: 'Nonacetylated salicylate, NSAID',
    synonyms: 'Synonyms: Disalicylic acid',
    __EMPTY: '\r\nTablets: 500 and 750 mg',
    dosage: 'Adult: 3 g/day in two or three divided doses',
    indications: 'Rheumatoid arthritis, osteoarthritis, pain',
    mechanismOfAction: 'Weak inhibitor of prostaglandin synthesis',
    contraindications:
      'Hypersensitivity to salicylates, acute coronary ischemia or peri-operative pain in setting of CABG',
    precautions:
      'Administer with food. Use caution in asthma, bleeding disorders, anticoagulant use, hepatic or renal disease or high GI or CV risk, hypertension',
    __EMPTY_1:
      'Monitor hematocrit, creatinine, liver enzymes periodically (e.g.,1 month after starting and then every 3â€“6 months). Serum salicylate levels may be assayed periodically, if necessary.',
    pregnancyRisk: 'C/D last trimester',
    commonSideEffects:
      '\r\nCommon: GI irritation (dyspepsia, reflux, epigastric pain)\r\nLess common: GI ulceration or hemorrhage, minor elevations of liver enzymes,hypersensitivity (asthma, urticaria, angioedema, particularly in patients with nasal polyps), cross-sensitivity occurs between NSAIDs, but hypersensitivity is less common with the nonacetylated salicylates, NSAIDs can increase CV risk and impair renal function, dose-related side effects include tinnitus and deafness.',
    commonInteractions:
      'Drug Interactions\r\nAntacids: Decreased salicylate levels through increased elimination in alkaline urine\r\nAnticoagulants: Activity of warfarin increased\r\nUricosurics: Decreased uricosuric effect',
    patientInstructions:
      ' Take with food. Discontinue and seek medical advice if fainting, vomiting of blood, or unusual bleeding develops.',
    comments:
      'Nonacetylated salicylates have little effect on platelet function and may cause less GI toxicity than classic NSAIDs, which are more potent inhibitors of prostaglandin synthesis. In practice, the antiinflammatory effect of salsalate is less than that of classic NSAIDs.',
    clinicalPharmacology:
      'Rapidly and well absorbed; hepatic metabolism and renal excretion of conjugated metabolites. Wide variation in plasma concentrations in individuals receiving the same dose. Half-life varies with dose (2â€“3 hours with low doses, >20 hours with high doses) because at high doses the salicylate elimination pathway is saturated and a small increase in dose can lead to a large increase in serum concentrations.',
  },
  {
    name: 'Sildenafil\r\n',
    brandNames: 'Viagra, Revatio',
    drugClass: 'Phosphodiesterase 5 (PDE5) inhibitor',
    __EMPTY: 'Revatio 20 mg, Viagra 25-, 50-, 100 mg tablets',
    dosage:
      'For pulmonary hypertension 20 mg three times daily; for erectile dysfunction 50 mg 1 hour before sex',
    indications:
      'Erectile dysfuntion (Viagra), pulmonary arterial hypertension (Revatio), sildenafil also tried in Raynaudâ€™s and for digital ulcers associated with scleroderma',
    mechanismOfAction:
      'Inhibits PDE5 and thus increases cyclic GMP concentrations causing vasodilation',
    contraindications:
      'Hypersensitivity, concurrent use of any form of nitrates, including ointment',
    precautions:
      'Potential for drug interactions; not recommended for children with pulmonary hypertension (increased mortality)',
    pregnancyRisk: 'B',
    commonSideEffects:
      '\r\nCommon: Flushing, headache, dyspepsia, dizziness\r\nLess common: Visual disturbance including color changes, allergy, rash, hearing loss, hypotension (particularly with nitrates or cytochrome P450 (CYP)3A inhibitors), priapism',
    commonInteractions:
      'Drug Interactions\r\nCYP3A inhibitors (strong inhibitors include clarithromycin,telithromycin, ketoconazole, itraconazole, nefazodone and many HIV drugs; moderate inhibitors include aprepitant, diltiazem, verapamil, erythromycin, fluconazole, posaconazole, voriconazole, grapefruit juice): Increased sildenafil concentrations.\r\nNitrates: Hypotension; avoid this combination\r\nAlpha-1 blockers: Increased risk of hypotension',
    patientInstructions: ' Avoid grapefruit juice; stop drug if have sudden visual or hearing loss',
    comments:
      'Small studies or case-series suggest PDE5 inhibitors are modestly effective for Raynaudâ€™s and digital ulcers associated with scleroderma.',
    clinicalPharmacology:
      'Onset 60 minutes, duration 2-4 hours, half-life 4 h. Metabolized by CYP3A4.',
    citation:
      "Roustit M, Blaise S, Allanore Y, et al.vPhosphodiesterase-5 inhibitors for the treatment of secondary Raynaud's phenomenon:systematic review and meta-analysis of randomised trials. Ann Rheum Dis 2013;72:1696-9. PMID: 23426043.",
  },
  {
    name: 'Sulfasalazine\r\n',
    description:
      "A salicylate used to treat Crohn's disease, ulcerative colitis, and rheumatoid arthritis.",
    brandNames: 'Azulfidine, Salazopyrin, Salazopyrin En-tabs',
    drugClass: 'Sulfonamide/salicylate congener, DMARD',
    synonyms: 'Synonyms: Salazopyrin; 5-aminosalicylic acid plus sulfapyridine',
    __EMPTY: '\r\nTablet: 500 mg\r\nTablet, enteric coated: 500 mg',
    dosage:
      'In rheumatic diseases, 2â€“3 g/day in two or three divided doses. Initial dose of 500 mg daily is increased by 500-mg increments weekly as tolerated. Usual maintenance dose is 2â€“3 g/day. Higher doses may be associated with greater GI toxicity.',
    administration: 'Oral',
    indications:
      'Rheumatoid arthritis, reactive arthritis, ankylosing spondylitis, psoriatic arthritis, inflammatory bowel disease',
    mechanismOfAction:
      'Unknown; the sulfonamide component is thought to be more active in the treatment of rheumatic diseases than the 5-aminosalicylic component.',
    contraindications:
      'Hypersensitivity to sulfonamides or salicylates, porphyria, GI/genitourinary obstruction',
    precautions:
      'Use caution in impaired renal function; it may cause hemolysis in G6PD deficiency.',
    __EMPTY_1:
      'Hematologic adverse effects are most likely in the first 6 months. CBC every 2â€“3 weeks for first 3 months, then gradually decrease frequency to every 3 months. Periodic LFTs (3- to 6-month intervals).',
    pregnancyRisk: 'B (D at term)',
    commonSideEffects:
      '\r\nCommon: GI side effects (nausea, vomiting, diarrhea, cramps), rash, itch, dizziness, headache\r\nLess common: Reversible oligospermia, neutropenia, aplastic anemia, agranulocytosis, hemolysis, Stevens-Johnson syndrome and other hypersensitivity reactions, photosensitivity, SLE-like syndrome, nephrotic syndrome, orange-yellow discoloration of urine, hepatitis',
    commonInteractions:
      'Drug Interactions\r\nWarfarin: Resistance to warfarin described, monitor international normalized ratio for change',
    patientInstructions:
      ' May cause orange-yellow discoloration of skin, urine, and contact lenses. Beware of photosensitive reactions to prolonged sunlight exposure.',
    comments:
      'GI intolerance is often prominent when starting treatment. Thus, start with a low dose and work up. Some evidence indicates that the enteric coated tablets are better tolerated. Efficacy in RA appears similar to that of methotrexate (MTX), but there may be more minor side effects. Widely used in combination with MTX and hydroxychloroquine (triple therapy) in patients with RA not responding to MTX alone. Efficacy in ankylosing spondylitis, reactive arthritis, and psoriatic arthritis is variable, probably greatest in those with peripheral arthropathy (rather than axial disease alone), and less than that of anti-TNFs. It may cause folate deficiency (consider supplementation with folate, 1 mg/day).',
    clinicalPharmacology:
      'The azo bond joining 5-aminosalicylic and sulfapyridine is broken by bacteria in the colon. Approximately 15%â€“30% is absorbed; hepatic metabolism; renal excretion. Half-life is 6â€“10 hours. Slow acetylators have higher sulfapyridine blood levels and perhaps more minor side effects, but acetylator status need not be routinely determined.',
    citation:
      "O'Dell JR, Mikuls TR, Taylor TH, et al. Therapies for active rheumatoid arthritis after methotrexate failure. N Engl J Med 2013;369:307-18. PMID: 23755969.",
  },
  {
    name: 'Sulindac\r\n',
    brandNames: 'Clinoril',
    drugClass: 'NSAID',
    __EMPTY: '150- and 200-mg tablets',
    dosage: '150â€“200 mg twice daily',
    comments:
      'Claims by some that sulindac is less nephrotoxic than other NSAIDs are based on limited and controversial evidence from short-term studies.',
    clinicalPharmacology:
      'Well absorbed after oral administration, sulindac is a prodrug. After ingestion, there is hepatic metabolism to an active sulfide metabolite, which later undergoes renal elimination. Half-life of parent drug is 7 hours and half-life of metabolite is 18 hours.',
    price: '$$',
    citation:
      'See NSAIDs for Indications, mechanismOfAction, contraindications, precautions, Monitoring, pregnancyRisk, Adverse Effects, Interactions, and patientInstructions.',
  },
  {
    name: 'Tadalafil\r\n',
    brandNames: 'Cialis, Adcirca',
    drugClass: 'Phosphodiesterase 5 (PDE5) inhibitor',
    __EMPTY: '2.5-, 5-, 10-, 20 mg tablets',
    dosage:
      'For pulmonary hypertension 40 mg once daily; for erectile dysfunction 2.5-5 mg once daily or 5-20 mg episodically (not more than once daily) before sexual activity',
    indications:
      'Erectile dysfuntion, pulmonary hypertension, benign prostatic hypertrophy, PDE5 inhibitors also tried in Raynaudâ€™s and for digital ulcers associated with scleroderma',
    mechanismOfAction:
      'Inhibits PDE5 and thus increases cyclic GMP concentrations causing vasodilation',
    contraindications:
      'Hypersensitivity, concurrent use of any form of nitrates, including ointment',
    precautions: 'Potential for drug interactions',
    pregnancyRisk: 'B',
    commonSideEffects:
      '\r\nCommon: Flushing, headache, dyspepsia, dizziness\r\nLess common: Visual disturbance including color changes, allergy, rash, hearing loss, hypotension (particularly with nitrates or cytochrome P450 (CYP) 3A inhibitors), priapism',
    commonInteractions:
      'Drug Interactions\r\nCYP3A inhibitors (strong inhibitors include clarithromycin, telithromycin, ketoconazole, itraconazole, nefazodone and many HIV drugs; moderate inhibitors include aprepitant, diltiazem, verapamil, erythromycin, fluconazole, posaconazole, voriconazole, grapefruit juice): increased tadalafil concentrations.\r\nNitrates: Hypotension; avoid this combination\r\nAlpha-1 blockers: Increased risk of hypotension',
    patientInstructions: ' Avoid grapefruit juice; stop drug if have sudden visual or hearing loss',
    comments:
      'Small studies or case-series suggest PDE5 inhibitors are modestly effective for Raynaudâ€™s and for digital ulcers associated with scleroderma.',
    clinicalPharmacology: 'Onset 60 minutes, half-life 15-35 h. Metabolized by CYP3A4.',
    price: '$$$$$',
    citation:
      "Roustit M, Blaise S, Allanore Y, et al.vPhosphodiesterase-5 inhibitors for the treatment of secondary Raynaud's phenomenon:systematic reviewand meta-analysis of randomised trials. Ann Rheum Dis 2013;72:1696-9. PMID: 23426043.",
  },
  {
    name: 'Teriparatide\r\n',
    brandNames: 'Forteo',
    drugClass: 'Hormone',
    synonyms: 'Synonyms: Human recombinant parathyroid hormone (1-34)',
    __EMPTY: 'Prefilled injection pen 2.4 mL (supplies 20 mcg dose for 28 days)',
    dosage: '20 mcg daily by subcutaneous injection',
    indications:
      'Osteoporosis with high risk of fracture failed alternative therapies; long-term safety not well established; until additional information is available, treatment for >2 years is not recommended.',
    mechanismOfAction: 'Mimics action of parathyroid hormone and stimulates new bone formation',
    contraindications:
      'Hypersensitivity, hypercalcemia, increased risk of osteosarcoma, avoid in Pagetâ€™s disease, previous irradiation, skeletal malignancy, open epiphyses, children',
    precautions:
      'Renal stones, unexplained increase in alkaline phosphatase, digoxin therapy, orthostatic hypotension â€“ if occurs, is usually within a few hours of injection, ensure adequate vitamin D and calcium intake',
    __EMPTY_1: 'Monitor serum calcium level periodically',
    pregnancyRisk: 'C',
    commonSideEffects:
      ': Causes osteosarcoma in rats; the significance of this as regards risk in humans not established\r\nCommon: Arthralgia, nausea, transient increase in serum calcium concentration 4â€“6 hours after injection, dizziness, leg cramps\r\nUncommon: Orthostatic hypotension 4â€“6 hours after injection',
    commonInteractions:
      'Drug Interactions\r\nDigoxin: Increased serum calcium may predispose to toxicity\r\nBisphosphonates: Impair ability of teriparatide to increase bone density',
    patientInstructions:
      ' Refrigerate (do not freeze) the injection pen. Follow manual of instructions that comes with the pen. Dizziness on standing can occur after injection.',
    comments:
      'Cost, daily injections and limited duration of therapy limit use. A weekly teriparatide preparation is under study. Carries a â€œblack boxâ€ warning that it causes osteosarcoma in rats and should only be prescribed for patients for whom benefits outweigh risk. Antiresorptive drugs such as bisphosphonates impair the anabolic effects of teriparatide. Bone mineral density decreases when drug is stopped. Case reports of use in bisphosphonate-associated osteonecrosis of the jaw and atypical subtrochanteric fractures. Reports of increased bone mineral density in combination with denosumab.',
    clinicalPharmacology:
      'Good rapid absorption, half-life less than 1 hour after subcutaneous injection',
    citation:
      'Saag KG, Zanchetta JR, Devogelaer JP, et al. Effects of teriparatide versus alendronate for treating glucocorticoid-induced osteoporosis: thirty-six-month results of a randomized,double-blind, controlled trial. Arthritis Rheum 2009;60:3346-55. PMID: 19877063.',
  },
  {
    name: 'Thalidomide\r\n',
    brandNames: 'Thalomid',
    drugClass: 'Immunomodulator',
    __EMPTY: 'Capsule: 50-, 100-, 150-, 200 mg',
    dosage:
      '100â€“300 mg daily in divided doses; must only be administered in compliance with the REMS program',
    indications:
      'Erythema nodosum leprosum, myeloma, small, mostly uncontrolled reports have suggested possible benefit in severe recurrent refractory apthous stomatitis including ulcers associated with HIV and BehÃ§etâ€™s disease, and pyoderma gangrenosum and other refractory dermatological conditions',
    mechanismOfAction: 'Unknown; may inhibit angiogenesis and TNF',
    contraindications:
      'Pregnancy, hypersensitivity to thalidomide, childbearing potential and noncompliance with contraception, neutropenia',
    precautions:
      'May only be prescribed by physicians registered in the Thalidomid REMS program (formerly System for Thalidomide Education and Prescribing Safety (STEPS) program (see www.celgeneriskmanagement.com). If possible, avoid in women of childbearing potential; if not possible, ensure contraception for 4 weeks before and 4 weeks after therapy and a negative pregnancy test within 10-14 days and again within 24 hours of beginning treatment.',
    __EMPTY_1:
      'Monthly pregnancy tests, monthly assessment for peripheral neuropathy for 3 months and then periodically, consider electrophysiologic testing to monitor for neuropathy, monitor CBC periodically',
    pregnancyRisk: 'X, highly teratogenic',
    commonSideEffects:
      '\r\nCommon: Peripheral neuropathy that may be permanent; this can occur after short-term use. Postural hypotension, dizziness, sleepiness. If used during pregnancy, causes birth defects such as phocomelia\r\nLess common: Neutropenia, hypersensitivity including Stevens-Johnson syndrome, seizures, edema, rash, deep vein thrombosis, bradycardia, myocardial infarction and stroke',
    commonInteractions:
      'Increased sedation with sedatives and increased risk of bradycardia and peripheral neuropathy with drugs that cause those',
    patientInstructions:
      ' Do not share the medication. Use two forms of contraception if a women and a latex condom if a man. This drug causes birth defects; do not become pregnant. Report changes in sensation in your hands and feet. Can cause sleepiness and dizziness on standing. Do not donate blood or sperm. Increased risk of blood clots.',
    comments:
      'Has limited potential for long-term use owing to frequent side effects, teratogenicity, lack of proven efficacy in rheumatic diseases, and the relapse of disease after discontinuation. Strategies to prevent fetal exposure to thalidomide are outlined in the REMS program; can only be prescribed by program-approved physicians.',
    clinicalPharmacology:
      'Peak concentrations at 4 hours; elimination half-life is 5 hours, largely through nonenzymatic hydrolysis',
    price: '$$$$$',
    citation:
      'Hello M, Barbarot S, Bastuji-Garin S, Revuz J, Chosidow O. Use of thalidomide for severe recurrent aphthous stomatitis: a multicenter cohort analysis. Medicine 2010;89:176-82.PMID: 20453604.',
  },
  {
    name: 'Tocilizumab',
    description:
      'Blocks the activity of T-cells, a type of immune cell in the body that causes swelling and joint damage in people who have arthritis',
    brandNames: 'Actemra, RoActemra',
    administration: 'injection, IV',
    indications:
      'moderate to severe rheumatoid arthritis, giant cell arteritis, systemic sclerosis-associated interstitial lung disease, polyarticular juvenile idiopathic arthritis, systemic juvenile idiopathic arthritis, and cytokine release syndrome',
    commonInteractions:
      'The risk or severity of adverse effects can be increased when Abatacept is combined with Tocilizumab.',
    commonSideEffects: 'neutropenia',
    dosage: '20 mg/1mL, 162 mg/0.9mL, 400 mg/20mL',
  },
  {
    name: 'Tofacitinib',
    description:
      'A Janus kinase (JAK) inhibitor used to treat rheumatic conditions, such as rheumatoid arthritis and ankylosing spondylitis, and ulcerative colitis.',
    brandNames: 'Xeljanz',
    administration: 'Oral',
    indications:
      'rheumatoid arthritis, active psoriatic arthritis, active ankylosing spondylitis, active ulcerative colitis',
  },
  {
    name: 'Upadacitinib',
    description:
      'an oral Janus kinase (JAK)1-selective inhibitor used in the treatment of moderate to severe rheumatoid arthritis, active psoriatic arthritis, ankylosing spondylitis, and severe atopic dermatitis, including in patients who did not respond well to other therapies.',
    brandNames: 'Rinvoq',
    administration: 'Oral',
    indications:
      'rheumatoid arthritis, psoriatic arthritis, atopic dermatitis, ankylosing spondylitis, ulcerative colitis',
  },
];
