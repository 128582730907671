import { Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  adminChip: {
    borderRadius: 4,
    margin: theme.spacing(0, 0.5, 0.5, 0),
  },
}));

export default function Administration({ administration }) {
  const classes = useStyles();
  return (
    <>
      {administration.map((admin) => (
        <Chip
          label={admin.toLowerCase()}
          className={classes.adminChip}
          color="default"
          variant="outlined"
        />
      ))}
    </>
  );
}
