import { gql } from '@apollo/client';

export const CREATE_PATIENT = gql`
  mutation createPatient(
    $firstname: String
    $lastname: String
    $email: String
    $password: String
    $birthdate: String
    $gender: String
  ) {
    createPatient(
      firstname: $firstname
      lastname: $lastname
      email: $email
      birthdate: $birthdate
      gender: $gender
      password: $password
      isPatient: false
    ) {
      email
    }
  }
`;
