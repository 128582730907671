import {
    SELECT_TREATMENT,
    ADD_TREATMENTS,
    ADD_RESPONSES
} from './../actions/types';

const initialState = {
    selectedTreatmentIndex: 0,
    treatments: [],
};

export default function(state=initialState, action) {

       switch(action.type) {
        case SELECT_TREATMENT:
            return {
                ...state,
                treatments: state.treatments,
                selectedTreatmentIndex: action.payload.treatmentIndex,
                responses: state.responses
            }
        case ADD_TREATMENTS:
            return {
                ...state,
                treatments: action.payload.treatments,
                selectedTreatmentIndex: state.treatmentIndex,
                responses: state.responses

            }
        case ADD_RESPONSES:

            return {
                ...state,
                treatments: state.treatments,
                selectedTreatmentIndex: state.selectedTreatmentIndex,
                responses: action.payload.responses
            }
        default:
            return state;
    }

}