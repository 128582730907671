import {
    FETCH_ALERTS,
    READ_ALERT
} from "../actions/types"

const initialState = {
    alerts: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case FETCH_ALERTS:
            return {
                ...state,
                alerts: action.payload.data.UnreadAlerts
            }
        default:
            return state;
    }    
}