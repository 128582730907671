// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_LeCFqAlbf",
    "aws_user_pools_web_client_id": "2o99uim2tkaer86defl0adu7t9",
    "oauth": {}
};


export default awsmobile;
