import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  sideEffectListItem: {
    // paddingLeft: theme.spacing(2),
    '&::before': {
      content: "'\\2022'",
      paddingRight: theme.spacing(1),
    },
  },
}));

export default function SideEffects({ sideEffects }) {
  const classes = useStyles();
  return (
    <div>
      <List disablePadding dense>
        {sideEffects.map((sideEffect) => (
          <ListItem key={sideEffect} disableGutters className={classes.sideEffectListItem}>
            <ListItemText primary={sideEffect} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
