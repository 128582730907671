import { createTheme } from '@material-ui/core';

const themeOptions = {
  spacing: 10,
  palette: {
    type: 'light',
    primary: {
      main: '#4F8CE2',
    },

    error: {
      main: '#BB2649',
    },
    info: {
      main: '#0288d1',
    },
    success: {
      main: '#2e7d32',
    },
  },
  typography: {
    fontWeightRegular: 400,
    h1: {
      fontSize: '6rem',
    },
    h5: {
      fontSize: '1.5em',
    },
    body1: {
      fontSize: '1em',
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
