import { useAuthenticator } from '@aws-amplify/ui-react';
import clsx from 'clsx';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import FiltersLeftMenu from './FiltersLeftMenu';
import Footer from './Footer';
import Header from './Header/Header';
import { useStyles } from './usestyles';
import SearchHeader from './Header/SearchHeader';

const protectedRoutes = ['/home', '/responses', '/analytics', '/drugs'];

export default function Layout() {
  const classes = useStyles();
  const location = useLocation();
  const currentRoute = location.pathname;

  const { route } = useAuthenticator((context) => [context.route]);
  const isAuthenticated = route === 'authenticated';
  const isProtectedRoute = protectedRoutes.includes(currentRoute);
  const authAppStyles =
    isAuthenticated && isProtectedRoute ? clsx(classes.isAuthApp, classes.app) : classes.app;

  return (
    <div className={authAppStyles}>
      {isAuthenticated && isProtectedRoute ? (
        <>
          <header className={classes.header}>
            <SearchHeader />
          </header>
          <nav className={classes.sideMenu}>
            <FiltersLeftMenu />
          </nav>
          <main className={classes.mainContent}>
            <Outlet />
          </main>
          <footer className={classes.footer}>
            <Footer />
          </footer>
        </>
      ) : (
        <>
          <header className={classes.header}>
            <Header />
          </header>
          <main className={classes.mainContent}>
            <Outlet />
          </main>
          <footer className={classes.footer}>
            <Footer />
          </footer>
        </>
      )}
    </div>
  );
}
