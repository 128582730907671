import { useReactiveVar } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { textbookDrugs } from '../../../../__mocks__/drugs';
import { drugViewVar } from '../../../../data/state/drugs/drugViewVar';
import { selectedIndicationsVar } from '../../../../data/state/drugs/indications/selectedIndicationsVar';
import { selectedPregnancyRiskVar } from '../../../../data/state/drugs/pregnancyRisk/selectedPregnancyRiskVar';
import { drugSearchVar } from '../../../../data/state/drugs/search/drugSearchVar';
import DrugCard from './DrugCard/DrugCard';
import DrugComparisonView from './DrugComparisonView';
import { cleanNames, parseIndicators, stringWithCommasToArray } from './dataCleaners';

const useStyles = makeStyles((theme) => ({
  drugsResultHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function filterDrugsByIndications(drugs, targetIndications) {
  if (!targetIndications || targetIndications.length === 0) return drugs;
  return drugs.filter((drug) =>
    targetIndications.some((targetIndication) => drug.indications.includes(targetIndication))
  );
}
const filterDrugsByPregnancyRisk = (drugs, targetPregnancyRisk) => {
  if (!targetPregnancyRisk || targetPregnancyRisk.length === 0) return drugs;
  return drugs.filter((drug) => targetPregnancyRisk.includes(drug.pregnancyRisk));
};

const filterBySearch = (drugs, search) => {
  if (!search || search === null || search.length < 3) return drugs;
  return drugs.filter(
    (drug) =>
      drug.name.toLowerCase().includes(search.toLowerCase()) ||
      drug.brandNames.some((brandName) => brandName.toLowerCase().includes(search.toLowerCase()))
  );
};

export default function Drugs() {
  const classes = useStyles();
  const parseAllDrugs = textbookDrugs.map((drug) => {
    return {
      ...drug,
      indications: parseIndicators(drug.indications),
      name: cleanNames(drug.name),
      brandNames: stringWithCommasToArray(drug.brandNames),
      administration: stringWithCommasToArray(drug.administration),
      commonSideEffects: stringWithCommasToArray(drug.commonSideEffects),
    };
  });

  const drugView = useReactiveVar(drugViewVar);
  const selectedIndications = useReactiveVar(selectedIndicationsVar);
  const selectedPregnancyRisks = useReactiveVar(selectedPregnancyRiskVar);
  const drugSearch = useReactiveVar(drugSearchVar);
  const filteredDrugs = filterDrugsByIndications(parseAllDrugs, selectedIndications);
  const filteredDrugsByPregnancyRisk = filterDrugsByPregnancyRisk(
    filteredDrugs,
    selectedPregnancyRisks
  );
  const filterBySearchResults = filterBySearch(filteredDrugsByPregnancyRisk, drugSearch);
  const isComparisonView = drugView === 'yes';

  return (
    <div>
      {isComparisonView ? (
        <DrugComparisonView />
      ) : (
        <div>
          <div className={classes.drugsResultHeader}>
            Showing {filterBySearchResults.length}/{textbookDrugs.length} drugs
          </div>
          <Grid container spacing={2}>
            {filterBySearchResults.map((drug) => (
              <Grid item xs={6} md={4} key={`grid-${drug.name}`}>
                <DrugCard drug={drug} key={`drugcar - ${drug.name}`} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
}
