import { Divider, Drawer, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PulseLogo from '../Header/PulseLogo/PulseLogo';
import Actions from './Actions';
import FilterByCategory from './FilterByCategory';

const DRAWER_WIDTH = 240;
const useStyles = makeStyles((theme) => ({
  filtersDrawer: {
    width: theme.spacing(30),
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    boxShadow: theme.shadows[1],
  },
  divider: {
    width: '100%',
  },
  drawerLogo: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    cursor: 'pointer',
  },
}));

export default function FiltersLeftMenu(props) {
  const { window } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;
  const drawerContent = (
    <>
      <div className={classes.drawerLogo} onClick={() => navigate('/')}>
        <PulseLogo />
        <Typography variant="h6" noWrap>
          Panacea AI
        </Typography>
      </div>
      <Divider variant="fullWidth" className={classes.divider} />
      <FilterByCategory />
      <Divider variant="fullWidth" className={classes.divider} />
      <Actions />
    </>
  );
  return (
    <div className={classes.filtersLeftMenu}>
      {/* <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuOpenIcon />
      </IconButton> */}
      {/* <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden> */}
      {/* <Hidden xsDown implementation="css"> */}
      {/* TODO: fix temporary drawer so be responsive */}
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="permanent"
        open
        className={classes.filtersDrawer}
      >
        {drawerContent}
      </Drawer>
      {/* </Hidden> */}
    </div>
  );
}
