import {
  alpha,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
} from '@material-ui/core';
import HealingIcon from '@material-ui/icons/Healing';
import PregnantWomanIcon from '@material-ui/icons/PregnantWoman';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { selectedIndicationsVar } from '../../../../../data/state/drugs/indications/selectedIndicationsVar';
import { getIndications, getPregnancyRisks } from '../../../pages/Drugs/dataCleaners';
import { GenericAutocomplete } from '../filterComponents';
import { selectedPregnancyRiskVar } from '../../../../../data/state/drugs/pregnancyRisk/selectedPregnancyRiskVar';

const CATEGORY_FILTERS = [
  {
    category: 'Indication',
    icon: <HealingIcon />,
    subcategories: [
      { label: 'Rheumatoid Arthritis', value: 'rheumatoid-arthritis' },
      { label: 'Osteoarthritis', value: 'osteoarthritis' },
      { label: 'Lupus', value: 'lupus' },
    ],
    placeholder: 'Filter by indications',
    options: [],
  },
  {
    category: 'Pregnancy Risk',
    icon: <PregnantWomanIcon />,
    placeholder: 'Filter by pregnancy risk',
    options: [],
  },
];

export const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(8),
  },
  selected: {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: theme.palette.info.main,
  },
  divider: {
    width: '100%',
  },
  indicationSelector: {
    margin: theme.spacing(1),
    '& .MuiAutocomplete-tagSizeSmall': {
      backgroundColor: alpha(theme.palette.info.main, 0.25),
    },
  },
  selectedIndicationChip: {
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
  },
}));
export default function FilterByCategory() {
  const classes = useStyles();
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = React.useState(null);
  const [selectedIndications, setSelectedIndications] = React.useState([]);
  const newIndications = getIndications().sort();

  const [selectedPregnancyRisk, setSelectedPregnancyRisk] = React.useState([]);
  const pregnancyRiskOptions = getPregnancyRisks().sort();

  const handleCategoryClick = (category) => {
    if (selectedCategory === category) {
      setSelectedCategory(null);
      setSelectedSubcategory(null);
    } else {
      setSelectedCategory(category);
      setSelectedSubcategory(null);
    }
  };

  const isCategorySelected = (category) => {
    return selectedCategory === category;
  };

  const handleSelectedIndications = (event, value) => {
    setSelectedIndications(value);
    selectedIndicationsVar(value);
  };
  const handleSelectedPregnancyRisks = (event, value) => {
    setSelectedPregnancyRisk(value);
    selectedPregnancyRiskVar(value);
  };

  const getCategorySpecificProps = (category) => {
    switch (category.toLowerCase()) {
      case 'indication':
        return {
          options: newIndications,
          value: selectedIndications,
          onChange: handleSelectedIndications,
        };
      case 'pregnancy risk':
        return {
          options: pregnancyRiskOptions,
          value: selectedPregnancyRisk,
          onChange: handleSelectedPregnancyRisks,
        };
      default:
        return { options: [], value: [], onChange: () => {} };
    }
  };
  return (
    <div>
      <List aria-labelledby="nested-list-subheader">
        {CATEGORY_FILTERS.map(({ category, icon, placeholder }) => {
          const isSelectedStyles = isCategorySelected(category) ? classes.selected : '';
          const { options, value, onChange } = getCategorySpecificProps(category);
          // options = getOptions(category);
          return (
            <React.Fragment key={`fragment-${category}`}>
              <ListItem
                button
                key={category}
                onClick={() => handleCategoryClick(category)}
                selected={isCategorySelected(category)}
                className={isSelectedStyles}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={category} />
              </ListItem>
              <GenericAutocomplete
                multiple
                size="small"
                className={classes.indicationSelector}
                limitTags={1}
                id="multiple-limit-tags"
                options={options}
                value={value}
                getOptionLabel={(indic) => indic}
                placeholder={placeholder}
                onChange={onChange}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="default"
                      label={option}
                      size="small"
                      className={classes.selectedIndicationChip}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </React.Fragment>
          );
        })}
      </List>
    </div>
  );
}
