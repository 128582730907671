import { useApolloClient, useMutation } from '@apollo/client';
import {
  Authenticator,
  CheckboxField,
  TextField,
  View,
  useAuthenticator,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { makeStyles } from '@material-ui/core';
import { Auth, I18n } from 'aws-amplify';
import { clsx } from 'clsx';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { CREATE_PATIENT } from '../../../data/queries/CreatePatient';

import { translations } from '@aws-amplify/ui-react';

I18n.setLanguage('en');
I18n.putVocabulariesForLanguage('en', {
  [translations.EMPTY_USERNAME]: 'A valid email address must be provided',
});

const useStyles = makeStyles((theme) => ({
  authWrapper: {
    display: 'grid',
    height: '100vh',
    placeItems: 'center',
  },
}));

const BETA_CODE = process.env.REACT_APP_BETA_CODE;

export function CustomLogin() {
  const classes = useStyles();
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  const apolloClient = useApolloClient();

  const [createPatient, { data, loading, error }] = useMutation(CREATE_PATIENT, {
    context: {
      excludeHeaders: true,
    },
  });
  let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (route === 'authenticated') {
      let newFrom = from === '/' ? '/drugs' : from;
      navigate(newFrom, { replace: true });
    }
  }, [route, navigate, from]);

  const authWrapperClasses = clsx('auth-wrapper', classes.authWrapper);
  const signUpAttributes = [
    'password',
    'given_name', // 'first_name
    'family_name', // 'last_name
  ];

  const services = {
    async handleSignIn(formData) {
      let { username, password } = formData;
      try {
        const auth = await Auth.signIn(username, password);
        // getToken(messaging).then((device_token) => {
        //   apolloClient.mutate({
        //     mutation: REGISTER_DEVICE_TOKEN_MUTATION,
        //     variables: {
        //       patientId: username,
        //       deviceName: 'android',
        //       token: device_token,
        //     },
        //   });
        // });
        return auth;
      } catch (error) {
        console.log('error signing in', error);
      }
    },
    async handleSignUp(formData) {
      let { username, password, attributes } = formData;
      username = username.toLowerCase();
      await createPatient({
        variables: {
          email: username,
          password: password,
          firstname: attributes.given_name,
          lastname: attributes.family_name,
        },
      }).then((res) => {
        console.log(`Account created with email: ${res.data.createPatient.email}, please verify`);
      });
      return Auth.signUp({
        username,
        password,
        attributes,
        autoSignIn: {
          enabled: true,
        },
      });
    },
    async validateCustomSignUp(formData) {
      let acknowledgement = !formData.acknowledgement;
      let beta_code = formData.beta_code !== BETA_CODE;
      let username = formData.username === '';

      let acknowledgementError = acknowledgement ? 'You must agree to the Terms & Conditions' : '';
      let betaCodeError = beta_code ? 'You must enter valid beta code' : '';
      let usernameError = username ? 'Email is required' : '';

      if (acknowledgementError || betaCodeError || usernameError) {
        return {
          beta_code: betaCodeError,
          acknowledgement: acknowledgementError,
          username: usernameError,
        };
      }
    },
  };
  const components = {
    SignUp: {
      FormFields() {
        const { validationErrors } = useAuthenticator();
        return (
          <>
            {!!validationErrors.beta_code ? (
              <TextField
                label="Beta code"
                name="beta_code"
                placeholder="Enter valid beta code"
                errorMessage={validationErrors.beta_code}
                hasError={!!validationErrors.beta_code}
              />
            ) : (
              <>
                {/* Re-use default `Authenticator.SignUp.FormFields` */}
                <Authenticator.SignUp.FormFields validationErrors={validationErrors} />
                <TextField
                  label="Role or position"
                  name="user_role"
                  placeholder="Enter your current professional role"
                  isRequired={false}
                />
                <CheckboxField
                  errorMessage={validationErrors.acknowledgement}
                  hasError={!!validationErrors.acknowledgement}
                  name="acknowledgement"
                  value="yes"
                  label="I agree with the Terms & Conditions"
                />
              </>
            )}
          </>
        );
      },
    },
  };
  return (
    <View className={authWrapperClasses}>
      <Authenticator
        services={services}
        signUpAttributes={signUpAttributes}
        components={components}
        formFields={{
          signUp: {
            username: {
              label: 'Email',
              placeholder: 'Enter your email',
              isRequired: true,
            },
            given_name: { label: 'First Name' },
            family_name: { label: 'Last Name' },
          },
          signIn: {
            username: { label: 'Email', placeholder: 'Enter your email' },
          },
        }}
        validateCustomSignUp={services.validateCustomSignUp}
      />
    </View>
  );
}
