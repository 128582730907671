export const data = {
  appName: 'Panacea',
  tagline: 'Empowering physcians to optimize medication',
  joinWaitListBtn: 'Join Waitlist',
  coreFeatureTitle: 'Right treatment. Right dose.',
  coreFeatureDescription:
    'Optimize treatment plans to reduce time to successful outcomes, minimize risk of side effects, and increase patient satisfaction',
  how1: "1. Identify the treatment with the best chance of success based on patient's unique profile",
  how2: '2. Use our remote monitoring app to track treatment response and intelligently alert the physician and patient of detected abnormalities',
  keyFeatureTitle1: 'Data Driven Treatment Plans',
  keyFeatureDesc1: 'Data models assist doctors to determine the best treatment plan',
  keyFeatureTitle2: 'Real-time Alerting',
  keyFeatureDesc2:
    'Reduce risk of unnecessary side effects and increase speed to recovery with out easy-to-use patient monitoring app',
  keyFeatureTitle3: 'Security First',
  keyFeatureDesc3:
    "We take our user's privacy seriously, and ensure all data security is top of the line",
  requestDemoDesc: 'Interested in learning more about how we can improve care in your practice?',
  requestDemo: 'Request Demo',
  contactUs: 'Contact Us',
  sayHello: 'Say Hello',
};
