import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './ui/components/App/App';
import * as serviceWorker from './config/serviceWorker';
import store from './data';
import { Provider } from 'react-redux';
import awsmobile from './aws-exports';
import { Amplify } from 'aws-amplify';

Amplify.configure(awsmobile);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register();
if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}
