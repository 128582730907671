import React from 'react';
import { data } from '../data.';
import { Typography, makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  coreFeature: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  coreFeatureTitle: {
    fontWeight: 600,
  },
  coreFeatureDesc: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    '& h5': {
      maxWidth: '70%',
    },
  },
}));

export default function CoreFeature() {
  const classes = styles();
  return (
    <div className={classes.coreFeature}>
      <Typography variant="h3" component="h3" className={classes.coreFeatureTitle}>
        {data.coreFeatureTitle}
      </Typography>
      <div className={classes.coreFeatureDesc}>
        <Typography variant="h5">{data.coreFeatureDescription}</Typography>
      </div>
    </div>
  );
}
