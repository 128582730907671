import React from 'react';
import CoreFeature from './CoreFeature/CoreFeature';
import How from './How';
import KeyFeatures from './KeyFeatures';
import RequestDemo from './RequestDemo';
import TaglineSection from './TaglineSection';

export default function Landing() {
  return (
    <>
      <TaglineSection />
      <CoreFeature />
      <How />
      <KeyFeatures />
      <RequestDemo />
    </>
  );
}
