import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { useStyles } from './FilterByCategory';

export function GenericAutocomplete(props) {
  const {
    options,
    value,
    onChange,
    label,
    placeholder,
    getOptionLabel,
    renderTags,
    limitTags,
    size,
    className,
  } = props;
  const classes = useStyles();
  return (
    <Autocomplete
      multiple
      size={size || 'small'}
      className={className}
      limitTags={limitTags || 1}
      options={options}
      value={value}
      getOptionLabel={getOptionLabel}
      defaultValue={[]}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="default"
            label={option}
            size="small"
            className={classes.selectedIndicationChip}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} variant="outlined" placeholder={placeholder} />
      )}
      onChange={onChange}
      {...props}
    />
  );
}
