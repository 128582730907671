import { makeStyles } from '@material-ui/core/styles';
const SIDE_MENU_WIDTH = 240;
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  headerAppBar: {
    marginLeft: SIDE_MENU_WIDTH,
    width: `calc(100% - ${SIDE_MENU_WIDTH}px)`,
    boxShadow: 'none',
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchSection: {
    minWidth: '300px',
    flexGrow: 1,
  },
  searchBar: {
    width: '100%',
  },
  searchHeaderConfig: {
    display: 'flex',
    gridGap: theme.spacing(1),
    alignItems: 'center',
  },
  toolbarLeft: {
    display: 'flex',
    gridGap: theme.spacing(2),
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  content: {
    marginLeft: '25px',
  },

  /** not auth */
  isNotAuth: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
  },

  /** menu and nav */
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawer: {
    [theme.breakpoints.up('xs')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

export default useStyles;
