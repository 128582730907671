import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { data } from '../data.';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import BubbleChartOutlinedIcon from '@material-ui/icons/BubbleChartOutlined';

const styles = makeStyles((theme) => ({
  KeyFeatures: {
    padding: theme.spacing(4),
  },
  featureTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  featureIcon: {
    fontSize: '3rem',
  },
}));

export default function KeyFeatures() {
  const classes = styles();

  return (
    <div className={classes.KeyFeatures}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <BubbleChartOutlinedIcon color="primary" className={classes.featureIcon} />
          <Typography variant="h5" className={classes.featureTitle}>
            {data.keyFeatureTitle1}
          </Typography>
          <Typography variant="subtitle">{data.keyFeatureDesc1}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <NotificationsActiveOutlinedIcon color="primary" className={classes.featureIcon} />
          <Typography variant="h5" className={classes.featureTitle}>
            {data.keyFeatureTitle2}
          </Typography>
          <Typography variant="subtitle">{data.keyFeatureDesc2}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <LockOutlinedIcon color="primary" className={classes.featureIcon} />
          <Typography variant="h5" className={classes.featureTitle}>
            {data.keyFeatureTitle3}
          </Typography>
          <Typography variant="subtitle">{data.keyFeatureDesc3}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}
