import { Authenticator } from '@aws-amplify/ui-react';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PaThemeProvider from '../../providers/PaThemeProvider';
import { RouteMap } from '../auth/RouteMap';
import './App.css';
import { PApolloProvider } from './providers/ApolloProvider';

// const HotToastNotifications = () => {
//   const { toasts, handlers } = useToaster();
//   const { startPause, endPause, calculateOffset, updateHeight } = handlers;

//   return (
//     <div
//       style={{
//         position: 'fixed',
//         top: 10,
//         left: 10,
//       }}
//       onMouseEnter={startPause}
//       onMouseLeave={endPause}
//     >
//       {toasts.map((toast) => {
//         console.log({ toast });
//         const offset = calculateOffset(toast.id, {
//           reverseOrder: false,
//           margin: 10,
//         });
//         const ref = (el) => {
//           if (el && !toast.height) {
//             const height = el.getBoundingClientRect().height;
//             updateHeight(toast.id, height);
//           }
//         };
//         return (
//           <div
//             role="alert"
//             aria-label="notification"
//             key={toast.id}
//             ref={ref}
//             style={{
//               position: 'absolute',
//               width: '13rem',
//               padding: '.7rem',
//               background: 'rgba(175, 75, 62, 0.1)',
//               borderRadius: '3rem',
//               transition: 'all 0.2s',
//               transform: `translateY(${offset}px)`,
//               opacity: toast.visible ? 1 : 0,
//             }}
//           >
//             {toast.message}
//           </div>
//         );
//       })}
//     </div>
//   );
// };

var device_token = 'default';
const useStyles = makeStyles(() => ({
  app: {
    height: '100%',
  },
}));

// Notification.requestPermission()
//   .then(() => {
//     return getToken(messaging);
//   })
//   .then((token) => {
//     device_token = token;
//   })
//   .catch((e) =>
//     onMessage((payload) => {
//       console.log({ payload });
//     })
//   );

function App(props) {
  const classes = useStyles();

  return (
    <PApolloProvider>
      <PaThemeProvider>
        <div className={classes.app}>
          {/* <HotToastNotifications /> */}
          <Authenticator.Provider>
            <RouteMap />
          </Authenticator.Provider>
        </div>
      </PaThemeProvider>
    </PApolloProvider>
  );
}

export default App;
