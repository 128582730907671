import { Link, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import PulseLogo from '../Header/PulseLogo/PulseLogo';
import { data } from '../../pages/Landing/data.';

const styles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gridGap: theme.spacing(2),
  },
}));

export default function Footer() {
  const classes = styles();
  return (
    <div className={classes.footer}>
      <PulseLogo />

      <Typography variant="caption">
        © 2021- {new Date().getFullYear()} {data.appName}
      </Typography>
      <Link href="mailto:jaiessarani@gmail.com" variant="caption">
        {data.sayHello}
      </Link>
    </div>
  );
}
