import { FormControlLabel } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelectedDrugs } from '../../../../../data/state/drugs/useSelectedDrug';
import Indications from '../Indications';

const useStyles = makeStyles((theme) => ({
  drugCard: {
    display: 'flex',
    height: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    mindWidth: '350px',
    maxWidth: '350px',
    marginLeft: '30px',
    marginTop: '12px',
  },
  cover: {
    width: 140,
  },
  controls: {
    flex: '1 0 auto',
    maxWidth: '300px',
    marginLeft: '30px',
    marginTop: 0,
  },
  indications: {
    display: 'flex',
    overflowX: 'auto',
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  centeredLabel: {
    display: 'flex',
    marginLeft: theme.spacing(0.4),
    alignItems: 'center',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    '& .MuiFormControlLabel-label': {
      fontWeight: 'bold',
    },
  },
}));

export default function MediaControlCard(props) {
  const classes = useStyles();
  const { drug } = props;
  const { addRemoveDrug, isDrugSelected } = useSelectedDrugs();
  const isDrugChecked = isDrugSelected(drug) > -1;
  const [checked, setChecked] = React.useState(isDrugChecked || false);

  const handleSelectDrug = (event) => {
    addRemoveDrug(drug);
    setChecked(!checked);
  };

  return (
    <Card className={classes.drugCard}>
      <CardMedia
        className={classes.cover}
        image="https://previews.123rf.com/images/razvodovska/razvodovska1202/razvodovska120200012/12486533-abstract-molecule-illustration-over-white-background.jpg"
        title="Live from space album cover"
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography
            component="h5"
            variant="h5"
            style={{ textAlign: 'left', marginBottom: '9px', fontWeight: 'bold' }}
          >
            {drug.name ? drug.name : drug.brandNames.join(', ') || drug.drugClass}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {drug.description ||
              drug.comments ||
              drug.mechanismOfAction ||
              drug.clinicalPharmacology}
          </Typography>
        </CardContent>
        <div className={classes.controls}>
          {/* <div className={classes.indications}>
            <Indications indications={drug.indications} />
          </div> */}
          <FormControlLabel
            control={<Checkbox checked={checked} name={drug.name} color="primary" />}
            label="Add to compare"
            onChange={handleSelectDrug}
            color="primary"
            className={classes.centeredLabel}
          />
        </div>
      </div>
    </Card>
  );
}
