import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    height: 'calc(100vh - 64px - 60px - 40px)',
    backgroundColor: 'transparent',
  },
  drugTableHead: {},
  emptyCell: {
    border: 'none',
  },
  drugButton: {
    marginBottom: theme.spacing(1),
  },
  drugHeadCells: {
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    border: 'none',
    textAlign: 'center',
  },
  headerContent: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1, 0, 1, 0),
    textAlign: 'center',
    borderRadius: 4,
  },
  drugDataRow: {
    backgroundColor: 'transparent',
    '& .MuiTableCell-root': {
      backgroundColor: theme.palette.common.white,
      borderBottom: `25px solid #fafafa`,
    },
  },
  drugData: {
    fontWeight: 'bold',
  },
  noDrugs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: theme.spacing(1),
    height: 'calc(100vh - 64px - 60px - 40px)',
  },
}));
