import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Administration from '../Administration';
import Dosage from '../Dosage';
import Indications from '../Indications';
import SideEffects from '../SideEffects';
import { comparisonTableLabels } from './tableLabels';
import { useStyles } from './useStyles';
import { useReactiveVar } from '@apollo/client';
import { selectedDrugsVar } from '../../../../../data/state/drugs/selectedDrugsVar';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { useSelectedDrugs } from '../../../../../data/state/drugs/useSelectedDrug';
import { textbookDrugs } from '../../../../../__mocks__/drugs';

export default function DrugComparisonView() {
  const classes = useStyles();
  const { addRemoveDrug } = useSelectedDrugs();
  const selectedDrugs = useReactiveVar(selectedDrugsVar);
  if (!selectedDrugs || selectedDrugs.length <= 1)
    return (
      <div className={classes.noDrugs}>
        <ErrorOutlineOutlinedIcon color="action" />
        <Typography variant="h6" color="textSecondary">
          No drugs selected, please select two or more drugs to compare{' '}
        </Typography>
      </div>
    );

  const drugHeaders = selectedDrugs?.map((drug) => drug.name);
  const getDrug = (name) => selectedDrugs.find((drug) => drug.name === name);

  const tableKeys = [
    'indications',
    'commonInteractions',
    'commonSideEffects',
    'administration',
    'organClearance',
    'dosage',
    'pregnancyRisk',
    'price',
  ];

  const parseDrugData = (drugData, key) => {
    switch (key) {
      case 'indications':
        return <Indications indications={drugData.indications} />;
      case 'commonInteractions': {
        return drugData.commonInteractions;
      }
      case 'commonSideEffects': {
        return <SideEffects sideEffects={drugData[key]} />;
      }
      case 'administration': {
        return <Administration administration={drugData.administration} />;
      }
      case 'organClearance': {
        return drugData.organClearance;
      }
      case 'dosage': {
        return drugData.dosage;
      }
      case 'price': {
        return drugData[key];
      }
      case 'pregnancyRisk': {
        return drugData.pregnancyRisk;
      }
      default:
        return 'NA';
    }
  };

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.drugTableHead}>
            <TableRow>
              <TableCell className={classes.emptyCell}></TableCell>
              {drugHeaders.map((header, index) => (
                <TableCell
                  key={`${header}-${index}`}
                  align="left"
                  className={classes.drugHeadCells}
                >
                  <Button
                    color="default"
                    size="small"
                    variant="outlined"
                    className={classes.drugButton}
                    startIcon={<HighlightOffOutlinedIcon />}
                    onClick={() => addRemoveDrug(getDrug(header))}
                  >
                    Remove
                  </Button>
                  <Typography variant="h6" color="white" className={classes.headerContent}>
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableKeys.map((key, index) => (
              <TableRow key={`${key}-${index}`} className={classes.drugDataRow}>
                <TableCell component="th" scope="row" className={classes.drugDataCell}>
                  <Typography variant="h6" color="textPrimary" className={classes.drugData}>
                    {comparisonTableLabels[key]}
                  </Typography>
                </TableCell>
                {selectedDrugs?.map((drug, drugIndex) => (
                  <TableCell key={`${drug.name}-${drugIndex}`} align="left">
                    <Typography variant="body1">{parseDrugData(drug, key)} </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
