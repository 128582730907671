// create an apollo client and export it as a provider
import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVICE,
});

const authLink = setContext(async (request, { headers }) => {
  if (request.operationName === 'createPatient') return { headers };
  const currentSession = await Auth.currentSession()
    .then((data) => data)
    .catch((e) => `${e} no valid session`); // this is where I'm trying to get the token
  return { headers: { ...headers, authorization: currentSession?.getIdToken()?.getJwtToken() } };
});

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_SERVICE,
  cache: new InMemoryCache({}),
  fetchOptions: { mode: 'no-cors' },
  link: authLink.concat(httpLink),
});

export const PApolloProvider = (props) => {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
