import { selectedDrugsVar } from './selectedDrugsVar';
import { produce } from 'immer';

export const useSelectedDrugs = () => {
  const isDrugSelected = (drug) => {
    const currentDrugs = selectedDrugsVar();
    return currentDrugs.findIndex((d) => d.name === drug.name);
  };
  const addRemoveDrug = (drug) => {
    // get current drug value, if it exists, find the index of the drug and remove it, otherwise add it to the array of drugs
    const currentDrugs = selectedDrugsVar();
    const drugIndex = isDrugSelected(drug);
    const nextState = produce(currentDrugs, (draftState) => {
      if (drugIndex > -1) {
        draftState.splice(drugIndex, 1);
      } else {
        draftState.push(drug);
      }
    });
    selectedDrugsVar(nextState);
  };

  return { addRemoveDrug, isDrugSelected };
};
