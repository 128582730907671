import {createStore, applyMiddleware, combineReducers} from 'redux';
import {thunk} from  'react-redux';
import reduxThunk from 'redux-thunk'

// import authReducer from './reducers/authReducer';
import treatmentReducer from './reducers/treatmentReducer';
import AuthReducer from './reducers/auth_reducer';
import AlertReducer from './reducers/alert_reducer';
import FitlerReducer from './reducers/fitlerReducer';

const rootReducer= combineReducers({
    auth: AuthReducer,
    treatment: treatmentReducer,
    alert: AlertReducer,
    filters: FitlerReducer
});
const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore)

const store = createStoreWithMiddleware(
    rootReducer
)

export default store;