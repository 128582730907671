import {
  Button,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from './Header.styles';
import PulseLogo from './PulseLogo/PulseLogo';
import { useAuthenticator } from '@aws-amplify/ui-react';
import MenuIcon from '@material-ui/icons/Menu';

export default function Header() {
  const classes = useStyles();

  const { route } = useAuthenticator((context) => [context.route]);
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const isAuthenticated = route === 'authenticated';

  const navigate = useNavigate();
  const handleSignin = () => {
    navigate('/login');
  };
  const handleBackToApp = () => {
    navigate('/drugs');
  };
  const handleScrollToContact = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleMobileDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const drawer = (
    <List>
      <ListItem button onClick={() => handleScrollToContact('requestdemo')}>
        <ListItemText primary="Request demo" />
      </ListItem>
      {!isAuthenticated ? (
        <>
          <ListItem button onClick={handleSignin}>
            <ListItemText primary="Sign in" />
          </ListItem>
          <ListItem button onClick={handleSignin}>
            <ListItemText primary="Beta sign up" />
          </ListItem>
        </>
      ) : (
        <ListItem button onClick={handleBackToApp}>
          <ListItemText primary="Back to app" />
        </ListItem>
      )}
    </List>
  );

  return (
    <AppBar position="sticky" className={classes.isNotAuth}>
      <Toolbar className={classes.toolbar}>
        <div>
          <Hidden mdUp>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleMobileDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <PulseLogo />
          <nav className={classes.drawer} aria-label="navigation">
            <Drawer
              variant="temporary"
              anchor="left"
              open={mobileDrawerOpen}
              onClose={handleMobileDrawerToggle}
              ModalProps={{ keepMounted: true }}
              classes={{ paper: classes.drawerPaper }}
            >
              {drawer}
            </Drawer>
          </nav>
        </div>

        <Hidden xsDown>
          <div className={classes.toolbarLeft}>
            <Button
              onClick={() => handleScrollToContact('requestdemo')}
              variant="contained"
              color="primary"
              size="small"
            >
              Request Demo
            </Button>
            {!isAuthenticated ? (
              <>
                <Button onClick={handleSignin} color="primary" variant="outlined" size="small">
                  Sign In
                </Button>
                <Button onClick={handleSignin} color="secondary" variant="outlined" size="small">
                  Beta Sign Up
                </Button>
              </>
            ) : (
              <Button onClick={handleBackToApp} color="primary" variant="outlined" size="small">
                Back to app
              </Button>
            )}
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}
