import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import { drugViewVar } from '../../../../../data/state/drugs/drugViewVar';
import { useState } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { signOut } from '../../../auth/authFunctions';

export default function Actions() {
  const [isCompareView, setIsCompareView] = useState('no');

  const handleSettings = () => console.log('settings');
  const handleDownload = () => console.log('download');
  const handleShare = () => console.log('share');
  const handleCompareView = (event, newView) => {
    setIsCompareView(newView);
    drugViewVar(newView);
  };

  const compareViewSwitch = (
    <ToggleButtonGroup
      value={isCompareView}
      exclusive
      onChange={handleCompareView}
      aria-label="comparison view"
      size="small"
    >
      <ToggleButton value="no" aria-label="left aligned">
        N
      </ToggleButton>
      <ToggleButton value="yes" aria-label="centered">
        Y
      </ToggleButton>
    </ToggleButtonGroup>
  );
  const ACTIONS = [
    {
      label: 'Compare View',
      icon: <TableChartOutlinedIcon color="primary" />,
      onClick: handleCompareView,
      secondary: compareViewSwitch,
    },
    { label: 'Settings', icon: <SettingsOutlinedIcon />, onClick: handleSettings },
    { label: 'Download', icon: <GetAppOutlinedIcon />, onClick: handleDownload },
    { label: 'Share', icon: <ShareOutlinedIcon />, onClick: handleShare },

    {
      label: 'Sign out',
      icon: <ExitToAppIcon />,
      onClick: signOut,
    },
  ];
  return (
    <div>
      <List aria-labelledby="drawer-actions">
        {ACTIONS.map(({ label, icon, onClick, secondary }) => {
          return (
            <ListItem button onClick={onClick} key={label}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
              {secondary ? <ListItemSecondaryAction>{secondary}</ListItemSecondaryAction> : ''}
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
